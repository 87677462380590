import { Fragment } from 'react';

import FirstMoveModule from '~/components/common/firstMoves/FirstMoveModule';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { getBioPhotoImageId } from '~/util/storefrontUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

type FirstMoveSectionProps = {
  isLdpRedesign?: boolean;
};

const FirstMoveSection = ({ isLdpRedesign }: FirstMoveSectionProps) => {
  const { storefrontDetails, firstMove } = useStorefrontDetails<CouplesStorefrontDetails>();
  const firstMoveImageId = storefrontDetails ? getBioPhotoImageId(storefrontDetails) : null;

  if (!firstMove || !firstMoveImageId) {
    return <Fragment />;
  }

  return (
    <FirstMoveModule
      firstMove={firstMove}
      imageId={firstMoveImageId}
      isLdpRedesign={isLdpRedesign}
    />
  );
};

export default FirstMoveSection;
