type ConverterFunction = (s: number) => number;
type DecoratorFunction = (s: string) => string;

const getConvertedValue = (
  value?: string | number | null,
  converter?: ConverterFunction | null
): string => {
  if (value === null || value === undefined) {
    return '';
  }

  if (converter) {
    return converter(typeof value === 'string' ? parseInt(value, 10) : value).toString();
  }

  return value.toString();
};

const decorateRangeAsDollars = (range: string): string => {
  return `$${range}`;
};

/**
 * Formats a range
 *
 * This performs some additional safeguards and checks and allows conversion of the passed-in values.
 *
 * - If the start and end are the same, do not format as a range
 * - Treats null and undefined as blank strings, and only returns strings
 * - Safely handles 0 as a part of the range
 * - Allows decoration of the returned range (to pre-pend a dollar sign)
 * - Allows conversion of the passed in values (convert cents to dollars)
 *
 * @example formatRange(2,2) // returns '2'
 * @example formatRange(null,2) // returns '2'
 * @example formatRange(2, null) // returns '2'
 * @example formatRange(null, null) // returns ''
 * @example formatRange(10, 100) // returns '10-100'
 * @example formatRange(10, 100, null, x => `$${x}`) // returns '$10-100'
 * @example formatRange(10, 100, null, x => `$${x}`) // returns '$10-100'
 * @example formatRange(10, 100, x => x / 10, x => `$${x}`) // returns '$1-10'
 *
 * @param {*} rawStart - An unconverted value representing the start of the range
 * @param {*} rawEnd  - An unconverted value representing the end of the range
 * @param {*} converter - An optional converter to apply to both the start and end of the range
 * @param {*} decorator - An optional decorator to apply to the resulting range when the range is not blank
 */

export const formatRange = (
  rawStart?: string | number | null,
  rawEnd?: string | number | null,
  converter?: ConverterFunction | null,
  decorator?: DecoratorFunction | null
): string => {
  const convertedStart = getConvertedValue(rawStart, converter);
  const convertedEnd = getConvertedValue(rawEnd, converter);

  // both values are blank, so just return blank
  if (convertedStart === '' && convertedEnd === '') {
    return '';
  }

  // special case for equal values
  if (convertedStart === convertedEnd) {
    return decorator ? decorator(convertedStart) : convertedStart;
  }

  const asRange = [convertedStart, convertedEnd].filter((value) => value !== '').join('-');

  return decorator ? decorator(asRange) : asRange;
};

/**
 * Formats two price ranges as peak and off-peak prices
 *
 * @example formatSeasonalPriceRanges(null, 5, null, 5) // returns '$5'
 * @example formatSeasonalPriceRanges(1, 2, 3, 4) // returns 'Peak: $3-4 / Off-Peak: $1-2'
 * @example formatSeasonalPriceRanges(null, 2, null, 4) // returns 'Peak: $4 / Off-Peak: $2'
 * @example formatSeasonalPriceRanges(1000, 2000, 3000, 4000, PriceConversion.centsToDollarsWithCommas) // returns 'Peak: $3,000-4,000 / Off-Peak: $1,000-2,000'
 *
 * @param {*} offPeakStart - An unconverted value representing the start of the off-peak price range
 * @param {*} offPeakEnd- An unconverted value representing the end of the off-peak range
 * @param {*} peakStart - An unconverted value representing the start of the peak price range
 * @param {*} peakEnd- An unconverted value representing the end of the peak range
 * @param {*} converter - An optional function, taking one argument, to convert the values passed, e.g. cents to dollars
 */
export const formatSeasonalPriceRanges = (
  offPeakStart?: string | number | null,
  offPeakEnd?: string | number | null,
  peakStart?: string | number | null,
  peakEnd?: string | number | null,
  converter?: ConverterFunction
): string => {
  const offPeakFormatted = formatRange(offPeakStart, offPeakEnd, converter, decorateRangeAsDollars);
  const peakFormatted = formatRange(peakStart, peakEnd, converter, decorateRangeAsDollars);

  if (offPeakFormatted === '') {
    return peakFormatted;
  }

  if (peakFormatted === '') {
    return offPeakFormatted;
  }

  if (peakFormatted === offPeakFormatted) {
    return peakFormatted;
  }

  return `Peak: ${peakFormatted} / Off-Peak: ${offPeakFormatted}`;
};
