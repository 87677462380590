import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';
import P from '@zola/zola-ui/src/typography/Paragraphs';

type ValidReason = Exclude<StorefrontFirstMoveView.LeadSourcesEnum, 'FAVORITE_INQUIRY'>;

type LeadReasonText = { dashboard: React.ReactNode | null; firstMove: React.ReactNode };

const firstMove = <P.BodyBase>Interested? Get a quote!</P.BodyBase>;

const LeadReasonTextLookup: Record<ValidReason, LeadReasonText> = {
  FAVORITE: {
    dashboard: 'You favorited them',
    firstMove,
  },
  INQUIRY: {
    dashboard: 'Similar to vendors you inquired with',
    firstMove,
  },
  MINI_QUIZ: {
    dashboard: 'Similar to vendors you’re searching for',
    firstMove,
  },
};

const getBestLeadReason = (
  leadSources: StorefrontFirstMoveView.LeadSourcesEnum[]
): LeadReasonText | undefined => {
  const bestReason = leadSources.find((reason) =>
    ['MINI_QUIZ', 'INQUIRY', 'FAVORITE'].includes(reason)
  ) as ValidReason | undefined;

  return bestReason ? LeadReasonTextLookup[bestReason] : undefined;
};

export const getBestLeadReasonTextForFirstMove = (
  leadSources: StorefrontFirstMoveView.LeadSourcesEnum[]
) => {
  const bestReasonText = getBestLeadReason(leadSources);

  if (bestReasonText) return bestReasonText.firstMove;

  return undefined;
};

export const getBestLeadReasonTextForDashboard = (
  leadSources: StorefrontFirstMoveView.LeadSourcesEnum[]
) => {
  const bestReasonText = getBestLeadReason(leadSources);

  if (bestReasonText) return bestReasonText.dashboard;

  return undefined;
};
