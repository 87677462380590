import { WhiteCircleIcon } from '@zola/zola-ui/src/components/CircleIcon/CircleIcon';
import { TitleMedium3 } from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import './ServiceSection.less';

interface ServiceSectionProps {
  label: string;
  imgSrc?: string;
  className?: string;
  children?: React.ReactNode;
}
const ServiceSection = ({
  imgSrc,
  label,
  className,
  children,
}: ServiceSectionProps): JSX.Element => {
  return (
    <div className={cx('services-section__service-block', className)}>
      {imgSrc && <WhiteCircleIcon image={imgSrc} className="services-section__image" alt="" />}
      <TitleMedium3 className="services-section__heading" presentation="h6">
        {label}
      </TitleMedium3>
      <div className="services-section__content">{children}</div>
    </div>
  );
};

export default ServiceSection;
