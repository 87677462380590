import { Fragment } from 'react';

import { CtaType } from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';

import { isPreview } from '~/util/storefrontUtils';
import { TrackingProductLocation, trackCtaClicked } from '~/util/trackingHelper';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './SocialLink.module.less';

interface SocialLinkProps {
  to: string;
  title: string;
  iconClassName: string;
}

const SocialLink = (props: SocialLinkProps): JSX.Element => {
  const { storefrontDetails } = useStorefrontDetails();
  const { to, title, iconClassName } = props;
  const handleClick = () => {
    if (!isPreview()) {
      trackCtaClicked({
        location: TrackingProductLocation.STOREFRONT_DETAIL_PAGE,
        ctaId: title,
        ctaType: CtaType.BUTTON,
        cta: to,
        storefrontId: storefrontDetails?.id,
        vendorType: storefrontDetails?.taxonomyKey,
      });
    }
  };
  if (!to) {
    return <Fragment />;
  }
  return (
    <a
      className={styles.socialLink}
      href={to}
      title={title}
      rel="noopener"
      target="_blank"
      onClick={handleClick}
    >
      <span className={iconClassName} aria-hidden="true" />
    </a>
  );
};

export default SocialLink;
