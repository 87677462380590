import { Fragment } from 'react';

import { SocialLinkView } from '~/types/responseTypes';

import SocialLink from './SocialLink';

interface SocialLinksProps {
  social: SocialLinkView;
  className?: string;
}

const getWebLink = (to: string): string => {
  const urlWithoutQueryString = to.replace(/\?.*$/, '');
  return `${urlWithoutQueryString}?utm_source=zola&utm_medium=referral&utm_campaign=listing`;
};

const SocialLinks = ({ social, className }: SocialLinksProps): JSX.Element => {
  const { facebook, twitter, instagram, pinterest, web } = social;
  const socialMedia = [
    {
      to: facebook,
      title: 'Facebook',
      iconClassName: 'zola-ui-icon-facebook',
    },
    {
      to: twitter,
      title: 'Twitter/X',
      iconClassName: 'zola-ui-icon-twitter',
    },
    {
      to: instagram,
      title: 'Instagram',
      iconClassName: 'zola-ui-icon-instagram',
    },
    {
      to: pinterest,
      title: 'Pinterest',
      iconClassName: 'zola-ui-icon-pinterest-circled',
    },
    {
      to: web ? getWebLink(web) : null,
      title: 'Website',
      iconClassName: 'zola-ui-icon-globe',
    },
  ];

  return (
    <div className={className}>
      {socialMedia.map(({ to, title, iconClassName }, index) =>
        to ? (
          <Fragment key={`link-${index}`}>
            <SocialLink to={to} title={title} iconClassName={iconClassName} />
          </Fragment>
        ) : (
          <Fragment key={`link-${index}`} />
        )
      )}
    </div>
  );
};

export default SocialLinks;
