import { createContext, useContext } from 'react';

import {
  StorefrontBookingView,
  StorefrontFirstMoveView,
  WeddingCardView,
} from '@zola/svc-marketplace-ts-types';

import { MappedInquiryView } from '~/types/mappedResponseTypes';
import {
  InquiryServicesFacet,
  MappedInquiryPreferencesView,
  MappedOptionFacetView,
  VendorCardView,
} from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { JumpMenuItem, JumpMenuSectionIds } from '../helpers/jumpMenu';

export type StorefrontDetailsValues<T = CouplesStorefrontDetails | null> = {
  storefrontDetails: T;
  inquiryServices: InquiryServicesFacet[];
  otherServices: VendorCardView[];
  plannerPackageOptions: MappedOptionFacetView[] | null;
  inquiryPreferences: MappedInquiryPreferencesView | null;
  lastInquiry: MappedInquiryView | null;
  firstMove: StorefrontFirstMoveView | null;
  jumpMenuItems: JumpMenuItem[];
  isActive: boolean;
  isUnclaimed: boolean;
  isRapidRepeat: boolean;
  realWeddings: WeddingCardView[];
  bookedDates: StorefrontBookingView[];
  setLastInquiry: (inquiry: MappedInquiryView) => void;
  dismissFirstMove: () => void;
  addJumpMenuItem: (id: JumpMenuSectionIds) => void;
};

export const StorefrontDetailsContext = createContext<StorefrontDetailsValues | null>(null);

// Almost all our uses of useStorefrontDetails are on the listing detail page.. BUT the inquiry modal
// uses this as well, and the inquiry modal can live outside the listing detail page.  The inquiry
// modal just uses setLastInquiry so we can allow that to be called (to update the button from
// get a quote to view messages.  This is 100% a hack.  A better solution would be to have an
// inquiries provider instead of mis-mash of redux and storefront details provider
export const useStorefrontDetails = <T = CouplesStorefrontDetails>() => {
  const context = useContext(StorefrontDetailsContext) as StorefrontDetailsValues<T>;
  if (context === undefined) {
    throw new Error('useStorefrontDetails must be used within a StorefrontDetailsContext');
  }
  return context;
};

export const useStorefrontDetailsUnsafe = <T = CouplesStorefrontDetails>() => {
  return useContext(StorefrontDetailsContext) as StorefrontDetailsValues<T> | null;
};
