import { useMemo } from 'react';

import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import { vendorCardViewToVendorCardVendor } from '~/components/common/cards/util/helper';
import VendorCard from '~/components/common/cards/VendorCard/VendorCard';
import SeeAllDrawer from '~/components/common/SeeAllDrawer';
import { MappedStorefrontDetailsPreferredVendor } from '~/types/mappedResponseTypes';
import { VendorCardView } from '~/types/responseTypes';
import { TrackingProductLocation } from '~/util/trackingHelper';

type VendorType = MappedStorefrontDetailsPreferredVendor | VendorCardView;

type OtherVendorsSectionProps = {
  className?: string;
  title: string;
  vendors?: VendorType[];
};

const orderVendorsWithStorefrontsFirst = (a: VendorType, b: VendorType): number => {
  if (a.storefrontUuid && b.storefrontUuid) {
    // both have storefronts
    return 0;
  }
  if (a.storefrontUuid) {
    return -1;
  }
  if (b.storefrontUuid) {
    return 1;
  }
  return 0;
};

const OtherVendorsSection = (props: OtherVendorsSectionProps) => {
  const { className, vendors, title } = props;
  const classes = cx('marketplace__other-vendors-section', 'storefront__section', className);

  const data = useMemo(() => {
    if (vendors && vendors.length > 0) {
      return [...vendors] // sort mutates the source array
        .sort(orderVendorsWithStorefrontsFirst)
        .map((vendor, index) => {
          const mappedVendor = vendorCardViewToVendorCardVendor(vendor);
          return (
            <VendorCard
              vendor={mappedVendor}
              position={index + 1}
              location={TrackingProductLocation.STOREFRONT_DETAIL_PAGE}
              trackClick
              readOnly={!mappedVendor.storefrontSlug}
              key={mappedVendor.storefrontUuid}
            />
          );
        });
    }
    return [];
  }, [vendors]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={classes}>
      <div className="container">
        <hr />
        <SeeAllDrawer
          data={data}
          desktopColumnCount={3}
          heading={
            <H.Title2 presentation="h4" strong>
              {title}
            </H.Title2>
          }
        />
      </div>
    </div>
  );
};

export default OtherVendorsSection;
