import { Fragment } from 'react';

import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getBreadcrumbsSchema } from '@zola-helpers/client/dist/es/schema';
import { SearchLocationView } from '@zola/svc-marketplace-ts-types';
import { BreadcrumbsV2 } from '@zola/zola-ui/src/components/BreadcrumbsV2';
import Schemas from '@zola/zola-ui/src/components/Schemas/Schemas';

import {
  getStateBreadcrumb,
  getCategoryLandingPageBreadcrumb,
  getLandingPageBreadcrumb,
  getLocationBreadcrumb,
} from '~/util/breadcrumbUtils';

import styles from './breadcrumb.module.less';

interface BreadcrumbProps {
  vendorTitle?: string;
  vendorTypeKey: SearchableVendorTaxonomyKey;
  /** Current location from search */
  selectedLocation?: SearchLocationView;
  storefrontLocation?: SearchLocationView;
  hideCLPLPBreadcrumbs?: boolean;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { vendorTitle, vendorTypeKey, selectedLocation, storefrontLocation, hideCLPLPBreadcrumbs } =
    props;

  const linkItems = hideCLPLPBreadcrumbs
    ? []
    : [getLandingPageBreadcrumb(), getCategoryLandingPageBreadcrumb(vendorTypeKey)];

  if (selectedLocation) {
    // When the selected location is a city, also add a breadcrumb for the state
    if (selectedLocation.type === 'CITY') {
      const stateCode = selectedLocation.storefrontSearchPayload?.stateProvince;
      const stateSlug = selectedLocation.stateSlug;

      if (stateCode && stateSlug) {
        linkItems.push(getStateBreadcrumb(vendorTypeKey, stateCode, stateSlug));
      }
    }

    linkItems.push(getLocationBreadcrumb(vendorTypeKey, selectedLocation));

    // When the selected location is a state or market, also add a breadcrumb for the storefront's city
    const isStateOrMarket = selectedLocation.type === 'STATE' || selectedLocation.type === 'MARKET';

    if (isStateOrMarket && storefrontLocation) {
      linkItems.push(getLocationBreadcrumb(vendorTypeKey, storefrontLocation));
    }
  }

  return (
    <Fragment>
      <Schemas schemas={getBreadcrumbsSchema(linkItems)} />
      <BreadcrumbsV2
        className={styles.vendorBreadcrumbs}
        currentSelectionLabel={vendorTitle || ''}
        linkItems={linkItems}
        disableBackLink
        scrollOnMobile
      />
    </Fragment>
  );
};

export default Breadcrumb;
