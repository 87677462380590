import { Fragment } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import cx from 'classnames';

import { useUnclaimedLearnMoreModal } from '~/components/common/modals/UnclaimedLearnMoreModal/UnclaimedLearnMoreModal';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { FavoriteCTA } from './favorites/FavoriteCTA';

import styles from './unclaimedModule.module.less';

type UnclaimedModuleProps = React.HTMLAttributes<HTMLDivElement> & {
  showFavoriteButton?: boolean;
  onClick?: () => void;
};

const UnclaimedModule = ({
  className,
  showFavoriteButton = true,
  onClick,
  ...rest
}: UnclaimedModuleProps) => {
  const { showUnclaimedLearnMoreModal } = useUnclaimedLearnMoreModal();
  const { storefrontDetails } = useStorefrontDetails();
  const { uuid } = storefrontDetails || {};

  if (!storefrontDetails || !uuid) {
    return <Fragment />;
  }

  return (
    <div className={cx(styles.unclaimedModule, className)} {...rest}>
      <ButtonV3
        className={styles.learnMoreButton}
        onClick={() => {
          onClick?.();
          showUnclaimedLearnMoreModal(storefrontDetails);
        }}
      >
        Explore more
      </ButtonV3>
      {showFavoriteButton && <FavoriteCTA storefrontUuid={uuid} />}
    </div>
  );
};

export default UnclaimedModule;
