import { WeddingPlannerPackageView } from '@zola/svc-marketplace-ts-types';
import H from '@zola/zola-ui/src/typography/Headings';

import { MobileServicePackageCard } from '~/pages/couples/storefronts/planners/components/MobileServicePackageCard';

import styles from './mobileServiceList.module.less';

interface MobileServiceModalProps {
  packages: WeddingPlannerPackageView[];
  priceRangeVisible: boolean;
}

export const MobileServiceList = ({ packages, priceRangeVisible }: MobileServiceModalProps) => (
  <div>
    <H.Title2 presentation="h4" strong>
      Services by planning level
    </H.Title2>
    <div className={styles.packageGrid}>
      {packages.map((service) => (
        <MobileServicePackageCard
          key={service.id}
          servicePackage={service}
          priceRangeVisible={priceRangeVisible}
        />
      ))}
    </div>
  </div>
);

export default MobileServiceList;
