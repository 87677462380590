import { useMemo } from 'react';

import { CouplesStorefrontDetailsWeddingPlanner } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';

const usePlannerDetailsFilteredPackages = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsWeddingPlanner>();
  const {
    options,
    weddingPlannerDetails: { packages },
  } = storefrontDetails;

  const filteredPackages = useMemo(() => {
    const selectedOptions = options.map((option) => option.key);
    return packages.filter(
      (pkg) => pkg.serviceLevelFacet && selectedOptions.includes(pkg.serviceLevelFacet.key)
    );
  }, [options, packages]);

  return filteredPackages;
};

export default usePlannerDetailsFilteredPackages;
