import { useEffect, useState } from 'react';

import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorWordPlural } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';
import { COLORS } from '@zola/zola-ui/src/styles/emotion';

import unpublishedStorefrontImage from '~/assets/images/StorefrontDetail/unpublished_storefront.png';
import Head from '~/components/common/layouts/head/Head';
import MainLayout from '~/components/common/layouts/MainLayout/MainLayout';
import createCanonicalUrl from '~/pages/couples/explore/util/canonicalUrl';
import { isValidZolaURL } from '~/util/linkUtils';

import { PageSection } from '../../explore/components/PageSection/PageSection';

import styles from './unpublishedStorefront.module.less';

const UnpublishedStorefront = () => {
  const [similarLink, setSimilarLink] = useState('');
  const [backLink, setBackLink] = useState('');
  const [vendorType, setVendorType] = useState('');

  useEffect(() => {
    const canonicalUrl = createCanonicalUrl({
      selectedTaxonomy: {
        key: vendorType as VendorTaxonomyKey,
      },
    });
    setSimilarLink(canonicalUrl);
  }, [vendorType]);

  useEffectOnce(() => {
    const pathComponents = window.location.pathname.split('/');
    setVendorType(pathComponents[2]);

    if (isValidZolaURL(document.referrer)) {
      setBackLink(document.referrer);
    }
  });

  return (
    <MainLayout
      id="unpublished-storefront"
      activeSecondaryLink={NAV_DATA.SECONDARY.MARKETPLACE.EXPLORE_VENDORS}
    >
      <Head title="Wedding Vendor Not Found - Zola" />
      <PageSection backgroundColor={COLORS.GEYSER}>
        <main className={styles.container}>
          {backLink && (
            <LinkV2 className={styles.link} href={backLink} arrow arrowPosition="prefix">
              Go Back
            </LinkV2>
          )}
          <img src={unpublishedStorefrontImage} alt="" className={styles.unpublishedImage} />
          <div className={styles.mainContent}>
            <div className={styles.headerContainer}>
              <h1 className={styles.hed}>Bummer!</h1>
            </div>
            <div className={styles.dekContainer}>
              <h1 className={styles.hed}>This vendor is no longer active on Zola.</h1>
            </div>
            <div className={styles.similarVendorLinkContainer}>
              <LinkV2 className={styles.link} href={similarLink} arrow>
                See Similar {getVendorWordPlural(vendorType)}
              </LinkV2>
            </div>
          </div>
        </main>
      </PageSection>
    </MainLayout>
  );
};

export default UnpublishedStorefront;
