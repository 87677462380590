import { WhiteCircleIcon } from '@zola/zola-ui/src/components/CircleIcon/CircleIcon';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import styles from './ServiceSectionV2.module.less';

interface ServiceSectionProps {
  label: string;
  imgSrc?: string;
  className?: string;
  children?: React.ReactNode;
}
const ServiceSectionV2 = ({
  imgSrc,
  label,
  className,
  children,
}: ServiceSectionProps): JSX.Element => {
  return (
    <div className={cx(styles.serviceSection, className)}>
      {imgSrc && <WhiteCircleIcon image={imgSrc} className={styles.image} alt="" />}
      <H.TitleMedium3 className={styles.heading} presentation="h6">
        {label}
      </H.TitleMedium3>
      {children}
    </div>
  );
};

export default ServiceSectionV2;
