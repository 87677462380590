import { useState, useCallback, useEffect, useMemo } from 'react';

import { getRecommendedBookingWindowRange } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { ErrorBoundary } from '@zola/zola-ui/src/components/ErrorBoundary';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';
import _debounce from 'lodash/debounce';

import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import { DayPicker } from '~/components/common/zolaUI/renderDatePicker/DayPicker';
import { useUserContext } from '~/contexts/UserContext';
import { getCalendars } from '~/pages/vendors/LeadPreferences/components/questions/shared/Availability';
import { useAppDispatch } from '~/reducers';
import { getDate, getEndOfMonth, isAfterDate, isBeforeDate } from '~/util/dateUtils';
import { TrackingProductLocation } from '~/util/trackingHelper';
import { getUserWeddingDate, isGuest } from '~/util/userContextUtils';

import { useInquiryModal } from '../../inquiry-flow/showInquiryModal';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './availabilityCalendar.module.less';

const CALENDARS = getCalendars();
/**
 * Couples facing view of the storefronts availability calendar.  Must be used
 * from within a StorefrontDetailsContext
 */
const AvailabilityCalendarContent = () => {
  const { isDesktopDevice } = useResponsiveDesign();
  const userContext = useUserContext();
  const { storefrontDetails, bookedDates, lastInquiry, firstMove } = useStorefrontDetails();

  const [numMonths, setNumMonths] = useState(2);
  const determineNumMonths = useCallback(() => {
    if (typeof window === undefined) {
      setNumMonths(isDesktopDevice ? 3 : 2);
    }
    if (window.matchMedia(`(min-width: 820px) and (max-width: 991px)`).matches) {
      setNumMonths(3);
      return;
    }

    if (window.matchMedia(`(max-width: 620px)`).matches) {
      setNumMonths(1);
      return;
    }
    if (window.matchMedia(`(min-width: 1280px)`).matches) {
      setNumMonths(3);
      return;
    }

    setNumMonths(2);
  }, [isDesktopDevice]);

  useEffect(() => {
    determineNumMonths();
  }, [determineNumMonths]);

  const debounceFn = useMemo(() => _debounce(determineNumMonths, 500), [determineNumMonths]);
  useEffectOnce(() => {
    window.addEventListener('resize', debounceFn);
    return () => {
      window.removeEventListener('resize', debounceFn);
    };
  });

  const disabled = isGuest(userContext);

  const startMonth = useMemo(() => {
    const weddingDateValue = getUserWeddingDate(userContext);
    const weddingDate = weddingDateValue ? getDate(weddingDateValue) : null;

    if (
      weddingDate &&
      isAfterDate(weddingDate, CALENDARS[0]) &&
      isBeforeDate(weddingDate, CALENDARS[CALENDARS.length - 1])
    ) {
      return weddingDate;
    }

    const bookingRange = getRecommendedBookingWindowRange(storefrontDetails.taxonomyKey);
    if (CALENDARS[bookingRange.low]) {
      return CALENDARS[bookingRange.low];
    }

    return CALENDARS[1]; // Next month
  }, [storefrontDetails.taxonomyKey, userContext]);

  const selectedDates = useMemo(() => {
    return bookedDates.map((booking) => new Date(booking.bookedDate));
  }, [bookedDates]);

  const { trackAndShowInquiry } = useInquiryModal();

  const dispatch = useAppDispatch();
  const showInquiryModal = useCallback(() => {
    trackAndShowInquiry({
      ctaText: 'Check availability',
      dispatch,
      firstMoveUuid: firstMove?.uuid,
      location: TrackingProductLocation.STOREFRONT_DETAIL_PAGE,
      position: 7,
      section: 'AVAILABILITY',
      storefront: storefrontDetails,
      entryPoint: 'AVAILABILITY',
    });
  }, [dispatch, firstMove?.uuid, storefrontDetails, trackAndShowInquiry]);

  return (
    <div className="storefront__section">
      <div className="container" role="section">
        <hr />
        <div className={styles.hed}>
          <Title2 presentation="h4" strong>
            Availability calendar
          </Title2>
          {!disabled && !lastInquiry && (
            <InquiryButton
              section="AVAILABILITY"
              position={7}
              buttonText="Check availability"
              entryPoint="AVAILABILITY"
              variant={'primary'}
            />
          )}
        </div>

        <div className="row mt-primary" />
        <div className={styles.wrapper}>
          <div className={cx({ [styles.overlay]: disabled })}>
            <DayPicker
              mode={'default'}
              defaultMonth={startMonth}
              modifiersStyles={{
                selected: { textDecoration: 'line-through' },
              }}
              selected={selectedDates}
              disableNavigation={disabled}
              disabled={disabled}
              onDayClick={!disabled && !lastInquiry ? showInquiryModal : undefined}
              numberOfMonths={numMonths}
              // Don't let the calendar go back in time past the next month
              fromDate={CALENDARS[1]}
              toDate={getEndOfMonth(CALENDARS[CALENDARS.length - 1])}
              fixedWeeks
              classNames={{ months: styles.availability }}
            />
          </div>
          {disabled && (
            <InquiryButton
              section="AVAILABILITY"
              position={7}
              buttonText="Reach out for availability"
              entryPoint="AVAILABILITY"
              variant={'primary'}
              className={styles.overlayButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const AvailabilityCalendar = () => {
  const [ready, setReady] = useState(false);
  useEffectOnce(() => {
    setReady(true);
  });

  return <ErrorBoundary>{ready && <AvailabilityCalendarContent />}</ErrorBoundary>;
};
