import {
  OptionFacetView,
  StorefrontPriceView,
  WeddingPlannerPackageView,
} from '@zola/svc-marketplace-ts-types';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import { useModal } from '~/contexts/ModalContext';
import { centsToDollarsWithCommas } from '~/util/priceConversion';

import { getDisplayPackageName } from '../utils/servicePackageHelpers';
import MobileServiceModal from './MobileServiceModal';

import styles from './mobileServicePackageCard.module.less';

const makePricingDek = (price: StorefrontPriceView | null) => {
  if (!price || !price.minCents || price.minCents < 100) return null;
  const priceInDollars = centsToDollarsWithCommas(price.minCents);
  return `Pricing starts at $${priceInDollars}`;
};

const makeTimelineNote = (timeFacet: OptionFacetView | null): string => {
  if (!timeFacet) return '';
  if (timeFacet.key === 'length-of-time-day-only') {
    return 'Planning begins the day of your event';
  }
  return `Planning begins ${timeFacet.name} out`;
};

const findCurrentPackage = (
  servicePackage: WeddingPlannerPackageView,
  priceRangeVisible: boolean
) => {
  const {
    serviceLevelFacet,
    lengthOfTimeFacet,
    packageOptions,
    extraFeePackageOptions,
    hasPercentageRate,
    storefrontPriceView,
    description,
  } = servicePackage;
  const hed = getDisplayPackageName(serviceLevelFacet?.name || '');
  const dek = priceRangeVisible ? makePricingDek(storefrontPriceView) : null;

  const note = priceRangeVisible && hasPercentageRate ? '*Plus % of total wedding costs' : null;
  const includedInStartingPriceOptions = (packageOptions || []).map(({ key, name }) => ({
    key,
    name,
  }));
  const extraFeeOptions = (extraFeePackageOptions || []).map(({ key, name }) => ({ key, name }));
  const timeline = makeTimelineNote(lengthOfTimeFacet);

  return {
    hed,
    dek,
    note,
    includedInStartingPriceOptions,
    timeline,
    extraFeeOptions,
    description,
  };
};

export const MobileServicePackageCard = ({
  servicePackage,
  priceRangeVisible,
}: {
  servicePackage: WeddingPlannerPackageView;
  priceRangeVisible: boolean;
}) => {
  const { showCustomModal } = useModal();

  const { hed, dek, note, includedInStartingPriceOptions, timeline, extraFeeOptions, description } =
    findCurrentPackage(servicePackage, priceRangeVisible);

  const handleClick = () =>
    showCustomModal(
      MobileServiceModal,
      {
        hideClose: false,
        hed,
        dek,
        note,
        includedInStartingPriceOptions,
        timeline,
        extraFeeOptions,
        description,
        priceRangeVisible,
      },
      { mobileHalfSheet: true }
    );

  return (
    <div className={styles.card}>
      <H.TitleMedium3 className={styles.hed} color="BLACK_075" presentation="h5">
        {hed}
      </H.TitleMedium3>
      {dek ? (
        <P.BodySmall>
          {dek}
          {note ? '*' : null}
        </P.BodySmall>
      ) : null}
      {note ? <P.BodySmall className={styles.note}>{note}</P.BodySmall> : null}
      {includedInStartingPriceOptions ? (
        <LinkV2
          className={styles.link}
          role="button"
          type="button"
          variant="primary"
          noTextTransform
          onClick={handleClick}
        >
          {includedInStartingPriceOptions.length}{' '}
          {includedInStartingPriceOptions.length === 1 ? 'service included' : 'services included'}
        </LinkV2>
      ) : null}
    </div>
  );
};

/**
 * a small card for previewing a planner's service package */
export default MobileServicePackageCard;
