import { Fragment } from 'react';

import { PLANNERS_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import CircleImage from '~/components/common/images/CircleImage';
import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import { PERSONALITY_ROOT_ID } from '~/meta/services';
import { AnyVendorDetails } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { getVendorDetails, isClaimed } from '~/util/storefrontUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentId } from '../helpers/vendor';
import useStorefrontAvatar from '../hooks/useStorefrontAvatar';
import Awards from './Awards/Awards';
import SocialLinks from './SocialLinks';

import './VendorIntroSection.less';

import styles from './vendorIntroSection.module.less';

const VendorIntroSection = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const avatar = useStorefrontAvatar();
  const {
    name: storefrontName,
    taxonomyKey,
    description,
    setsMeApart,
    social,
    options,
  } = storefrontDetails;
  const { name, title } = getVendorDetails(storefrontDetails) as AnyVendorDetails;

  let threeWords;

  if (taxonomyKey === PLANNERS_TAXONOMY_KEY) {
    const personality = filterForParentId(PERSONALITY_ROOT_ID, options);
    threeWords = personality.map((option) => option.name).join(', ');
  }

  if (!isClaimed(storefrontDetails) && !description) {
    return <Fragment />;
  }

  return (
    <div className="marketplace__vendor-intro-section storefront__section">
      <div className="container">
        <hr />
        <H.Title2 className={styles.heading} presentation="h4" strong>
          About {storefrontName}
        </H.Title2>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <div className="vendor-intro-section__vendor-details mb-primary">
              {avatar && (
                <CircleImage src={avatar} className="mb-secondary vendor-intro-section__avatar" />
              )}
              {name && (
                <div>
                  <H.Title3 presentation="h6" strong>
                    {name}
                  </H.Title3>
                </div>
              )}
              {title && <div className="vendor-intro-section__title">{title}</div>}
              <div className="vendor-intro-section__social">
                <SocialLinks social={social} className="venue-intro-section__social-icons" />
              </div>

              <div className="vendor-intro-section__contact mt-tertiary hidden-xs">
                <InquiryButton
                  className="vendor-intro-section__contact-button"
                  section="INTRO_SECTION"
                  position={2}
                  data-testid="secondary-inquiry-button"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8">
            <div className="vendor-intro-section__container ml-secondary">
              <div>
                <P.BodySmall className="mt-tertiary">{description}</P.BodySmall>
                {setsMeApart && <P.BodySmall className="mt-tertiary">{setsMeApart}</P.BodySmall>}
                {threeWords && (
                  <P.BodySmall className="mt-tertiary">
                    <strong>In three words:</strong> {threeWords}
                  </P.BodySmall>
                )}
                <Awards />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorIntroSection;
