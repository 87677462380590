import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import cx from 'classnames';

import { useModal } from '~/contexts/ModalContext';

import styles from './EditPageHeading.module.less';

type EditPageHeadingProps = {
  heading: string | React.ReactNode;
  subHeading?: string | React.ReactNode;
  showRequired?: boolean;
  customModal?: (props: { hideModalFn?: () => void }) => JSX.Element;
  titleContainerClassName?: string;
};

/**
 * Draws the standard heading and sub-heading at the top of a vendor edit page.
 *
 * The heading and sub-heading can both be either strings or elements.
 */
const EditPageHeading = ({
  heading,
  subHeading,
  showRequired,
  customModal: CustomModal,
  titleContainerClassName,
}: EditPageHeadingProps) => {
  const { showCustomModal } = useModal();

  return (
    <div className={styles.heading}>
      <div className={styles.titleAndSubheading}>
        <div className={cx(styles.titleContainer, titleContainerClassName)}>
          <h3 className={styles.title}>{heading}</h3>
          {CustomModal ? (
            <LinkV2
              className={styles.why}
              onClick={() => {
                showCustomModal(({ hideModalFn }) => (
                  <div className={styles.customModalContainer}>
                    <CustomModal hideModalFn={hideModalFn} />
                  </div>
                ));
              }}
            >
              Why?
            </LinkV2>
          ) : null}
        </div>
        {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
      </div>
      {showRequired && (
        <div className={styles.required}>
          <span>*</span> Required
        </div>
      )}
    </div>
  );
};

export default EditPageHeading;
