import { Fragment, useEffect } from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { Title5 } from '@zola/zola-ui/src/typography/Headings';
import { BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';

import { useUnclaimedClaimListingModal } from '~/components/common/modals/UnclaimedClaimListingModal/UnclaimedClaimListingModal';
import InfoSection from '~/components/common/ui/InfoSection/InfoSection';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './unclaimedClaimModule.module.less';

const UnclaimedClaimModule = () => {
  const { showUnclaimedClaimListingModal } = useUnclaimedClaimListingModal();
  const { storefrontDetails } = useStorefrontDetails();

  useEffect(() => {
    if (storefrontDetails && window.location.pathname.endsWith('/claim')) {
      showUnclaimedClaimListingModal(storefrontDetails);
    }
  }, [showUnclaimedClaimListingModal, storefrontDetails]);

  if (!storefrontDetails) {
    return <Fragment />;
  }

  return (
    <InfoSection className={styles.unclaimedClaimModule}>
      <Title5 className={styles.heading}>Is this your business?</Title5>
      <BodySmall color="BLACK_075">
        Hi, we&rsquo;re big fans! And so are our couples. Join us?
      </BodySmall>
      <BodySmall>
        <LinkV2
          role="button"
          inline
          onClick={() => showUnclaimedClaimListingModal(storefrontDetails)}
        >
          Claim this listing to start getting inquiries
        </LinkV2>
      </BodySmall>
    </InfoSection>
  );
};

export default UnclaimedClaimModule;
