import { Fragment } from 'react';

import { MappedOptionFacetView } from '~/types/responseTypes';

import ServiceBlock from '../components/ServiceBlock';
import ServiceBlockV2 from '../components/ServiceBlockV2';
import { ServiceMetaData } from '../types/VendorIntroSection';

export const findForParentId = (
  key: string,
  servicesMetaData: ServiceMetaData[]
): number | undefined => (servicesMetaData.find(({ formKey }) => formKey === key) || {}).parentId;

export const filterForParentId = (
  id: number | undefined,
  options: MappedOptionFacetView[]
): MappedOptionFacetView[] => options.filter(({ parentId }) => parentId === id);

export const filterForParentKey = (
  key: string,
  options: MappedOptionFacetView[]
): MappedOptionFacetView[] => options.filter(({ parentKey }) => parentKey === key);

export const serviceBlock = (
  condition: boolean,
  label: string,
  options: MappedOptionFacetView[],
  isV2 = false
): JSX.Element =>
  condition ? (
    <div className="services-section__service-group">
      {isV2 ? (
        <ServiceBlockV2 label={label} options={options} />
      ) : (
        <ServiceBlock label={label} options={options} />
      )}
    </div>
  ) : (
    <Fragment />
  );
