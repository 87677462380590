import { Fragment } from 'react';

import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';

import { CouplesStorefrontDetailsWeddingPlanner } from '~/types/storefrontDetails';
import { isClaimed } from '~/util/storefrontUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import DesktopServices from './components/DesktopServices';
import MobileServiceList from './components/MobileServiceList';
import usePlannerDetailsFilteredPackages from './utils/usePlannerDetailsFilteredPackages';

export const ServicesSection = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsWeddingPlanner>();
  const { isDesktopDevice } = useResponsiveDesign();

  const filteredPackages = usePlannerDetailsFilteredPackages();

  const renderServicesContent = () => {
    if (isDesktopDevice && filteredPackages.length <= 4) {
      return (
        <DesktopServices
          packages={filteredPackages}
          priceRangeVisible={storefrontDetails.priceRangeVisible}
        />
      );
    }
    return (
      <MobileServiceList
        packages={filteredPackages}
        priceRangeVisible={storefrontDetails.priceRangeVisible}
      />
    );
  };

  if (!isClaimed(storefrontDetails) && filteredPackages.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="container storefront__section" id="service-levels">
      <hr />
      {renderServicesContent()}
    </div>
  );
};

export default ServicesSection;
