import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  getVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import {
  StorefrontBookingView,
  StorefrontFirstMoveView,
  WeddingCardView,
} from '@zola/svc-marketplace-ts-types';

import { useUserContext } from '~/contexts/UserContext';
import { dismissFirstMove, getFirstMoveForStorefront } from '~/libs/client/api/firstMoves';
import { getInquiryPreferences, getLastInquiryToStorefront } from '~/libs/client/api/inquiries';
import { MappedInquiryView } from '~/types/mappedResponseTypes';
import {
  InquiryServicesFacet,
  MappedInquiryPreferencesView,
  MappedOptionFacetView,
  VendorCardView,
} from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { isGuest } from '~/util/userContextUtils';

import { jumpAnchors, JumpMenuItem, JumpMenuSectionIds } from '../helpers/jumpMenu';
import { StorefrontDetailsContext } from './StorefrontDetailsContext';

type StorefrontDetailsProviderProps = {
  storefrontDetails: CouplesStorefrontDetails | null;
  inquiryServices?: InquiryServicesFacet[];
  otherServices?: VendorCardView[];
  plannerPackageOptions?: MappedOptionFacetView[] | null;
  isVendorPreview?: boolean;
  realWeddings?: WeddingCardView[];
  bookedDates?: StorefrontBookingView[];
};

export const StorefrontDetailsProvider: React.FC<StorefrontDetailsProviderProps> = ({
  children,
  storefrontDetails,
  inquiryServices = [],
  otherServices = [],
  plannerPackageOptions = null,
  isVendorPreview = false,
  realWeddings = [],
  bookedDates = [],
}) => {
  const userContext = useUserContext();
  const [inquiryPreferences, setInquiryPreferences] = useState<MappedInquiryPreferencesView | null>(
    null
  );
  const [lastInquiry, setLastInquiry] = useState<MappedInquiryView | null>(null);
  const [firstMove, setFirstMove] = useState<StorefrontFirstMoveView | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isRapidRepeat, setIsRapidRepeat] = useState(false);
  const [jumpMenuItems, setJumpMenuItems] = useState<JumpMenuItem[]>([jumpAnchors[0]]); // Photos will always exist, but the other sections could be conditional (or need to wait for fetch)

  useEffect(() => {
    if (!isGuest(userContext) && storefrontDetails) {
      getInquiryPreferences(getVendorType(storefrontDetails.taxonomyKey) as VendorTypeEnum)
        .then((inquiryPreferencesForType) => {
          setInquiryPreferences(inquiryPreferencesForType);
          setIsRapidRepeat(
            inquiryPreferencesForType.inquiredSameCategoryBefore === true && !isVendorPreview
          );
        })
        .catch(() => null);

      getLastInquiryToStorefront(storefrontDetails.uuid)
        .then(setLastInquiry)
        .catch(() => null);

      getFirstMoveForStorefront(storefrontDetails.uuid)
        .then(setFirstMove)
        .catch(() => null);
    }
  }, [isVendorPreview, storefrontDetails, userContext]);

  useEffect(() => {
    setIsActive(Boolean(lastInquiry));
  }, [lastInquiry]);

  const isUnclaimed = useMemo(() => {
    return storefrontDetails?.claimedAt === null || storefrontDetails?.claimedAt === undefined;
  }, [storefrontDetails?.claimedAt]);

  const doDismissFirstMove = useCallback(() => {
    if (firstMove) {
      dismissFirstMove(firstMove.uuid)
        .then(() => setFirstMove(null))
        .catch(() => null);
    }
  }, [firstMove]);

  const addJumpMenuItem = useCallback(
    (id: JumpMenuSectionIds) => {
      const existing = jumpMenuItems.find((item) => item.id === id);
      if (!existing) {
        const newJumpMenuItem = jumpAnchors.find((item) => item.id === id);
        if (newJumpMenuItem) {
          setJumpMenuItems((prevItems) =>
            [...prevItems, newJumpMenuItem].sort((a, b) => {
              const aIndex = jumpAnchors.findIndex((item) => item.id === a.id);
              const bIndex = jumpAnchors.findIndex((item) => item.id === b.id);
              return aIndex - bIndex;
            })
          );
        }
      }
    },
    [jumpMenuItems]
  );

  const values = {
    storefrontDetails,
    inquiryServices,
    otherServices,
    plannerPackageOptions,
    inquiryPreferences,
    lastInquiry,
    firstMove,
    isActive,
    isUnclaimed,
    isRapidRepeat,
    jumpMenuItems,
    setLastInquiry,
    dismissFirstMove: doDismissFirstMove,
    addJumpMenuItem,
    realWeddings,
    bookedDates,
  };

  return (
    <StorefrontDetailsContext.Provider value={values}>{children}</StorefrontDetailsContext.Provider>
  );
};
