import { useCallback } from 'react';

import { useModal } from '~/contexts/ModalContext';
import { MappedVenueMenuView } from '~/types/responseTypes';

import CardBase from '../CardBase/CardBase';
import MenuCardContents from './MenuCardContents';

import './menuCard.less';

type MenuCardProps = {
  menu: MappedVenueMenuView;
};
const MenuCard = (props: MenuCardProps) => {
  const { menu } = props;
  const { showCustomModal } = useModal();

  const openMenuCardModal = useCallback(() => {
    showCustomModal(MenuCardContents, { menu });
  }, [showCustomModal, menu]);

  return (
    <CardBase className="marketplace__menu-card mb-secondary p-primary" isLink={false}>
      <MenuCardContents menu={menu} onClick={openMenuCardModal} />
    </CardBase>
  );
};

export default MenuCard;
