import { MappedOptionFacetView } from '~/types/responseTypes';

import ServiceSection from './ServiceSection';

import './ServiceBlock.less';

interface ServiceBlockProps {
  options: MappedOptionFacetView[];
  label: string;
  imgSrc?: string;
  className?: string;
}
const ServiceBlock = ({ options, imgSrc, label, className }: ServiceBlockProps): JSX.Element => {
  return (
    <ServiceSection imgSrc={imgSrc} label={label} className={className}>
      <ul className="services-section__list">
        {options.map((option) => (
          <li className="mt-quaternary" key={option.id}>
            {option.name}
          </li>
        ))}
      </ul>
    </ServiceSection>
  );
};

export default ServiceBlock;
