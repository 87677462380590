import { ErrorType } from '@zola-helpers/client/dist/es/http/types';
import cookieUtils from '@zola-helpers/server/dist/es/utils/cookieUtils';
import { ResponseType } from '@zola-helpers/server/dist/types/types.d';

import camelcaseKeys from 'camelcase-keys';
import type { Request as ExpressRequest } from 'express';
import fetch from 'isomorphic-fetch';
import _isPlainObject from 'lodash/isPlainObject';
import _pickBy from 'lodash/pickBy';
import snakecaseKeys from 'snakecase-keys';

import IncomingMessageWithCookies from '~/types/IncomingMessageWithCookies';
import Logger from '~/util/logger';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ApiServiceTarget {
  WEB_MARKETPLACE = 'WEB_MARKETPLACE',
  WEB_NAV = 'WEB_NAV',
  SVC_CONTENT = 'SVC_CONTENT',
  SVC_MARKETPLACE = 'SVC_MARKETPLACE',
  SVC_WEB_API = 'SVC_WEB_API',
}

const getTargetHost = (target: ApiServiceTarget): string => {
  let result: string | undefined;

  switch (target) {
    case ApiServiceTarget.WEB_MARKETPLACE:
    case ApiServiceTarget.WEB_NAV:
      result = process.env.WEB_HOST || 'localhost';
      break;
    case ApiServiceTarget.SVC_CONTENT:
    case ApiServiceTarget.SVC_MARKETPLACE:
    case ApiServiceTarget.SVC_WEB_API:
      result = process.env.SERVICE_HOST || '127.0.0.1';
      break;
  }

  if (!result) {
    Logger.error(`Cannot resolve port for target ${target}`);
  }

  if (!/^https?:\/\//.test(result)) {
    result = `http://${result}`;
  }

  return result;
};

const getTargetPort = (target: ApiServiceTarget): string => {
  let result: string | undefined;

  switch (target) {
    case ApiServiceTarget.WEB_MARKETPLACE:
      result = '9008';
      break;
    case ApiServiceTarget.WEB_NAV:
      result = '9005';
      break;
    case ApiServiceTarget.SVC_CONTENT:
      result = '9160';
      break;
    case ApiServiceTarget.SVC_MARKETPLACE:
      result = '9170';
      break;
    case ApiServiceTarget.SVC_WEB_API:
      result = '9140';
      break;
  }

  if (!result) {
    Logger.error(`Cannot resolve port for target ${target}`);
  }

  return result;
};

const getTargetBaseUrl = (target: ApiServiceTarget): string => {
  const host = getTargetHost(target);
  const port = getTargetPort(target);
  return `${host}${port && `:${port}`}`;
};

const getAbsoluteUrl = (target: ApiServiceTarget, url: string): string => {
  const base = getTargetBaseUrl(target);
  return new URL(url, base).toString();
};

const validateUrl = (target: ApiServiceTarget, url: string) => {
  const isWebTarget = target.toString().startsWith('WEB');
  const webUrlPrefix = url.match(/\/web[a-z-]*-api\//)?.[0];
  const isWebUrl = Boolean(webUrlPrefix);
  if (isWebTarget && !isWebUrl) {
    Logger.error(`Invalid URL ${url} for web target ${target} (add /web-*-api/?)`);
  }
  if (!isWebTarget && isWebUrl) {
    Logger.error(`Invalid URL ${url} for service target ${target} (remove ${webUrlPrefix}?)`);
  }
};

const isJsonContent = (headers: HeadersInit): boolean => {
  return (headers as Record<string, string>)['Content-Type'] === 'application/json';
};

const transformRequest = (body: NonNullable<unknown>): BodyInit => {
  return JSON.stringify(snakecaseKeys(body, { deep: true }));
};

const getRequestHeaders = (method: HttpMethod, headers: HeadersInit): HeadersInit => {
  const requestHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return _pickBy(requestHeaders, (value) => value !== undefined) as HeadersInit;
};

const getRequestOptions = (
  method: HttpMethod,
  body: unknown | null,
  options: RequestInit,
  headers: HeadersInit
): RequestInit => {
  const requestHeaders = getRequestHeaders(method, headers);
  let requestBody = null;
  if (body) {
    if (isJsonContent(requestHeaders)) {
      requestBody = transformRequest(body);
    } else {
      requestBody = body as BodyInit;
    }
  }
  return {
    method,
    body: requestBody,
    headers: requestHeaders,
    credentials: 'same-origin' as RequestCredentials,
    ...options,
  };
};

const handlePromise = (response: Response): Promise<[boolean, ResponseType<unknown> | string]> => {
  const contentType = response.headers.get('Content-Type');
  if (contentType?.includes('application/json')) {
    return Promise.all([response.ok, response.json()]);
  }
  return Promise.all([response.ok, response.text()]);
};

const handleErrors = ([ok, response]: [boolean, ResponseType<unknown> | string]):
  | ResponseType<unknown>
  | string => {
  if (!ok) {
    let errorMessage = 'An error has occurred';
    if (typeof response !== 'string') {
      errorMessage = response.message || errorMessage;
    }
    const error: ErrorType<ResponseType<unknown>> = new Error(errorMessage);
    error.response = response;
    throw error;
  }
  return response;
};

const UUID_REGEX = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const transformResponse = (response: ResponseType<unknown> | string): unknown => {
  if (typeof response === 'string') {
    return response;
  }
  if (!response.data) {
    return null;
  }
  return camelcaseKeys(response.data, {
    deep: true,
    exclude: [UUID_REGEX], // Don't camelcase UUID keys
  });
};

const request = async <ResponseDataType>(
  target: ApiServiceTarget,
  url: string,
  options: RequestInit
): Promise<ResponseDataType> => {
  validateUrl(target, url);
  return fetch(getAbsoluteUrl(target, url), options)
    .then(handlePromise)
    .then(handleErrors)
    .then((response) => transformResponse(response) as ResponseDataType);
};

type CacheKey = unknown[];

interface Options extends RequestInit {
  cacheKey?: CacheKey;
}

/**
 * Caches the request promises so that if multiple requests are made with the same cache key, they will all resolve to the same value.
 * If the request fails, the cache key is invalidated, so a new request will be made on the next call.
 */
const createCache = () => {
  const cacheMap = new Map();

  return {
    set: <T extends Promise<unknown>>(
      url: string,
      cacheKey: CacheKey | undefined,
      createRequest: () => T
    ): T => {
      if (!cacheKey) return createRequest();
      const key = stableValueHash([url, ...cacheKey]);
      if (cacheMap.has(key)) return cacheMap.get(key);
      const promise = createRequest();
      cacheMap.set(key, promise);

      // If the request fails, remove the cache so it can be requested again. This does not do any retries.
      promise.catch(() => cacheMap.delete(key));

      return promise;
    },
    clear: () => cacheMap.clear(),
    get: cacheMap.get,
  };
};

/**
 * Hashes the value into a stable hash. From React Query: https://github.com/tannerlinsley/react-query/blob/e7a3207f74b27bc112d6ab583831cceac60f94c5/src/core/utils.ts#L301
 */
function stableValueHash(value: unknown[]): string {
  return JSON.stringify(value, (_, val) =>
    _isPlainObject(val)
      ? Object.keys(val) // eslint-disable-line @typescript-eslint/require-array-sort-compare
          .sort()
          .reduce((result, key) => {
            // eslint-disable-next-line no-param-reassign
            result[key] = val[key];
            return result;
          }, {} as Record<string, unknown>)
      : val
  );
}

const getCache = createCache();

const get = async <ResponseDataType = unknown>(
  target: ApiServiceTarget,
  url: string,
  options: Options = {},
  headers: HeadersInit = {}
): Promise<ResponseDataType> => {
  const requestOptions = getRequestOptions(HttpMethod.GET, null, options, headers);
  return getCache.set(url, options.cacheKey, () =>
    request<ResponseDataType>(target, url, requestOptions)
  );
};

/**
 * Gets cookies and headers to pass through from a Next request (IncomingMessageWithCookies)
 * to an API call to express (via the ApiService).  Typically, this will not be needed, but
 * if you need to call an authenticated request that uses account id, user object id, or the
 * request IP address, this takes the next request and transforms it into headers for
 * the express call that pass through that information.
 */
const getPassthroughCookiesAndHeaders = (req: IncomingMessageWithCookies): HeadersInit => {
  const asExpressRequest = req as ExpressRequest;

  let xForwardHeader = asExpressRequest.headers['x-forwarded-for'] || '';
  if (Array.isArray(xForwardHeader)) {
    xForwardHeader = xForwardHeader.join(', ');
  }

  return {
    Cookie: asExpressRequest.headers.cookie || '',
    'x-forwarded-for': xForwardHeader,
  };
};

const postCache = createCache();

const post = async <ResponseDataType = unknown, BodyType = Record<string, unknown>>(
  target: ApiServiceTarget,
  url: string,
  body: BodyType | null = null,
  options: Options = {},
  headers: HeadersInit = {}
): Promise<ResponseDataType> => {
  const requestOptions = getRequestOptions(HttpMethod.POST, body, options, headers);
  return postCache.set(url, options.cacheKey, () =>
    request<ResponseDataType>(target, url, requestOptions)
  );
};

const put = <ResponseDataType = unknown, BodyType = Record<string, unknown>>(
  target: ApiServiceTarget,
  url: string,
  body: BodyType | null = null,
  options: RequestInit = {},
  headers: HeadersInit = {}
): Promise<ResponseDataType> => {
  const requestOptions = getRequestOptions(HttpMethod.PUT, body, options, headers);
  return request<ResponseDataType>(target, url, requestOptions);
};

const deleteMethod = <ResponseDataType = unknown>(
  target: ApiServiceTarget,
  url: string,
  options: RequestInit = {},
  headers: HeadersInit = {}
): Promise<ResponseDataType> => {
  const requestOptions = getRequestOptions(HttpMethod.DELETE, null, options, headers);
  return request<ResponseDataType>(target, url, requestOptions);
};

// JASON TODO: Move cookie functions and this entire file to zola-helpers
const getCookiebyRequest = (cookieName: string, req: ExpressRequest) => {
  if (!req.cookies) return undefined;
  return req.cookies[cookieName];
};

const getCookieHeader = (req: ExpressRequest) => {
  const authCookieNames = [
    cookieUtils.getLoginCookieName(),
    cookieUtils.getUserSessionCookieName(),
    cookieUtils.getUserSessionRefreshCookieName(),
  ];

  const finalString = authCookieNames.reduce((acc, cookieName) => {
    const cookieValue = getCookiebyRequest(cookieName, req);
    if (cookieValue) {
      return `${acc}${cookieName}=${cookieValue}; `;
    }
    return acc;
  }, '');

  return { Cookie: finalString };
};

const ApiService = {
  type: 'server',
  request,
  get,
  getPassthroughCookiesAndHeaders,
  post,
  put,
  delete: deleteMethod,
  clearCache: () => {
    getCache.clear();
    postCache.clear();
  },
  getCookieHeader,
};

export default ApiService;

export type ServerApiServiceType = typeof ApiService;
