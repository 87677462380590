import { Fragment, useRef } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import styled from '@emotion/styled';
import cx from 'classnames';

import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import { useAccountIncentivizationContext } from '~/contexts/AccountIncentivizationContext';
import { useUserContext } from '~/contexts/UserContext';
import { useToast } from '~/hooks/useToast';
import { SocialProofComponent } from '~/pages/couples/storefronts/components/SocialProofComponent';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { BookedVendorPromoModule } from '../../explore/components/BookedVendorPromoModule/BookedVendorPromoModule';
import { FirstMoveExpiration } from '../../inquiries/components/FirstMoveExpiration/FirstMoveExpiration';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { ShareOrFavorite } from './ShareOrFavorite';

import styles from './inquiryModule.module.less';

const PromoContainer = styled.div`
  container-type: inline-size;
  border: 1px solid ${COLORS3.BLACK_030};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const PromoWrapper = styled.div`
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${COLORS3.BLACK_010};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 24px;
`;

const ListingDetailPageBookedVendorPromo = () => {
  return (
    <PromoContainer>
      <PromoWrapper>
        <BookedVendorPromoModule showSearchBar={false} />
      </PromoWrapper>
    </PromoContainer>
  );
};

type InquiryModuleProps = {
  className?: string;
  hideVendorPromo?: boolean;
  hideSocialProof?: boolean;
  isFooter?: boolean;
};

const InquiryModule = (props: InquiryModuleProps) => {
  const { className, hideVendorPromo, hideSocialProof, isFooter } = props;
  const userContext = useUserContext();
  const { isDesktop } = useResponsiveDesign();
  const { isActive, isRapidRepeat, firstMove, dismissFirstMove, storefrontDetails } =
    useStorefrontDetails<CouplesStorefrontDetails>();
  const { taxonomyKey, socialProof } = storefrontDetails;
  const { positiveToast } = useToast();

  const { isBookedInquiryIncentiveAvailable } = useAccountIncentivizationContext();

  const showBookedInquiryIncentive = isBookedInquiryIncentiveAvailable();

  const trigger = useRef<HTMLDivElement>(null);

  const eyebrow =
    taxonomyKey === VENUES_TAXONOMY_KEY
      ? 'Want them for your wedding?'
      : 'Want to learn more about their pricing?';

  if (!userContext) {
    return <Fragment />;
  }

  return (
    <div
      className={cx(
        styles.inquiryModule,
        'hidden-sm hidden-xs',
        { [styles.footer]: isFooter },
        className
      )}
    >
      <div
        className={cx(styles.inquiry, {
          [styles.squareBottomBorder]: showBookedInquiryIncentive,
        })}
        id="inquiry-module"
      >
        {firstMove ? (
          <Fragment>
            <div className={cx(styles.firstMovePrompt)}>This vendor wants to work with you!</div>
            <FirstMoveExpiration firstMove={firstMove} />
          </Fragment>
        ) : (
          <div className={styles.text}>{eyebrow}</div>
        )}
        {!isActive && isRapidRepeat && (
          <P.BodyBase className={styles.rapidText}>
            We saved your answers from last time—reach out in seconds!
          </P.BodyBase>
        )}
        <div className={cx(styles.ctaButtons, { [styles.verticalStack]: Boolean(firstMove) })}>
          <InquiryButton
            position={1}
            section="OVERVIEW_SECTION"
            data-testid="inquiry-button"
            className={styles.inquiryButton}
            firstMoveUuid={firstMove?.uuid}
            entryPoint={isFooter ? 'FOOTER' : 'PRIMARY'}
          />
          {firstMove ? (
            <ButtonV3
              className={styles.ignoreButton}
              variant="secondary"
              size="large"
              onClick={() => {
                dismissFirstMove();
                positiveToast({ headline: `Got it—we'll let them know` });
              }}
            >
              Not interested
            </ButtonV3>
          ) : (
            <ShareOrFavorite />
          )}
        </div>
      </div>
      {showBookedInquiryIncentive && !hideVendorPromo && <ListingDetailPageBookedVendorPromo />}
      {isDesktop && !hideSocialProof && (
        <SocialProofComponent socialProof={socialProof} trigger={trigger} />
      )}
    </div>
  );
};

export default InquiryModule;
