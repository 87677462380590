import { ReactNode, Key, useRef } from 'react';

import { WeddingPlannerPackageView } from '@zola/svc-marketplace-ts-types';
import { PlusIcon } from '@zola/zola-ui/src/components/SvgIcons';
import Tooltip from '@zola/zola-ui/src/components/Tooltip/Tooltip';

import Checkmark from '~/components/common/ui/Checkmark/Checkmark';
import { useMouseEnter } from '~/hooks/useMouseEnter';
import { MappedOptionFacetView } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';
import { getDisplayPackageName } from '../utils/servicePackageHelpers';

import styles from './desktopServiceMatrix.module.less';

/**
 * Components that are used to help create DesktopServiceMatrix
 */

interface DataCellProps {
  includedInStartingPrice?: boolean;
  hasExtraCosts?: boolean;
}

const DataCell = ({ includedInStartingPrice, hasExtraCosts }: DataCellProps) => {
  const trigger = useRef<HTMLTableCellElement>(null);
  const { isEntered: showTooltip } = useMouseEnter({ trigger });

  if (includedInStartingPrice) {
    return (
      <td>
        <Checkmark />
      </td>
    );
  }

  if (hasExtraCosts) {
    return (
      <td ref={trigger}>
        <PlusIcon className={styles.plusIcon} />
        {showTooltip && <Tooltip className={styles.tooltip} text="Available for additional cost" />}
      </td>
    );
  }
  return <td className={styles.empty} />;
};

/* build as many td elements as we need */
const generateIcons = (packages: WeddingPlannerPackageView[], lineItemId: number) => {
  const cells: ReactNode[] = [];
  packages.forEach((plannerPackage, idx) => {
    const includedInStartingPrice = !!plannerPackage.packageOptions?.find(
      ({ id }) => lineItemId === id
    );
    const hasExtraCosts = !!plannerPackage.extraFeePackageOptions?.find(
      ({ id }) => lineItemId === id
    );
    cells.push(
      <DataCell
        hasExtraCosts={hasExtraCosts}
        includedInStartingPrice={includedInStartingPrice}
        key={idx.toString() as Key}
      />
    );
  });
  return cells;
};

export const Cell = ({ children, className }: { children: ReactNode; className?: string }) => (
  <td className={className}>{children}</td>
);

export const PackageNameHeader = ({ name }: { name: string }) => {
  return <th scope="col">{getDisplayPackageName(name)}</th>;
};

export const LineItemHeader = ({ id }: { id: number }) => {
  const { plannerPackageOptions } = useStorefrontDetails<CouplesStorefrontDetails>();
  return (
    <th scope="row" data-testid={id} className={styles.descriptor}>
      {
        (plannerPackageOptions as MappedOptionFacetView[]).find(
          ({ id: objectId }) => objectId === id
        )?.name
      }
    </th>
  );
};

export const LineItemRow = ({
  id,
  packages,
}: {
  id: number;
  packages: WeddingPlannerPackageView[];
}) => (
  <tr>
    <LineItemHeader id={id} />
    {generateIcons(packages, id)}
  </tr>
);
