import { Fragment } from 'react';

import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import { CouplesStorefrontDetailsVenue } from '~/types/storefrontDetails';
import { isClaimed } from '~/util/storefrontUtils';
import { formatRange } from '~/util/textUtils';

import Awards from '../../components/Awards/Awards';
import SocialLinks from '../../components/SocialLinks';
import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';

import './VenueIntroSection.less';

import styles from './venueIntroSection.module.less';

const VenueIntroSection = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsVenue>();
  const { name, description, social, address, venueDetails, options } = storefrontDetails;
  const venueTypeOptions = options.filter(
    (option) => option.parentKey === 'target-couple-venue-preference'
  );
  const venueSettingOptions = options.filter((option) => option.parentKey === 'venue-setting');
  const venueTypes = venueTypeOptions.map((option) => <li key={option.id}>{option.name}</li>);
  const venueSettings = venueSettingOptions.map((option) => <li key={option.id}>{option.name}</li>);

  if (!isClaimed(storefrontDetails) && !description && options.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="marketplace__venue-intro-section storefront__section">
      <div className="container">
        <hr />
        <div className="venue-intro-section__container">
          <div className="venue-intro-section__about">
            <H.Title2 className={styles.heading} presentation="h4" strong>
              About this venue
            </H.Title2>
            <P.BodySmall>{description}</P.BodySmall>
            <Awards />
            <div className="venue-intro-section__contact mt-primary">
              <div className="hidden-xs">
                <InquiryButton
                  className="venue-intro-section__contact-button"
                  section="INTRO_SECTION"
                  position={2}
                  data-testid="secondary-inquiry-button"
                />
                <SocialLinks social={social} className="venue-intro-section__social-icons" />
              </div>
            </div>
          </div>
          <div className="venue-intro-section__metadata">
            <H.TitleMedium3 className="metadata__heading" presentation="h6">
              Seated guest capacity
            </H.TitleMedium3>
            <ul className="metadata__list mb-primary">
              <li>
                {venueDetails && formatRange(venueDetails.minCapacity, venueDetails.maxCapacity)}
              </li>
            </ul>
            <H.TitleMedium3 className="metadata__heading" presentation="h6">
              Venue type
            </H.TitleMedium3>
            <ul className="metadata__list mb-primary">{venueTypes}</ul>
            <H.TitleMedium3 className="metadata__heading" presentation="h6">
              Venue setting
            </H.TitleMedium3>
            <ul className="metadata__list">{venueSettings}</ul>
          </div>
          {address && (
            <div className="venue-intro-section__location">
              <div className="venue-intro-section__map">
                <a
                  className="venue-intro-section__map-link"
                  href={address.googleMapsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Map to ${name}`}
                >
                  <img src={address.googleMapsImageUrl} alt="" />
                </a>
              </div>
              <div className="mt-tertiary">
                <a href={address.googleMapsUrl} target="_blank" rel="noopener noreferrer">
                  {address.singleLineAddress}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VenueIntroSection;
