import { useState } from 'react';

import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';

import cx, { Argument as ClassName } from 'classnames';
import './SectionDrawer.less';

export type SectionDrawerProps = {
  heading: JSX.Element;
  children: React.ReactNode;
  defaultIsOpen: boolean;
  className?: ClassName;
};

const SectionDrawer = (props: SectionDrawerProps) => {
  const { heading, children, className, defaultIsOpen = false } = props;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const toggleState = () => {
    setIsOpen(!isOpen);
  };

  const classes = cx('marketplace__section-drawer', isOpen ? 'open' : 'closed', className);

  return (
    <div className={classes} data-testid="section-drawer">
      <button onClick={() => toggleState()} type="button" className="section-drawer__button">
        {heading}
        <ChevronDownIcon direction={isOpen ? 'up' : 'down'} height={24} width={24} />
      </button>
      <div className="section-drawer__body-container">{children}</div>
    </div>
  );
};

export default SectionDrawer;
