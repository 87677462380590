import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { PhotoIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Photo';
import { COLORS3, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { COLORS_BACKGROUND } from '@zola/zola-ui/src/styles/emotion/colors';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

import styled from '@emotion/styled';

import { isSplitView } from '~/util/storefrontUtils';

const MAX_HEIGHT = '384px';

export const Container = styled.div<{ insufficientPhotos: boolean }>`
  position: relative;
  width: 100%;
  display: grid;
  grid-gap: ${SPACING.SM};
  grid-template-columns: 1fr;

  ${isSplitView() && `pointer-events: none;`}

  ${MEDIA_QUERY_V2.DESKTOP} {
    grid-template-columns: ${({ insufficientPhotos }) =>
      insufficientPhotos ? '66fr 34fr' : '55fr 20fr 25fr'};
  }
  ${MEDIA_QUERY_V2.DESKTOP_XL} {
    max-height: ${MAX_HEIGHT};
    grid-template-rows: repeat(2, calc((${MAX_HEIGHT} - ${SPACING.SM}) / 2));
  }
`;

export const Credit = styled.div`
  position: absolute;
  left: ${SPACING.S16};
  bottom: ${SPACING.S16};
  padding: 2px ${SPACING.XS};
  border-radius: ${SPACING.S16};
  background-color: rgba(0, 0, 0, 0.4);
  color: ${COLORS3.WHITE_100};
`;

export const Photo = styled.div<{ isPlaceholder?: boolean; insufficientPhotos: boolean }>`
  ${({ isPlaceholder }) =>
    isPlaceholder
      ? `
        display: flex;
        align-items: stretch;
        justify-content: center;
        background-color: ${COLORS_BACKGROUND.ILLUSTRATIVE}; // matches svg background color;
        border-radius: 20px;
      `
      : `
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
            border-radius: 20px;
          }

          &:hover {
            cursor: pointer;
          }
        }`}

  &:first-of-type {
    max-height: ${MAX_HEIGHT};
    ${({ insufficientPhotos }) => (insufficientPhotos ? 'grid-area: 1/1/3/2' : 'grid-area: 1/1/3')};
  }
  &:nth-of-type(2) {
    grid-area: 1/2/1/2;
    max-height: calc((384px / 2) - 6px);
  }
  &:nth-of-type(3) {
    grid-area: 2/2/2/2;
    max-height: 186px;
    max-height: calc((384px / 2) - 6px);
  }
  &:last-of-type {
    max-height: ${MAX_HEIGHT};
    ${({ insufficientPhotos }) => (insufficientPhotos ? 'grid-area: 1/2/3' : 'grid-area: 1/3/3')};
  }
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    &:not(:first-of-type) {
      display: none;
    }
  }
`;

export const StyledPhotoIcon = styled(PhotoIcon)`
  margin-right: ${SPACING.XS};
`;

export const StyledButton = styled(ButtonV3)`
  position: absolute;
  bottom: ${SPACING.S24};
  right: ${SPACING.S24};
`;

export const MobileText = styled.span`
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    display: none;
  }
`;

export const NotMobileText = styled.span`
  ${MEDIA_QUERY_V2.MOBILE} {
    display: none;
  }
`;
