import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';

import { StorefrontLinkTypeEnum } from '~/types/responseTypes';

import {
  isValidUrlWithProtocol,
  isValidUrlWithoutProtocol,
} from '../components/common/form/commonValidations';

type UrlProtocol = 'https://' | 'http://' | 'ftp://';

/**
 * Prepends https:// to text provided by a user if the link is valid but the protocol has been
 * omitted. Links are considered valid when they define a valid location (ip address or domain).
 * For example, these links will all be considered valid and trigger normalization:
 *
 * - google.co => https://google.co
 * - t.co      => https://t.co
 * - 8.8.8.8   => https://8.8.8.8
 * - www.go    => https://www.go
 *
 * @param {string} link
 * @param {string} protocol - The URL protocol, which defaults to https://
 */
export const normalizeLink = (link: string, protocol: UrlProtocol = 'https://'): string => {
  if (link && isValidUrlWithoutProtocol(link) && !isValidUrlWithProtocol(link)) {
    return `${protocol}${link}`;
  }
  return link;
};

/**
 * Same as normalizeLink, but for use in redux-form normalization where the first argument is the
 * current value, and other arguments are provided as well.
 *
 * @see https://redux-form.com/7.0.2/examples/normalizing/
 *
 * @param {string} link
 */
export const reduxFormLinkNormalizer = (link: string): string => {
  return normalizeLink(link);
};

/**
 * Checks whether or not the string provided is valid Zola URL
 *
 * accepted hostnames: zola.com, www.zola.com, homestore.zola.com
 * accepted hostnames (QA): qa.zola.com, qa-homestore.zola.com
 *
 * http://localhost will also be accepted for dev environment
 *
 * any invalid URL will return false, including non-HTTPS URLs
 *
 * @param {string} url
 *
 * @returns True if the URL is a valid Zola URL; false otherwise
 *
 * @example isValidZolaURL('https://www.zola.com') => true
 * @example isValidZolaURL('https://qa.zola.com') => true
 * @example isValidZolaURL('https://zola.com') => true
 * @example isValidZolaURL('http://zola.com') => false
 * @example isValidZolaURL('http://qa.zola.com') => false
 * @example isValidZolaURL('zola.com') => false
 * @example isValidZolaURL('invalid url') => false
 * @example isValidZolaURL('http://evil.com') => false
 * @example isValidZolaURL('http://evil.com?url=https://zola.com') => false
 */
export const isValidZolaURL = (url: string): boolean => {
  // try / catch block is used because new URL() will throw an error if URL is invalid
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch {
    parsedUrl = undefined;
  }

  if (parsedUrl) {
    if (parsedUrl.hostname === 'localhost' && getEnvironment() === EnvironmentTypes.DEVELOPMENT) {
      return true;
    }

    if (parsedUrl.hostname && parsedUrl.protocol === 'https:') {
      const acceptedHostnames = [
        'www.zola.com',
        'zola.com',
        'qa.zola.com',
        'homestore.zola.com',
        'qa-homestore.zola.com',
      ];
      return acceptedHostnames.includes(parsedUrl.hostname);
    }
  }
  return false;
};

/**
 * Redirects the user to the not found page (404 page)
 */
export const redirectToNotFound = (): void => {
  const redirectUrl = `${window.location.protocol}//${window.location.hostname}/not-found`;
  window.location.href = redirectUrl;
};

const videoLinksObj: Partial<Record<StorefrontLinkTypeEnum, string[]>> = {
  [StorefrontLinkTypeEnum.FACEBOOK_VIDEO]: ['/watch/?v='],
  [StorefrontLinkTypeEnum.MATTERPORT]: [`/show/?m=`],
};

export const getYouTubeVideoIdByUrl = (url: string) => {
  const reg =
    /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w-]{10,20})/i;
  const match = url.match(reg);
  if (match) {
    return match[9];
  }

  return url;
};

export const getVimeoVideoIdByUrl = (url: string) => {
  const firstPart = url.split('?')[0].split('/');
  const videoId = firstPart[firstPart.length - 1];

  if (videoId) {
    return videoId;
  }

  return url;
};

export const getVideoIdFromUrl = (videoType: StorefrontLinkTypeEnum, url: string) => {
  if (videoType === StorefrontLinkTypeEnum.YOUTUBE) return getYouTubeVideoIdByUrl(url);
  if (videoType === StorefrontLinkTypeEnum.VIMEO) return getVimeoVideoIdByUrl(url);

  const videoLinks = videoLinksObj[videoType];

  let tempVideoId;
  // eslint-disable-next-line consistent-return
  videoLinks?.forEach((partialUrl: string) => {
    if (url.includes(partialUrl)) {
      // split the string into two parts [partialUrl, videoId];
      tempVideoId = url.split(partialUrl);
    }
  });

  return tempVideoId?.[1] ?? url;
};
