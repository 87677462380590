import { Fragment, useState } from 'react';

import { pluralize } from '@zola-helpers/client/dist/es/transformers';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import StarRatings from '@zola/zola-ui/src/components/StarRatings/StarRatings';
import { CheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Check';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import cx from 'classnames';

import { MappedReviewView } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { formatEventDate, formatRelativeTime } from '~/util/formatters';
import { formatRating } from '~/util/reviewUtils';
import getCanonicalUrl, { getPublicReviewUrl } from '~/util/storefrontUrl';
import { isPreview } from '~/util/storefrontUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import JumpMenuSection, { JumpMenuSectionIds } from './JumpMenuSection';
import { ReviewsSectionGallery } from './ReviewsSectionGallery';

import styles from './reviewsSection.module.less';

const StorefrontResponse = ({ review }: { review: MappedReviewView }): JSX.Element => {
  const { responses } = review;
  const response = responses[0];
  const responseDate = formatEventDate(response?.createdAt || review.respondedAt);
  const responseText = response?.responseText || review.responseText;

  if (!responseText) {
    return <Fragment />;
  }

  return (
    <div className={styles.response}>
      <div className={styles.responseInfo}>
        <span>Response from vendor</span>
        <span className={styles.bullet}>&bull;</span>
        <span>{responseDate}</span>
      </div>
      <div className={styles.responseText}>{responseText}</div>
    </div>
  );
};

const Review = ({ review }: { review: MappedReviewView }) => {
  const {
    title,
    overallRating,
    reviewerName,
    sentToBvAt,
    reviewText,
    incentivized,
    reviewImageIds,
  } = review;
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { isMobile } = useResponsiveDesign();
  const reviewDate = sentToBvAt ? formatRelativeTime(sentToBvAt) : null;
  return (
    <div className={styles.review}>
      <H.TitleMedium3 className={styles.title} presentation="h5">
        {title}
      </H.TitleMedium3>
      <div className={styles.rating}>
        <div className={styles.stars}>
          <StarRatings
            totalStars={5}
            activeStars={overallRating}
            reviewCount=""
            fillColor={COLORS.YELLOW_100}
          />
          <span className={styles.verified}>
            <span className={styles.bullet}>&bull;</span>
            <CheckIcon className={styles.icon} width={16} height={16} title="" />
            <span>Verified review</span>
            {incentivized && (
              <>
                <span className={styles.bullet}>&bull;</span>
                <span>{isMobile ? 'Incentivized' : 'Incentivized review'}</span>
              </>
            )}
          </span>
        </div>
        <div className={styles.info}>
          <span className={styles.bullet}>&bull;</span>
          <span>{reviewerName}</span>
          {reviewDate && (
            <Fragment>
              <span className={styles.bullet}>&bull;</span>
              <span>{reviewDate}</span>
            </Fragment>
          )}
        </div>
      </div>
      <P.BodyBase className={styles.text}>{reviewText}</P.BodyBase>
      <StorefrontResponse review={review} />
      <ReviewsSectionGallery className={styles.reviewGallery} photoUuids={reviewImageIds || []} />
    </div>
  );
};

const ReviewsContent = ({
  averageRating,
  reviews,
  reviewUrl,
}: {
  averageRating: number;
  reviews: MappedReviewView[];
  reviewUrl: string;
}): JSX.Element => {
  const reviewsToShow = 4;
  const [showAllReviews, setShowAllReviews] = useState(false);
  return (
    <div className={styles.reviewsContent}>
      <div className={styles.reviewsHeader}>
        <div>
          <div className={styles.reviewsHeaderStars}>
            <StarRatings
              totalStars={5}
              activeStars={formatRating(averageRating)}
              reviewCount=""
              fillColor={COLORS.YELLOW_100}
              starSize="large"
            />
            <P.BodyLarge className={styles.reviewsHeaderStarsText}>
              {formatRating(averageRating)} out of 5
            </P.BodyLarge>
          </div>
          <P.BodySmall color="BLACK_075">
            {pluralize('review', 'reviews', reviews.length, true)}
          </P.BodySmall>
        </div>
        <ButtonV3
          className={styles.reviewsHeaderCta}
          component="a"
          href={reviewUrl}
          textTransform={false}
          rel="noindex nofollow"
          variant="secondary"
        >
          Write a review
        </ButtonV3>
      </div>
      <ul className={styles.reviews}>
        {reviews.slice(0, showAllReviews ? reviews.length : reviewsToShow).map((review, index) => (
          <li key={index}>
            <Review review={review} />
          </li>
        ))}
      </ul>
      {!showAllReviews && reviews.length > reviewsToShow && (
        <div className={styles.showAllButton}>
          <ButtonV3
            variant="secondary"
            onClick={() => {
              setShowAllReviews(true);
            }}
          >
            Show all
          </ButtonV3>
        </div>
      )}
    </div>
  );
};

const ReviewsSection = (): JSX.Element | null => {
  const { storefrontDetails, isUnclaimed } = useStorefrontDetails<CouplesStorefrontDetails>();
  const {
    averageReviewsRate,
    recommendations: reviews,
    slug,
    taxonomyKey,
    uuid,
  } = storefrontDetails;
  const reviewUrl = getPublicReviewUrl(uuid, getCanonicalUrl(taxonomyKey, slug));
  const hasAnyReviews = reviews.length > 0;

  if (!uuid || isUnclaimed || isPreview()) {
    return <></>;
  }

  return (
    <JumpMenuSection
      className={cx('marketplace__reviews-section', 'storefront__section', styles.reviewsSection)}
      id={JumpMenuSectionIds.Reviews}
      sectionName={JumpMenuSectionIds.Reviews}
    >
      <a id="reviews" />
      <div className="container">
        <hr className={styles.hr} />
        <div className={styles.reviewsSectionHeader}>
          <H.Title2 presentation="h4" strong>
            Reviews
          </H.Title2>
          {hasAnyReviews && (
            <ButtonV3
              className={styles.reviewsSectionHeaderCta}
              component="a"
              variant="secondary"
              size="large"
              textTransform={false}
              href={reviewUrl}
              rel="noindex nofollow"
            >
              Write a review
            </ButtonV3>
          )}
        </div>
        {hasAnyReviews ? (
          <ReviewsContent
            averageRating={averageReviewsRate}
            reviews={reviews}
            reviewUrl={reviewUrl}
          />
        ) : (
          <div className={styles.writeReview}>
            <span>Worked with this vendor?</span>
            <ButtonV3
              component="a"
              variant="primary"
              size="large"
              textTransform={false}
              href={reviewUrl}
              rel="noindex nofollow"
            >
              Write a review
            </ButtonV3>
          </div>
        )}
      </div>
    </JumpMenuSection>
  );
};

export default ReviewsSection;
