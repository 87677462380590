import React, {
  CSSProperties,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AccordionV2 from '@zola/zola-ui/src/components/AccordionV2';
import { AccordionSection } from '@zola/zola-ui/src/components/AccordionV2/AccordionV2.styles';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { FloorPlanIcon } from '@zola/zola-ui/src/components/SvgIconsV3/FloorPlan';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import H from '@zola/zola-ui/src/typography/Headings';
import { BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';

import styled from '@emotion/styled';
import cx from 'classnames';
import _debounce from 'lodash/debounce';

import { CardPhoto } from '~/components/common/cards/VendorCard/VendorCardPhoto';
import { VenueSpaceView } from '~/types/responseTypes';
import { CouplesStorefrontDetailsVenue } from '~/types/storefrontDetails';

import { useLightboxContext } from '../../contexts/LightboxContext';
import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';
import { useSpaceDetails } from '../hooks/useSpaceDetails';

import styles from './eventSpaces.module.less';

type SectionProps = {
  title: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const BODY_SMALL_LINE_HEIGHT = 22;
const GRADIENT_HEIGHT = BODY_SMALL_LINE_HEIGHT;
const H6_LINE_HEIGHT = 20;
const BODY_GAP = 16;

const TOP_GRADIENT = `linear-gradient(to bottom, transparent 0%, black ${GRADIENT_HEIGHT}px)`;
const BOTTOM_GRADIENT = `linear-gradient(to bottom, black calc(100% - ${GRADIENT_HEIGHT}px), transparent 100%)`;
const TOP_AND_BOTTOM_GRADIENT = `linear-gradient(to bottom, transparent 0%, black ${GRADIENT_HEIGHT}px, black calc(100% - ${GRADIENT_HEIGHT}px), transparent 100%)`;

const StyledAccordionV2 = styled(AccordionV2)`
  ${AccordionSection} {
    border-color: ${COLORS3.BLACK_010} !important;
  }
`;
const Label = ({ children }: { children: string }) => {
  return (
    // If you change this presentation, you need to update H6_LINE_HEIGHT
    <H.Title4 presentation="h6" strong className={styles.label}>
      {children}
    </H.Title4>
  );
};

const Section = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {
  const { className, title, children, ...rest } = props;
  return (
    <section className={className} {...rest} ref={ref}>
      <Label>{title}</Label>
      {children}
    </section>
  );
});

type DetailContent = (string | null) | (string | null)[];
type DetailItem = { title: string; className?: string; content: DetailContent };
type DetailListEntry = DetailItem | DetailItem[];

const hasContent = (item: DetailItem) =>
  Array.isArray(item.content) ? item.content.some(Boolean) : Boolean(item.content);

const listEntryHasContent = (entry: DetailListEntry) =>
  Array.isArray(entry) ? entry.some(hasContent) : hasContent(entry);

const SpaceItemContent = ({ content }: { content: DetailContent }) => {
  if (!content) return <Fragment />;

  const contentArray = Array.isArray(content) ? content : [content];

  return (
    <Fragment>
      {contentArray.filter(Boolean).map((item, index) => {
        // If you change the height of this, you need to change the BODY_SMALL_LINE_HEIGHT constant
        return <BodySmall key={index}>{item}</BodySmall>;
      })}
    </Fragment>
  );
};

type SpaceDetailItemProps = { item: DetailListEntry } & React.HTMLAttributes<HTMLDivElement>;

const SpaceDetailItem = forwardRef<HTMLDivElement, SpaceDetailItemProps>((props, ref) => {
  const { item, className, ...rest } = props;
  if (Array.isArray(item)) {
    if (item.some(hasContent)) {
      return (
        <div className={cx(styles.spaceDetailsPair, className)} {...rest} ref={ref}>
          {item.filter(hasContent).map((subItem, index) => (
            <Section className={subItem.className} title={subItem.title} key={index}>
              <SpaceItemContent content={subItem.content} />
            </Section>
          ))}
        </div>
      );
    }
    return <Fragment />;
  }
  if (hasContent(item)) {
    return (
      <Section title={item.title} className={cx(item.className, className)} {...rest} ref={ref}>
        <SpaceItemContent content={item.content} />
      </Section>
    );
  }
  return <Fragment />;
});

const EventSpaceCard = ({
  space,
  isMobile = false,
}: {
  space: VenueSpaceView;
  isMobile?: boolean;
}) => {
  const { seatedCapacity, spaceOptions, spacePricing, squareFeet, standingCapacity } =
    useSpaceDetails({ space });

  const {
    description,
    floorplanUuid,
    guestsFeel,
    included,
    name,
    pullQuote,
    venueSpaceGalleryViews,
  } = space;
  const getItems = (): DetailListEntry[] => {
    return [
      [
        { title: 'Guest capacity', content: [seatedCapacity, standingCapacity] },
        { title: 'Square footage', content: squareFeet },
      ],
      {
        title: 'Ideal for',
        content: spaceOptions,
      },
      {
        title: 'Guests often feel this space is',
        content: guestsFeel ? `“${guestsFeel}”` : null,
      },
      {
        title: 'Included in this room:',
        content: included,
        className: styles.included,
      },
      {
        title: 'Room cost',
        content: spacePricing,
      },
    ];
  };
  const [firstItem, ...restItems] = getItems().filter(listEntryHasContent);

  const imageIds = useMemo(() => {
    const spacePhotos = venueSpaceGalleryViews.map((view) => view.imageId);
    if (floorplanUuid) {
      return [...spacePhotos, floorplanUuid];
    }
    return spacePhotos;
  }, [venueSpaceGalleryViews, floorplanUuid]);

  /**
   * Ref to the scrollable content area for the event space.   We use this to
   * 1) know if the page has rendered on the client (with bodyRef.current) and
   * 2) determine if we have hidden content and need to render the show more
   *    link as well as a gradient on the content area masking part of the content
   *    to indicate that scrolling is possible
   */
  const bodyRef = useRef<HTMLDivElement>(null);

  /**
   * Ref to give us the height of the space pull quote and description. The height
   * of this element rolls into the visible body height calculation.
   */
  const descriptionRef = useRef<HTMLDivElement>(null);

  /**
   * Ref to give us the first item height (if we have one).   The height of this element
   * rolls into the visible body height calculation.
   */
  const firstItemRef = useRef<HTMLDivElement>(null);

  /**
   * Calculate how much of the body content should be visible until the visitor
   * clicks show more.
   */
  const calculateVisibleBodyHeight = useCallback(() => {
    if (!bodyRef.current) return undefined; // haven't yet rendered on the client

    // How tall is the pull quote + description
    const descriptionHeight = descriptionRef.current?.offsetHeight || 0;

    // If we have it, how tall is the first data item
    const firstItemHeight = firstItemRef.current?.offsetHeight || 0;

    let height = descriptionHeight;
    if (descriptionHeight > 0 && firstItemHeight > 0) {
      height = height + BODY_GAP; // gap between description and first item, from the spaceBody in the css
    }
    height = height + firstItemHeight;

    // IF we have more items, we have a gap, a header and at least one line of
    // content in the next item.  Lines are BodySmall, so they are 22px tall
    //
    if (restItems.length > 0) {
      height = height + BODY_GAP; // gap
      height = height + H6_LINE_HEIGHT; // header
      height = height + BODY_SMALL_LINE_HEIGHT; // first line of next items
    }

    return height;
  }, [restItems.length]);

  // State variable for the visible body height.  This triggers effect hooks and updates
  const [visibleBodyHeight, setVisibleBodyHeight] = useState<number | undefined>(
    calculateVisibleBodyHeight()
  );

  /** Calculate the visible body height and set the state variable */
  const determineVisibleBodyHeight = useCallback(() => {
    setVisibleBodyHeight(calculateVisibleBodyHeight());
  }, [calculateVisibleBodyHeight]);

  /** On load, determine visible height */
  useEffect(() => {
    determineVisibleBodyHeight();
  }, [determineVisibleBodyHeight]);

  /**
   * When the screensize changes, re-calculate the visible body height (debounced
   * so it doesn't happen too often) because our pull quote / description might wrap
   * to different line break.
   */
  const resizeListener = useMemo(
    () => _debounce(determineVisibleBodyHeight, 500),
    [determineVisibleBodyHeight]
  );
  useEffect(
    () => {
      window.addEventListener('resize', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    },
    // Because we memoized the debounce func, it will only invoke useEffect once the timeout completes.
    [resizeListener]
  );

  /** State variable to track if the body scroll size is larger than our computed visible height */
  const [hasHiddenContent, setHasHiddenContent] = useState(false);
  const computeHasHiddenContent = useCallback(() => {
    if (!bodyRef.current) return false;
    const body = bodyRef.current;
    setHasHiddenContent(body.scrollHeight > body.clientHeight);
  }, []);

  useEffect(() => {
    computeHasHiddenContent();
  }, [visibleBodyHeight, computeHasHiddenContent]);

  /** State variable to handle Show more and expanding the body content */
  const [isExpanded, setIsExpanded] = useState(false);

  /**
   * Gradient that is applied when the body is scrolled.  The gradient might be
   * at the bottom, top, or both depending on the scroll position
   */
  const [maskImage, setMaskImage] = useState<string | undefined>(undefined);

  /**
   * Determines the maskImage based on the scroll position of the body.
   */
  const setScrolled = useCallback(() => {
    if (bodyRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = bodyRef.current;
      const isScrollable = scrollHeight > clientHeight;
      if (isScrollable) {
        const isScrolledToBottom = scrollHeight - clientHeight === scrollTop;
        const isScrolledToTop = scrollTop === 0;
        if (isScrolledToBottom) {
          setMaskImage(TOP_GRADIENT);
        } else if (isScrolledToTop) {
          setMaskImage(BOTTOM_GRADIENT);
        } else {
          setMaskImage(TOP_AND_BOTTOM_GRADIENT);
        }
      } else {
        setMaskImage(undefined);
      }
    }
  }, []);

  // When the body is scrolled or the height changes, determine the mask image
  useEffect(() => {
    setScrolled();
  }, [visibleBodyHeight, setScrolled]);

  // Listen for scroll events in the body and update the mask image
  useEffect(() => {
    const { current } = bodyRef;
    if (current) {
      current.addEventListener('scroll', setScrolled);
    }
    return () => {
      current?.removeEventListener('scroll', setScrolled);
    };
  }, [bodyRef, setScrolled]);

  const bodyStyle: CSSProperties = useMemo(() => {
    if (isExpanded || isMobile) return {};

    return {
      maxHeight: visibleBodyHeight,
      maskImage: hasHiddenContent ? maskImage : undefined,
    };
  }, [isExpanded, isMobile, visibleBodyHeight, hasHiddenContent, maskImage]);

  const { openLightbox } = useLightboxContext();

  return (
    <div className={styles.eventSpace}>
      <div>
        <H.Title3 presentation="h5" strong className={styles.spaceName}>
          {name}
        </H.Title3>

        <div className={styles.spaceBody} ref={bodyRef} style={bodyStyle}>
          {/* // We will show all the description */}
          <div className={styles.spaceDescription} ref={descriptionRef}>
            {pullQuote && (
              <div>
                <BodySmall strong>{pullQuote}</BodySmall>
              </div>
            )}
            <BodySmall>{description}</BodySmall>
          </div>

          {/* We will show the first item */}
          {firstItem && <SpaceDetailItem item={firstItem} ref={firstItemRef} />}

          {/* These items will be hidden behind a show more */}
          {restItems.map((item, index) => (
            <SpaceDetailItem item={item} key={index} />
          ))}
          {floorplanUuid && (
            <LinkV2
              role="button"
              noTextTransform
              onClick={() => openLightbox(floorplanUuid)}
              className={styles.floorPlanLink}
            >
              <FloorPlanIcon />
              {name} floor plan
            </LinkV2>
          )}
        </div>
        {Boolean(hasHiddenContent) && !isExpanded && !isMobile && (
          <div>
            <LinkV2 role="button" noTextTransform onClick={() => setIsExpanded(true)}>
              Show more
            </LinkV2>
          </div>
        )}
      </div>
      <div>
        {imageIds.length > 0 && (
          <CardPhoto
            alternateImage={<Fragment />}
            imageIds={imageIds.slice(0, 5)}
            scaleOnHover={false}
            showCarousel
            lazyload={false}
            readOnly={false}
            scrollContainer={undefined}
            aspectRatio="4:3"
            photoOnClick={(uuid: string) => openLightbox(uuid, 'Vendor photos')}
            className={styles.eventSpacePhoto}
          />
        )}
      </div>
    </div>
  );
};
export const EventSpaces = () => {
  const classes = cx('marketplace__showcases-section storefront__section');

  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsVenue>();
  const { venueDetails } = storefrontDetails;
  const { spaces } = venueDetails;

  const accordionItems = useMemo(() => {
    return spaces.map((space) => {
      return {
        hed: space.name,
        dek: <EventSpaceCard space={space} key={space.uuid} isMobile />,
        id: space.uuid,
      };
    });
  }, [spaces]);

  if ((spaces?.length || 0) > 0) {
    return (
      <div className={classes}>
        <div className="container">
          <hr className={styles.sectionHr} />
          <H.Title2 className={styles.heading} presentation="h4" strong>
            Our event spaces
          </H.Title2>

          <div className={cx(styles.eventSpaces, styles.desktop)}>
            {spaces.map((space) => {
              return <EventSpaceCard space={space} key={space.uuid} />;
            })}
          </div>
          <div className={cx(styles.eventSpaces, styles.mobile)}>
            <StyledAccordionV2
              items={accordionItems}
              initialExpandedItems={[spaces[0]?.uuid]}
              allowMultiple
            />
          </div>
        </div>
      </div>
    );
  }
  return <Fragment />;
};
