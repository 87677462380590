import { useEffect, useState } from 'react';

import {
  BANDS_DJS_TAXONOMY_KEY,
  VendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { CTA_TYPES } from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';
import { BUSINESS_UNIT } from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';
import { StorefrontVideoGalleryView } from '@zola/svc-marketplace-ts-types';
import { Carousel } from '@zola/zola-ui/src/components/Carousel';

import _chunk from 'lodash/chunk';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as NotificationsActions from '~/actions/notificationActions';
import {
  getStorefrontVideoGallery,
  replaceVideoGallery,
  cancelEdit,
  swapVideoOrder,
  MAX_VIDEO_GALLERY_ENTRIES,
} from '~/actions/vendors/vendorStorefrontVideoActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import VendorsEditLayout from '~/components/common/layouts/vendorEditLayout/VendorEditLayout';
import Loader from '~/components/common/ui/loader/Loader';
import WithDraggablePhoto from '~/components/common/vendors/WithDraggablePhoto';
import { InjectedStorefrontDetails } from '~/components/common/vendors/WithVendorStorefront';
import { useAppDispatch, useAppSelector, RootState } from '~/reducers';
import {
  getVideoGallery,
  isLoaded,
  isPristine,
  getEditIndex,
  isEditing,
} from '~/reducers/entities/videoGalleryReducer';
import { getVideoGallerySection } from '~/selectors/vendorStorefrontSectionsSelectors';
import { getVendorTaxonomyKey } from '~/selectors/vendorStorefrontSelectors';
import { VendorStorefrontDetails } from '~/types/storefrontDetails';
import Logger, { StandardLogFn } from '~/util/logger';
import { trackCtaClicked, TrackingProductLocation } from '~/util/trackingHelper';

import EditButtonBlock from '../components/EditButtonBlock';
import EditPageHeading from '../components/EditPageHeading';
import Example from '../components/videoGallery/Example';
import GalleryCard from '../components/videoGallery/GalleryCard';
import VideoEditForm from '../components/videoGallery/VideoEditForm';

import './videoGallery.less';

const carouselSettings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  dots: true,
  arrows: false,
};

const COLUMNS = 4;

const DraggableGalleryCard = WithDraggablePhoto(GalleryCard);

type VideoGalleryDisplayProps = {
  canEdit?: boolean;
  canPlay?: boolean;
  columnsPerRow: number;
  gallery: StorefrontVideoGalleryView[];
  swapItems?: (first: number, second: number) => void;
  taxonomyKey: VendorTaxonomyKey;
};

export const VideoGalleryDisplay = ({
  gallery,
  columnsPerRow,
  canEdit,
  canPlay,
  swapItems,
  taxonomyKey,
}: VideoGalleryDisplayProps) => {
  let cellClass = 'col-xs-12 col-sm-6';

  switch (columnsPerRow) {
    case 4:
      cellClass = 'col-md-3 col-sm-6 col-xs-12';
      break;
    default:
      Logger.error('Unsupported column per-row choice in VideoGalleryDisplay');
  }

  const [galleryForDisplay, setGalleryForDisplay] = useState<StorefrontVideoGalleryView[]>([]);
  useEffect(() => {
    const galleryWithPlaceholders: StorefrontVideoGalleryView[] = [
      ...new Array(MAX_VIDEO_GALLERY_ENTRIES),
    ];
    gallery
      .filter((v) => !!v)
      .forEach((video, index) => {
        galleryWithPlaceholders[index] = video;
      });
    setGalleryForDisplay(canEdit ? galleryWithPlaceholders : gallery);
  }, [canEdit, gallery]);

  const [multiRowGalleryForDisplay, setMultiRowGalleryForDisplay] = useState<
    StorefrontVideoGalleryView[][]
  >([]);
  useEffect(() => {
    setMultiRowGalleryForDisplay(_chunk(galleryForDisplay, COLUMNS));
  }, [galleryForDisplay]);

  const cardsProps = galleryForDisplay.map((video, galleryIndex) => {
    return {
      key: `cell-${galleryIndex}`,
      index: galleryIndex,
      video,
      canEdit,
      canPlay,
      taxonomyKey,
    };
  });

  const carouselCards = cardsProps.map((cardProps) => {
    return <GalleryCard {...cardProps} key={cardProps.key} />;
  });

  return (
    <div className="marketplace-video-gallery-wrapper">
      <Carousel settings={carouselSettings} content={carouselCards} className="visible-xs" />

      <div className="hidden-xs">
        <DndProvider backend={HTML5Backend}>
          {multiRowGalleryForDisplay.map((row, rowIndex) => {
            return (
              <div className="row" key={`row-${rowIndex}`}>
                {row.map((_, cellIndex) => {
                  const galleryIndex = rowIndex * columnsPerRow + cellIndex;
                  const cardProps = cardsProps[galleryIndex];
                  return canEdit && swapItems && cardProps.video ? (
                    <DraggableGalleryCard
                      swapItems={swapItems}
                      // @ts-expect-error this prop is probably being overwritten by the spread
                      index={galleryIndex}
                      className={cellClass}
                      {...cardProps}
                    />
                  ) : (
                    <GalleryCard {...cardProps} className={cellClass} />
                  );
                })}
              </div>
            );
          })}
        </DndProvider>
      </div>
    </div>
  );
};

type VideoGalleryProps = {
  videoGallerySection: {
    heading?: string;
    title: string;
    imgSrc?: string;
  };
};

/**
 * The video gallery edit screen.  The gallery is only persisted on save, changes
 * made before save will be discarded.
 */
const VideoGallery = ({
  videoGallerySection,
}: VideoGalleryProps & Partial<InjectedStorefrontDetails>) => {
  const dispatch = useAppDispatch();

  // This is the section, on the listing page, and the next step.  It provides us text and icons
  // const videoGallerySection = useAppSelector(getVideoGallerySection);

  // Current video gallery
  const videoGallery = useAppSelector(getVideoGallery) || [];
  const taxonomyKey = useAppSelector(getVendorTaxonomyKey);

  // Redux selectors for the gallery state.
  const loaded = useAppSelector(isLoaded);
  const editing = useAppSelector(isEditing);
  const editIndex = useAppSelector(getEditIndex);
  const pristine = useAppSelector(isPristine);

  const storefront = useCurrentStorefront() as VendorStorefrontDetails;

  const history = useHistory();
  const returnToListing = () => {
    // Reset the gallery
    dispatch(getStorefrontVideoGallery(storefront.uuid)).catch(StandardLogFn);
    history.push('/inspire/vendors/listing');
  };

  const [requestedGallery, setRequestedGallery] = useState(false);
  useEffect(() => {
    if (storefront.uuid && (!requestedGallery || !loaded)) {
      setRequestedGallery(true); // we can't just use redux, we need to refetch
      dispatch(getStorefrontVideoGallery(storefront.uuid)).catch(StandardLogFn);
    }
  }, [dispatch, loaded, requestedGallery, storefront.uuid]);

  const validVideos = videoGallery.filter((video) => !!video);

  const [isSaving, setIsSaving] = useState(false);
  const persistGallery: React.FormEventHandler = (event) => {
    event.preventDefault();
    trackCtaClicked({
      location: TrackingProductLocation.EDIT_LISTING,
      section: 'VIDEO_GALLERY',
      ctaId:
        storefront.taxonomyKey === BANDS_DJS_TAXONOMY_KEY ? 'Performance Samples' : 'Video Samples',
      cta: '/inspire/vendors/listing',
      ctaType: CTA_TYPES.BUTTON,
      storefrontUuid: storefront.uuid,
      businessUnit: BUSINESS_UNIT.MARKETPLACE_VENDORS,
    });
    const newGalleryEntries = validVideos.map((entry) => entry.uuid);
    dispatch(replaceVideoGallery(storefront.uuid, newGalleryEntries))
      .then((response) => {
        setIsSaving(false);
        if (response) {
          const message =
            response.length < 2
              ? 'Your video link has been saved but at least TWO are required to publish!'
              : 'Your listing has been updated.';
          dispatch(NotificationsActions.success({ message }));
          returnToListing();
        }
      })
      .catch(StandardLogFn);
  };

  if (!loaded) {
    return <Loader />;
  }

  const invalid = validVideos.length === 0;
  const heading = editing ? (
    'Add a wedding video sample.'
  ) : (
    <span>
      {videoGallerySection.heading} Add at least <u>two</u> videos.
    </span>
  );
  const subHeading = editing
    ? 'Enter your Vimeo or YouTube video ID below. You can feature up to eight videos on your listing. '
    : 'We support up to 8 videos hosted on Vimeo and YouTube.';

  return (
    <VendorsEditLayout
      id="marketplace-vendors-storefront-video-gallery"
      sectionTitle={videoGallerySection.title}
      saveFunc={editing ? undefined : persistGallery}
      disableSave={isSaving || pristine || invalid}
      backOnClick={
        editing
          ? () => {
              dispatch(cancelEdit());
            }
          : undefined
      }
      backCopy={editing ? 'Back to Gallery' : 'Back'}
    >
      <EditPageHeading
        heading={heading}
        subHeading={subHeading}
        titleContainerClassName="video-gallery__heading"
      />
      <hr className="video-gallery__top-divider" />

      {editing && (
        <VideoEditForm
          index={editIndex}
          storefrontUuid={storefront.uuid}
          video={validVideos[editIndex as number]}
        />
      )}

      {!editing && (
        <form onSubmit={persistGallery}>
          <VideoGalleryDisplay
            gallery={videoGallery}
            columnsPerRow={COLUMNS}
            canEdit={!editing}
            canPlay={!editing}
            swapItems={(fromIndex: number, toIndex: number) =>
              dispatch(swapVideoOrder(fromIndex, toIndex))
            }
            taxonomyKey={taxonomyKey}
          />
          <EditButtonBlock
            cancelFunc={returnToListing}
            pristine={pristine}
            saving={isSaving}
            isValid={!invalid}
          />
          <hr className="hidden-xs video-gallery__bottom-divider" />
          <Example />
        </form>
      )}
    </VendorsEditLayout>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    videoGallerySection: getVideoGallerySection(state),
  };
};

export default connect(mapStateToProps, null)(VideoGallery);
