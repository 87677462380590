import { ButtonV2 } from '@zola/zola-ui/src/components/Button';

import { useModal } from '~/contexts/ModalContext';

import styles from './descriptionModal.module.less';

interface PackageCardContentProps {
  description: string;
  planningLevel: string;
}

const DescriptionModal = (props: PackageCardContentProps) => {
  const { description, planningLevel } = props;
  const { hideModal } = useModal();
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{planningLevel}</h3>
      <p className={styles.description}>{description}</p>
      <div className={styles.dismiss}>
        <ButtonV2 onClick={hideModal}>Got it</ButtonV2>
      </div>
    </div>
  );
};

export default DescriptionModal;
