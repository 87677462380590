import { Fragment, useMemo } from 'react';

import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { TagMoneyIcon } from '@zola/zola-ui/src/components/SvgIconsV3/TagMoney';
import { COLORS3, FONT } from '@zola/zola-ui/src/styles/emotion';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import styled from '@emotion/styled';

import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import useLastInquiryToStorefront from '~/components/common/ui/inquiries/useLastInquiryToStorefront';
import { useAccountIncentivizationContext } from '~/contexts/AccountIncentivizationContext';
import { useToast } from '~/hooks/useToast';
import { FirstMoveExpiration } from '~/pages/couples/inquiries/components/FirstMoveExpiration/FirstMoveExpiration';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { useStickyScroll } from '~/util/stickyBtn';
import { isClaimed } from '~/util/storefrontUtils';

import {
  BookedVendorPromoActivation,
  BookedVendorsPromoTermsLink,
} from '../../explore/components/BookedVendorPromoModule/BookedVendorPromoModule';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { FavoriteButton } from './ShareOrFavorite';
import UnclaimedModule from './UnclaimedModule';

import styles from './StorefrontDetailsStickyFooter.module.less';

type FirstMoveInviteProps = {
  firstMove: StorefrontFirstMoveView;
};

const FirstMoveInvite = ({ firstMove }: FirstMoveInviteProps) => {
  if (firstMove) {
    return (
      <div>
        <P.BodyBase className={styles.firstMovePrompt}>
          This vendor wants to work with you!
        </P.BodyBase>
        <FirstMoveExpiration className={styles.firstMoveExpiration} firstMove={firstMove} />
      </div>
    );
  }
  return <Fragment />;
};

const RapidRepeatText = () => {
  return (
    <P.BodyBase className={styles.rapidRepeatText}>
      We saved your answers from last time—reach&nbsp;out&nbsp;in&nbsp;seconds!
    </P.BodyBase>
  );
};

type StorefrontDetailsStickyFooterProps = {
  forceShow?: boolean;
  /** Optional click handler to fire on the inquire or first move buttons */
  onClick?: () => void;
};

const Incentive = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS3.BAY_100};
  border-radius: 50%;
  padding: 2px;
`;

const IncentiveText = styled(P.BodySmall)`
  span {
    font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  }
`;

export const StorefrontDetailsStickyFooter = ({
  forceShow = false,
  onClick,
}: StorefrontDetailsStickyFooterProps) => {
  const { storefrontDetails, firstMove, isRapidRepeat, dismissFirstMove } =
    useStorefrontDetails<CouplesStorefrontDetails>();

  const { isBookedInquiryIncentiveAvailable } = useAccountIncentivizationContext();

  const showBookedInquiryIncentive = isBookedInquiryIncentiveAvailable();
  const stickFooterRef = useStickyScroll({
    showStickyButton: true,
    height: 70,
  });

  const { uuid: storefrontUuid } = storefrontDetails;

  const imageUuid = storefrontDetails.coverGallery
    ? storefrontDetails.coverGallery[0]?.uuid
    : undefined;

  const lastInquiry = useLastInquiryToStorefront(storefrontUuid);
  const { positiveToast } = useToast();

  const renderStickyContainer = (children: JSX.Element) =>
    forceShow ? (
      children
    ) : (
      <div ref={stickFooterRef} className="hide">
        {children}
      </div>
    );

  const actionNote = useMemo(() => {
    if (firstMove && !lastInquiry) {
      return <FirstMoveInvite firstMove={firstMove} />;
    }
    if (showBookedInquiryIncentive) {
      return (
        <BookedVendorPromoActivation>
          <Incentive>
            <TagWrapper>
              <TagMoneyIcon height={20} width={20} showTitle={false} color={COLORS3.SKY_100} />
            </TagWrapper>
            <IncentiveText>
              Book on Zola, get <span>65% off save the dates</span>. <BookedVendorsPromoTermsLink />
            </IncentiveText>
          </Incentive>
        </BookedVendorPromoActivation>
      );
    }
    if (isRapidRepeat && !lastInquiry) {
      return <RapidRepeatText />;
    }
  }, [showBookedInquiryIncentive, firstMove, isRapidRepeat, lastInquiry]);

  return (
    <>
      {renderStickyContainer(
        <div className={styles.storefrontStickyInfo}>
          {isClaimed(storefrontDetails) ? (
            <Fragment>
              {actionNote && <div className={styles.actionNote}>{actionNote}</div>}
              <div className={styles.actionButtons}>
                {!firstMove && (
                  <FavoriteButton storefrontUuid={storefrontUuid} storefrontCoverUuid={imageUuid} />
                )}
                {firstMove && (
                  <ButtonV3
                    className={styles.ignoreButton}
                    variant="secondary"
                    size="large"
                    onClick={() => {
                      onClick?.();
                      dismissFirstMove();
                      positiveToast({ headline: `Got it—we'll let them know` });
                    }}
                  >
                    Not interested
                  </ButtonV3>
                )}
                <InquiryButton
                  className={styles.inquiryButton}
                  section="STICKY"
                  position={99}
                  data-testid="inquiry-button"
                  firstMoveUuid={firstMove?.uuid}
                  onClick={onClick}
                  size="large"
                />
              </div>
            </Fragment>
          ) : (
            <UnclaimedModule
              className={styles.unclaimedModule}
              showFavoriteButton={false}
              onClick={onClick}
            />
          )}
        </div>
      )}
    </>
  );
};
