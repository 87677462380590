import { ButtonV2 } from '@zola/zola-ui/src/components/Button';

import cx from 'classnames';

import { useModal } from '~/contexts/ModalContext';
import { MappedOptionFacetView, StorefrontMarketView } from '~/types/responseTypes';
import { titleCase } from '~/util/textUtils';

import styles from './ServicesModal.module.less';

interface ServicesModalWrapperProps {
  contentList: MappedOptionFacetView[] | StorefrontMarketView[];
  vendorLocation: string;
  vendorName: string | null;
  children?: any;
  title: string;
}

/*
/ A component that wraps around home market modal and services modal
*/

const ServicesModalWrapper = ({
  contentList,
  vendorLocation,
  vendorName,
  children,
  title,
}: ServicesModalWrapperProps): JSX.Element | null => {
  const { hideModal } = useModal();
  const displayServiceList = () => (
    <ul>
      {contentList.map((option: any, index: number) => (
        <li key={index} className={cx(styles.label, 'mt-quaternary')}>
          {option.name ? option.name : option.market.label}
        </li>
      ))}
    </ul>
  );

  const [city, stateCode] = vendorLocation.split(',');
  const formattedLocation = `${titleCase(city)}, ${stateCode.toUpperCase()}`;

  return (
    <div className={styles.servicesModalContainer}>
      <div className={styles.servicesModalHeader}>
        <h2 className={styles.servicesModalHeaderText}>{vendorName}</h2>
        <p>Based in {formattedLocation}</p>
        <hr />
      </div>
      <div className={styles.servicesModalBody}>
        {contentList.length > 0 && (
          <div className={styles.servicesModalMarketsListContainer}>
            <div className={styles.servicesModalListTitle}>{title}</div>
            {displayServiceList()}
          </div>
        )}
        {children}
      </div>
      <div className={styles.servicesModalButtonContainer}>
        <ButtonV2 onClick={hideModal}>Dismiss</ButtonV2>
      </div>
    </div>
  );
};

export default ServicesModalWrapper;
