import { useState } from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';

import cx from 'classnames';

import useRowLength from './hooks/useRowLength';
import GridLayout from './layouts/GridLayout/GridLayout';
import './SeeAllDrawer.less';

type SeeAllDrawerProps = {
  className?: string;
  heading?: React.ReactNode;
  desktopColumnCount?: number;
  data: JSX.Element[];
  noContainer?: boolean;
  linkBelowContent?: boolean;
};

const SeeAllDrawer = (props: SeeAllDrawerProps) => {
  const {
    desktopColumnCount,
    className,
    heading,
    data,
    noContainer = false,
    linkBelowContent = false,
  } = props;
  const classes = cx('marketplace__see-all-drawer', className);

  let columnClasses = 'col-xs-12 col-md-6';
  let columnCountMd = 2;
  let columnCountSm = 2;
  const columnCountXs = 1;
  switch (desktopColumnCount) {
    case 4:
      columnClasses = 'col-xs-12 col-sm-4 col-md-3';
      columnCountMd = 4;
      columnCountSm = 3;
      break;
    case 3:
      columnClasses = 'col-xs-12 col-sm-6 col-md-4';
      columnCountMd = 3;
      break;
    default:
      break;
  }
  const rowLength = useRowLength(null, columnCountMd, columnCountSm, columnCountXs);

  const [showAll, setShowAll] = useState(false);
  const toggleSeeAll = () => {
    setShowAll(!showAll);
  };

  const showLink = rowLength && data.length > rowLength;

  const renderLink = () => {
    return (
      <LinkV2
        className="see-all-drawer__button"
        noTextTransform
        onClick={toggleSeeAll}
        role="button"
        sizes="small"
      >
        {showAll ? 'See less' : 'See all'}
        <ChevronDownIcon direction={showAll ? 'up' : 'down'} height={20} width={20} />
      </LinkV2>
    );
  };

  return (
    <div className={classes}>
      <div className={cx({ container: !noContainer })}>
        <div className="see-all-drawer__control">
          {heading}
          {showLink && !linkBelowContent && renderLink()}
        </div>
        <div className="mt-primary mb-secondary">
          <GridLayout
            columnCountMd={columnCountMd}
            columnCountSm={columnCountSm}
            columnCountXs={columnCountXs}
            rows={showAll ? null : 1}
            data={data}
            columnClasses={columnClasses}
          />
        </div>
        {showLink && linkBelowContent && renderLink()}
      </div>
    </div>
  );
};

export default SeeAllDrawer;
