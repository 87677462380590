import { Fragment } from 'react';

import PackageLineItem from './PackageLineItem';

import styles from './mobileServiceModal.module.less';

interface optionsProps {
  key: string | null;
  name: string | null;
}
export interface MobileServiceModalProps {
  hed: string;
  dek: string | null;
  note: string | null;
  includedInStartingPriceOptions: optionsProps[];
  extraFeeOptions?: optionsProps[];
  timeline: string | null;
  description: string | null;
  priceRangeVisible: boolean;
}

export const MobileServiceModal = (props: MobileServiceModalProps) => {
  const {
    hed,
    dek,
    note,
    includedInStartingPriceOptions,
    timeline,
    extraFeeOptions,
    description,
    priceRangeVisible,
  } = props;

  return (
    <div className={styles.modal}>
      <div className={styles.head}>
        <h3 className={styles.heading}>{hed}</h3>
        <div className={styles.pricingSection}>
          {priceRangeVisible && (
            <p className={styles.dek}>
              {dek}
              {dek && note ? '*' : null}
            </p>
          )}

          {note && priceRangeVisible ? <p className={styles.note}>{note}</p> : null}
          {timeline ? <p className={styles.timeline}>{timeline}</p> : null}
        </div>
        {description && (
          <Fragment>
            <div className={styles.title}>Description</div>
            <p className={styles.timeline}>{description}</p>
          </Fragment>
        )}
      </div>
      <div className={styles.servicesCtn}>
        <div>
          <div className={styles.title} data-testid="services-included-title">
            Services included{priceRangeVisible ? ' in starting price' : ''}
          </div>
          {includedInStartingPriceOptions?.map((option) => (
            <PackageLineItem key={option.key} option={option} />
          ))}
        </div>
        {extraFeeOptions && extraFeeOptions.length ? (
          <div>
            <div className={styles.title}>Services available for extra costs</div>
            {extraFeeOptions?.map((option: optionsProps) => (
              <PackageLineItem key={option.key} option={option} />
            ))}
          </div>
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  );
};

export default MobileServiceModal;
