import React, { Fragment } from 'react';

import { ShareIcon } from '@zola/zola-ui/src/components/SvgIcons';

import { ShareButton } from '~/components/common/ui/ShareButton/ShareButton';
import type { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { FavoriteCTAWrapper } from './favorites/FavoriteCTA';
import { ShareIconButton, FavoriteIconButton } from './ShareOrFavorite.styles';

export const FavoriteButton = ({
  storefrontUuid,
  storefrontCoverUuid,
}: {
  storefrontUuid: string;
  storefrontCoverUuid: string | undefined;
}) => {
  return (
    <FavoriteCTAWrapper storefrontUuid={storefrontUuid} storefrontCoverUuid={storefrontCoverUuid}>
      {({ isFavorited, onClick }) => (
        <FavoriteIconButton
          type="button"
          onClick={onClick}
          variant="secondary"
          title={isFavorited ? 'You favorited this vendor' : 'Favorite this vendor'}
          size="large"
          isFavorited={isFavorited}
          data-testid="FavoriteButton"
        />
      )}
    </FavoriteCTAWrapper>
  );
};
export const ShareOrFavorite = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { name, coverGallery, location, taxonomyKey, uuid, slug } = storefrontDetails;

  const storefrontCoverUuid = coverGallery?.[0]?.uuid;

  return (
    <Fragment>
      <ShareButton
        imageUuid={storefrontCoverUuid as string}
        location={location || ''}
        modalHed=""
        modalType="vendors"
        name={name}
        slug={slug}
        taxonomyKey={taxonomyKey}
      >
        {({ onClick }) => (
          <ShareIconButton
            type="button"
            size="large"
            onClick={onClick}
            variant="secondary"
            title="Share this vendor with somebody else"
          >
            <ShareIcon showTitle={false} height={24} width={24} />
          </ShareIconButton>
        )}
      </ShareButton>
      <FavoriteButton storefrontUuid={uuid} storefrontCoverUuid={storefrontCoverUuid} />
    </Fragment>
  );
};
