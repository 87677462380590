import { Fragment, useMemo } from 'react';

import useRowLength from '../../hooks/useRowLength';

type GridLayoutProps = {
  columnCountLg?: number;
  columnCountMd: number;
  columnCountSm: number;
  columnCountXs: number;
  data: JSX.Element[];
  rows: number | null;
  columnClasses: string;
};

const GridLayout = (props: GridLayoutProps) => {
  const {
    columnCountLg = null,
    columnCountMd,
    columnCountSm,
    columnCountXs,
    data,
    columnClasses,
    rows,
  } = props;
  const rowLength = useRowLength(columnCountLg, columnCountMd, columnCountSm, columnCountXs) || 4;

  const grid = useMemo(() => {
    const gridData = [];
    let rowData;
    for (let index = 0; index < data.length; index += rowLength) {
      rowData = data.slice(index, index + rowLength);
      gridData.push(
        <div className="row mt-secondary marketplace__flex-row" key={index}>
          {rowData.map((item, columnIndex) => (
            <div className={columnClasses} key={columnIndex}>
              {item}
            </div>
          ))}
        </div>
      );
      if (rows && gridData.length === rows) {
        break;
      }
    }
    return gridData;
  }, [columnClasses, data, rowLength, rows]);

  return <Fragment>{grid}</Fragment>;
};

export default GridLayout;
