import { Fragment } from 'react';

import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import { Tag } from '@zola/zola-ui/src/components/Tag';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

import { uploadTempPhoto } from '~/actions/imageUploadActions';
import * as NotificationActions from '~/actions/notificationActions';
import Upload from '~/assets/images/doodle/upload_doodle_shifted.svg';

import ActiveDragArea from './ActiveDragArea';
import './singleImageUpload.less';

const SingleImageUpload = (props) => {
  const {
    ctaCopy,
    instructionCopy,
    image,
    setImage,
    dispatch,
    className,
    sectionTitle,
    showEditButton,
    deleteFunc,
    index,
    showErrorMessage,
    uploadPill,
    pillText,
    style = {},
    ...rest
  } = props;

  const handleUploadTempPhoto = (img) => {
    dispatch(uploadTempPhoto(sectionTitle, img, index)).then((data) => {
      if (data.url) {
        setImage({
          imageUrl: data.url,
          height: data.height,
          width: data.width,
          s3Bucket: data.s3_bucket,
          s3Key: data.s3_key,
          contentType: data.content_type,
          fileName: img.name,
          newPhoto: true,
          index,
        });
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 1) {
        showErrorMessage('Please upload one photo at a time');
        return;
      }
      if (acceptedFiles.length > 0) {
        const img = acceptedFiles[0];
        handleUploadTempPhoto(img);
      }
    },
  });

  const buttonClick = (e, func) => {
    e.stopPropagation();
    func();
  };

  const emptyContent = (
    <Fragment>
      {uploadPill && (
        <Tag size="lg" type="secondary" className="upload-pill">
          {pillText}
        </Tag>
      )}
      <img
        src={Upload}
        alt=""
        className="single-image-upload__icon hidden-md hidden-sm hidden-xs"
      />
      {instructionCopy && (
        <p className="single-image-upload__instructions mt-tertiary">{instructionCopy}</p>
      )}
      {ctaCopy && (
        <ButtonV2 type="button" className="mt-secondary">
          {ctaCopy}
        </ButtonV2>
      )}
    </Fragment>
  );

  const filledContent = (
    <div className="single-image-upload__button-container">
      {showEditButton && (
        <button type="button" className="single-image-upload__button">
          <span className="zola-ui-icon-pencil" />
        </button>
      )}
      {typeof deleteFunc === 'function' && (
        <button
          onClick={(e) => buttonClick(e, deleteFunc)}
          type="button"
          className="single-image-upload__button"
        >
          <span className="zola-ui-icon-trash" />
        </button>
      )}
    </div>
  );

  const handleBackgroundImage = () => {
    if (image?.imageUrl) {
      return `url(${image?.newPhoto ? `https://` : ''}${image.imageUrl})`;
    }
    return null;
  };

  return (
    <div
      className={cx(
        className,
        'single-image-upload',
        image?.imageUrl ? 'single-image-upload__photo--preview' : 'single-image-upload__photo--edit'
      )}
      style={{ ...style, backgroundImage: handleBackgroundImage() }}
      {...rest}
    >
      <div {...getRootProps()} className="single-image-upload__upload-zone">
        <input {...getInputProps()} data-testid="dropzone-input" />
        <ActiveDragArea isDragActive={isDragActive} showHelperCopy={false} />
        {image?.imageUrl ? !isDragActive && filledContent : emptyContent}
      </div>
    </div>
  );
};

SingleImageUpload.propTypes = {
  image: PropTypes.shape({
    imageUrl: PropTypes.string,
  }),
  setImage: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  ctaCopy: PropTypes.string,
  instructionCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showEditButton: PropTypes.bool,
  deleteFunc: PropTypes.func,
  className: PropTypes.string,
  dispatch: PropTypes.func,
  index: PropTypes.number,
  showErrorMessage: PropTypes.func,
  uploadPill: PropTypes.bool,
  pillText: PropTypes.string,
};

SingleImageUpload.defaultProps = {
  image: null,
  showEditButton: false,
  index: 0,
  uploadPill: false,
  pillText: null,
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorMessage: (message) => dispatch(NotificationActions.error({ message })),
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(SingleImageUpload);
