import React, { Fragment } from 'react';

import { StorefrontSocialProofView } from '@zola/svc-marketplace-ts-types';
import { ClearCircleIcon } from '@zola/zola-ui/src/components/CircleIcon';
import { Tooltip } from '@zola/zola-ui/src/components/Tooltip';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import trendingUp from '~/assets/images/icons/clear/trending-up.png';
import userCheck from '~/assets/images/icons/clear/user-check.png';
import { useMouseEnter } from '~/hooks/useMouseEnter';

import styles from './socialProofComponent.module.less';

export type SocialProofComponentProps<T extends HTMLElement = HTMLElement> = {
  socialProof: StorefrontSocialProofView;
  trigger?: React.MutableRefObject<T | null>;
  condensed?: boolean;
};

const TIP_TEXT =
  'Couples on Zola who have either booked this vendor through Zola or booked them elsewhere and added them to their vendor team on Zola.';

export const SocialProofComponent = ({
  socialProof,
  trigger,
}: SocialProofComponentProps): JSX.Element => {
  const { couplesBooked, couplesConsidering } = socialProof;

  const { isEntered: showTooltip } = useMouseEnter({ trigger });

  const getSocialProofCopy = () => {
    if (couplesBooked > 1) {
      return (
        <span className={styles.tooltipIcon} role="tooltip" ref={trigger}>
          <div className={styles.socialProof}>
            <ClearCircleIcon image={userCheck} size={30} alt="" />
            <P.BodySmall className={styles.dek}>
              {couplesBooked} Zola couples have booked
            </P.BodySmall>
            {showTooltip && <Tooltip text={TIP_TEXT} />}
          </div>
        </span>
      );
    }
    if (couplesConsidering > 1) {
      return (
        <div className={styles.socialProof}>
          <ClearCircleIcon image={trendingUp} size={30} alt="" />
          <P.BodySmall className={styles.dek}>
            {couplesConsidering} Zola couples currently interested
          </P.BodySmall>
        </div>
      );
    }
    return <Fragment />;
  };

  return <div>{getSocialProofCopy()}</div>;
};
