import { Fragment, useRef } from 'react';

import { StorefrontDetailView } from '@zola/svc-marketplace-ts-types';
import { ErrorBoundary } from '@zola/zola-ui/src/components/ErrorBoundary';
import { Tooltip } from '@zola/zola-ui/src/components/Tooltip';

import { useMouseEnter } from '~/hooks/useMouseEnter';
import { AWARD_LOOKUP, filterAndSortSupportedAwards } from '~/meta/awards';
import Logger from '~/util/logger';

import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';

import styles from './Awards.module.less';

const Award = ({ badge }: { badge: StorefrontDetailView.BadgesEnum }): JSX.Element => {
  const trigger = useRef<HTMLDivElement>(null);
  const { isEntered: showTooltip } = useMouseEnter({ trigger });

  const { imgUrl, title, toolTip } = AWARD_LOOKUP[badge];
  return (
    <div className={styles.award} ref={trigger}>
      {showTooltip && <Tooltip text={toolTip} className={styles.toolTip} />}
      <img alt={title} src={imgUrl} />
    </div>
  );
};

const AwardContent = (): JSX.Element => {
  const { storefrontDetails } = useStorefrontDetails();

  if (storefrontDetails) {
    const supportedAwards = filterAndSortSupportedAwards(storefrontDetails.badges);

    if (supportedAwards.length > 0) {
      return (
        <div className={styles.awards}>
          <div className={styles.awardsList}>
            {supportedAwards.map((award) => {
              return <Award key={award} badge={award} />;
            })}
          </div>
        </div>
      );
    }
  }
  return <Fragment />;
};

export const Awards = (): JSX.Element => {
  return (
    <ErrorBoundary Logger={Logger}>
      <AwardContent />
    </ErrorBoundary>
  );
};

export default Awards;
