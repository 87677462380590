import ButtonV3 from '@zola/zola-ui/src/components/ButtonV3/ButtonV3';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import styled from '@emotion/styled';

import Heart from '~/components/common/favorites/assets/heart-default.svg';
import Favorited from '~/components/common/favorites/assets/heart-selected.svg';

const StyledButton = styled(ButtonV3)`
  height: 56px;
  width: 56px;

  &&& {
    border-radius: 50%;
    border: 1px solid ${COLORS3.BLACK_030};
  }
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }
`;

export const FavoriteIconButton = styled(StyledButton)<{ isFavorited: boolean }>`
  background-image: url(${Heart});
  ${({ isFavorited }) => isFavorited && `background-image: url(${Favorited});`}

  background-size: 24px 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &:hover {
    background-image: url(${Favorited});
    /* ${({ isFavorited }) => isFavorited && `background-image: url(${Heart});`} */
  }
`;

export const ShareIconButton = styled(StyledButton)`
  @media (max-width: 1080px) and (min-width: 992px) {
    display: none !important;
  }
`;
