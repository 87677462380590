import { useState, useEffect } from 'react';

const useRowLength = (
  columnCountLg: number | null,
  columnCountMd: number | null,
  columnCountSm: number | null,
  columnCountXs: number | null
) => {
  const [rowLength, setRowLength] = useState<number | null>(null);

  useEffect(() => {
    function handleScreenSizeChange() {
      if (!window) {
        return;
      }

      if (window.innerWidth >= 1200) {
        setRowLength(columnCountLg || columnCountMd || columnCountSm || columnCountXs);
      } else if (window.innerWidth >= 992) {
        setRowLength(columnCountMd || columnCountLg || columnCountSm || columnCountXs);
      } else if (window.innerWidth >= 768) {
        setRowLength(columnCountSm || columnCountMd || columnCountLg || columnCountXs);
      } else {
        setRowLength(columnCountXs || columnCountSm || columnCountMd || columnCountLg);
      }
    }
    if (!rowLength) {
      handleScreenSizeChange();
    }
    window.addEventListener('resize', handleScreenSizeChange);
    return () => {
      window.removeEventListener('resize', handleScreenSizeChange);
    };
  });

  return rowLength;
};

export default useRowLength;
