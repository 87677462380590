import { useRef } from 'react';

import InfoIcon from '@zola/zola-ui/src/components/SvgIcons/Info';
import { Tooltip } from '@zola/zola-ui/src/components/Tooltip';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import { useMouseEnter } from '~/hooks/useMouseEnter';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './unclaimedDisclaimerSection.module.less';

interface UnclaimedDisclaimerSectionProps {
  storefrontName?: string;
}

const UnclaimedDisclaimerSection = (props: UnclaimedDisclaimerSectionProps) => {
  const { storefrontName } = props;
  const { storefrontDetails } = useStorefrontDetails();
  const name = storefrontName || storefrontDetails?.name || 'This vendor';

  const trigger = useRef<HTMLElement>(null);
  const { isEntered: showTooltip } = useMouseEnter({ trigger });
  const tooltipText = `${name} has not claimed this profile and is not on Zola yet.`;

  return (
    <div className={styles.unclaimedDisclaimerSection}>
      <span className={styles.tooltipIcon} ref={trigger}>
        <P.BodyBase component="div" color="BLACK_050">
          Unclaimed
        </P.BodyBase>
        <InfoIcon width={16} height={16} />
        {showTooltip && <Tooltip text={tooltipText} />}
      </span>
    </div>
  );
};

export default UnclaimedDisclaimerSection;
