import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import useForceUpdateOnWindowResize from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import SeeAllDrawer from '~/components/common/SeeAllDrawer';
import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import { sortPackages } from '~/pages/vendors/Storefront/editPages/Packages/utils/packages';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import PackageCard from './PackageCard';

import styles from './packagesSection.module.less';

interface PackagesSectionProps {
  className?: string;
}

const PackagesSection = ({ className }: PackagesSectionProps): JSX.Element | null => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { name, packages, taxonomyKey } = storefrontDetails;
  const { isMobile } = useResponsiveDesign();
  const classes = cx(
    'marketplace__packages-section',
    'storefront__section',
    styles.packagesSection,
    className
  );

  useForceUpdateOnWindowResize();

  if (!packages || !packages.length) {
    return null;
  }

  const sortedPackages = sortPackages(packages).map((packageData) => (
    <PackageCard packageData={packageData} vendorType={taxonomyKey} key={packageData.id} />
  ));

  return (
    <div id="packages-section" className={classes}>
      <div className="container">
        <hr />
      </div>
      <SeeAllDrawer
        data={sortedPackages}
        desktopColumnCount={3}
        className={styles.seeAllDrawer}
        heading={
          <div className={styles.headerWrapper}>
            <H.Title2 presentation="h4" strong>
              {name} packages
            </H.Title2>
            <InquiryButton
              className={styles.desktopInquiryButton}
              section="PACKAGES"
              position={4}
              buttonText="Get a custom quote"
              entryPoint="PACKAGES"
            />
          </div>
        }
        linkBelowContent={isMobile}
      />
      <InquiryButton
        className={styles.mobileOnly}
        section="PACKAGES"
        position={4}
        buttonText="Get a custom quote"
        entryPoint="PACKAGES"
      />
    </div>
  );
};

export default PackagesSection;
