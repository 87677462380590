import { Fragment } from 'react';

import Upload from '~/assets/images/doodle/upload_doodle_shifted.svg';
import './activeDragArea.less';

type ActiveDragAreaProps = {
  isDragActive: boolean;
  showHelperCopy?: boolean;
};

const ActiveDragArea = (props: ActiveDragAreaProps) => {
  const { isDragActive, showHelperCopy = true } = props;

  if (!isDragActive) {
    return null;
  }

  return (
    <div className="active-drag-area__overlay">
      <img src={Upload} alt="" className="active-drag-area__icon" />
      <div className="overlay__cta">Drop files anywhere to upload</div>
      {showHelperCopy && (
        <Fragment>
          <div>Accepted: .jpeg, .png</div>
          <div>Maximum file size: 10MB</div>
        </Fragment>
      )}
    </div>
  );
};

export default ActiveDragArea;
