import { useEffect } from 'react';

import { getLocalStorage, deleteLocalStorage } from '@zola-helpers/client/dist/es/util/storage';

import { showFavoritedVendorToast } from '~/components/common/favorites/showFavoritedVendorToast';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getFavoriteStorefrontEntity } from '~/selectors/favoritesSelectors';
import { TrackingProductLocation } from '~/util/trackingHelper';

export const VENDOR_FAVORITE_ONBOARD_STOREFRONT_UUID_KEY =
  'vendorFavoriteSuccessfulOnboardStorefrontUuid';
export const VENDOR_FAVORITE_ONBOARD_STOREFRONT_IMAGE_KEY =
  'vendorFavoriteSuccessfulOnboardStorefrontImageId';

export type UseOnboardedViaFavoritedStorefrontProps = {
  showCustomInitialToasts: boolean;
  location: TrackingProductLocation;
  /** defaults to true, should be false if the value will be needed elsewhere, ie to scroll to the vendor card */
  shouldDeleteStorefrontUuidFromLocalStorage?: boolean;
};
export const useOnboardedViaFavoritedStorefront = (
  props: UseOnboardedViaFavoritedStorefrontProps
) => {
  const {
    showCustomInitialToasts,
    location,
    shouldDeleteStorefrontUuidFromLocalStorage = true,
  } = props;

  const dispatch = useAppDispatch();
  const vendorFavoriteCount = useAppSelector(getFavoriteStorefrontEntity)?.allIds?.length || 0;

  useEffect(() => {
    const favoritedStorefrontUuid = getLocalStorage(VENDOR_FAVORITE_ONBOARD_STOREFRONT_UUID_KEY);
    const favoritedStorefrontImageId = getLocalStorage(
      VENDOR_FAVORITE_ONBOARD_STOREFRONT_IMAGE_KEY
    );
    if (favoritedStorefrontUuid) {
      dispatch(
        showFavoritedVendorToast({
          vendorFavoriteCount,
          uuid: favoritedStorefrontUuid,
          vendorPhotoUuid: favoritedStorefrontImageId,
          showCustomInitialToasts,
          location,
        })
      );

      deleteLocalStorage(VENDOR_FAVORITE_ONBOARD_STOREFRONT_IMAGE_KEY);

      if (shouldDeleteStorefrontUuidFromLocalStorage) {
        deleteLocalStorage(VENDOR_FAVORITE_ONBOARD_STOREFRONT_UUID_KEY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
