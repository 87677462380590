import type { SearchLocationView } from '@zola/svc-marketplace-ts-types';

import { isServerApiService } from '~/libs/universal/util/apiServiceType';
import type { ClientApiServiceType } from '~/util/apiService';
import { isSearchLocation } from '~/util/searchUtils';

import { ApiServiceTarget, ServerApiServiceType } from '../server/util/apiService';

export const getSearchLocationForSlug = (
  locationSlug: string,
  apiService: ServerApiServiceType | ClientApiServiceType
) => {
  const slug = encodeURIComponent(locationSlug);
  const request = isServerApiService(apiService)
    ? apiService.get<SearchLocationView>(
        ApiServiceTarget.SVC_MARKETPLACE,
        `/v1/search-location/slug/${slug}`
      )
    : apiService.get<SearchLocationView>(`/web-marketplace-api/v1/search-location/slug/${slug}`);
  return request
    .then((location) => {
      if (isSearchLocation(location)) {
        return location;
      }
      return null;
    })
    .catch(() => null);
};
