import { Fragment, useEffect, useMemo, useState } from 'react';

import { getVendorType } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { getVendorWordPlural } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import {
  StorefrontCardView,
  V4StorefrontSearchRequest,
  StorefrontSearchView,
} from '@zola/svc-marketplace-ts-types';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import cx from 'classnames';

import { storefrontCardViewToVendorCardVendor } from '~/components/common/cards/util/helper';
import VendorCard from '~/components/common/cards/VendorCard/VendorCard';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

import { VendorSearchRequestType } from '../../explore/components/SearchResults/types/types';
import createCanonicalUrl from '../../explore/util/canonicalUrl';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './unclaimedSimilarSection.module.less';

interface UnclaimedSimilarSectionProps {
  presentation?: 'default' | 'modal';
}

const UnclaimedSimilarSection = (props: UnclaimedSimilarSectionProps) => {
  const { presentation = 'default' } = props;
  const isDefaultPresentation = presentation === 'default';
  const isModalPresentation = presentation === 'modal';
  const { storefrontDetails } = useStorefrontDetails();
  const { taxonomyKey, location, address, markets } = storefrontDetails || {};
  const category = getVendorWordPlural(taxonomyKey);
  const [searchResults, setSearchResults] = useState<StorefrontCardView[]>([]);

  const searchUrl = useMemo(() => {
    if (!taxonomyKey || !address) {
      return undefined;
    }

    return createCanonicalUrl({
      selectedLocation: address.possibleCitySlug
        ? {
            slug: address.possibleCitySlug,
          }
        : undefined,
      selectedTaxonomy: {
        key: taxonomyKey,
      },
    });
  }, [taxonomyKey, address]);

  const searchRequest: VendorSearchRequestType | null = useMemo(() => {
    if (!taxonomyKey || !address || !markets) {
      return null;
    }

    return {
      offset: 0,
      limit: isModalPresentation ? 4 : 3,
      vendorType: getVendorType(taxonomyKey) as V4StorefrontSearchRequest.VendorTypeEnum,
      city: address.city,
      stateProvince: address.stateProvince,
      vendorMarketId: markets.homeMarkets[0].market.id,
      locationMethod: 'MARKET',
      allowUnclaimedListings: false,
      price: null,
      capacity: null,
      metroTypes: null,
      pointRadius: null,
      facetGroups: null,
      targetDates: null,
      sortBy: null,
      sortOrder: null,
      searchName: 'DEFAULT',
      boostFeaturedStorefronts: null,
      awardGroups: null,
      priceTier: null,
      seoOptimized: false,
      excludeBookedStorefronts: null,
      excludeInquiredStorefronts: null,
    };
  }, [taxonomyKey, address, markets, isModalPresentation]);

  useEffect(() => {
    if (searchRequest) {
      ApiService.post<StorefrontSearchView, VendorSearchRequestType>(
        '/web-marketplace-api/v4/storefront/search',
        searchRequest
      )
        .then((response) => {
          setSearchResults(response.entities || []);
        })
        .catch(StandardLogFn);
    }
  }, [searchRequest]);

  if (!storefrontDetails || !searchUrl || searchResults.length === 0) {
    return <Fragment />;
  }

  return (
    <div className={cx(styles.unclaimedSimilarSection, { [styles.modal]: isModalPresentation })}>
      <hr className={styles.hr} />
      <div className={styles.heading}>
        <H.Title2 presentation="h4">
          More {category} you&rsquo;ll love near <span className={styles.location}>{location}</span>
        </H.Title2>
        {isDefaultPresentation && <LinkV2 href={searchUrl}>View all</LinkV2>}
        {isModalPresentation && (
          <P.BodyBase>While you&rsquo;re here, check out these other great options.</P.BodyBase>
        )}
      </div>
      <div className={styles.searchResults}>
        {searchResults.map((searchResult, index) => (
          <div className={styles.searchCard} key={index}>
            <VendorCard
              queriedLocation={location ?? undefined}
              vendor={storefrontCardViewToVendorCardVendor(searchResult)}
              position={index + 1}
              trackClick={false}
            />
          </div>
        ))}
      </div>
      {isModalPresentation && (
        <ButtonV3 component="a" variant="secondary" size="large" fullWidth href={searchUrl}>
          Explore all {category}
        </ButtonV3>
      )}
    </div>
  );
};

export default UnclaimedSimilarSection;
