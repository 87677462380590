import React, { useMemo } from 'react';

import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';
import { ViewMore } from '@zola/zola-ui/src/components/ViewMore/ViewMore';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import T from '@zola/zola-ui/src/typography/Text';

import cx from 'classnames';

import CachedImage from '~/components/common/images/CachedImage';

import { getBestLeadReasonTextForFirstMove } from './getLeadReasonText';

import styles from './firstMoveModule.module.less';

interface FirstMoveModuleProps {
  firstMove: StorefrontFirstMoveView;
  imageId: string;
  isLdpRedesign?: boolean;
}

export const FirstMoveModule = ({ firstMove, imageId, isLdpRedesign }: FirstMoveModuleProps) => {
  const { vendorName, leadSources, note } = firstMove;

  const info = useMemo(() => {
    if (note) {
      return <ViewMore lines={2}>{note}</ViewMore>;
    }

    return getBestLeadReasonTextForFirstMove(leadSources);
  }, [leadSources, note]);

  return (
    <div className={cx(styles.firstMoveModule, { [styles.isLdpRedesign]: isLdpRedesign })}>
      <div className={styles.image}>
        <CachedImage uuid={imageId} width={40} height={40} />
      </div>
      <div className={styles.content}>
        <P.BodyBase>
          <T.Strong>{vendorName} said hello to you!</T.Strong>
        </P.BodyBase>
        {info}
      </div>
    </div>
  );
};

export default FirstMoveModule;
