import {
  VENUES_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  BANDS_DJS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  PLANNERS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { pluralize } from '@zola-helpers/client/dist/es/transformers';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { Hearts as HeartsIcon } from '@zola/zola-ui/src/components/SvgIcons/Hearts';
import { CircleDollarIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CircleDollar';
import { GiftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Gift';
import { PlaneIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Plane';
import { UsersIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Users';
import { VenueIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Venue';
import { WeddingRingsIcon } from '@zola/zola-ui/src/components/SvgIconsV3/WeddingRings';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import _flatMap from 'lodash/flatMap';

import {
  VENUE_SERVICE_LEVEL_ALL_INCLUSIVE,
  VENUE_SERVICE_LEVEL_LIMITED_SERVICES,
  VENUE_SERVICE_LEVEL_RAW_SPACE,
} from '~/types/facets';
import {
  AnyVendorDetails,
  PhotographerDetails,
  MusicianDetails,
  VideographerDetails,
  BakerDetails,
  FloristDetails,
  CatererDetails,
  VenueDetails,
  BeauticianDetails,
  InquiryServicesFacet,
} from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { centsToDollars } from '~/util/priceConversion';
import { getVendorDetails, isClaimed } from '~/util/storefrontUtils';
import { formatAsCurrency } from '~/util/textUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import PricingForPlanners from '../planners/components/Pricing';

import styles from './pricing.module.less';

export enum PricingSection {
  CAPACITY = 'capacity',
  PACKAGES = 'packages',
  ELOPEMENT = 'elopment',
  FULL_WEDDING = 'full-wedding',
  RECEPTION = 'reception',
  CEREMONY = 'ceremony',
  CATERER_PRICE = 'caterer-price',
  CATERER_MINIMUM_SPEND = 'caterer-minimum-spend',
  BAR_SERVICES_PRICE = 'bar-services-price',
  BAR_SERVICES_MINIMUM_SPEND = 'bar-services-minimum-spend',
  BAKER_PRICE = 'baker-price',
  VENUE_FULL_WEDDING = 'venue-full-wedding',
  VENUE_MINIMUM_SPEND = 'venue-minimum-spend',
  BEAUTICIAN_PRICE = 'beautician-price',
  BEAUTICIAN_MINIMUM_SPEND = 'beautician-minimum-spend',
  FLORIST_ARRANGEMENT_PRICING = 'florist-arrangement',
  FLORIST_MINIMUM_SPEND = 'florist-minimum-spend',
  VENUE_SERVICE_LEVEL = 'venue-service-level',
}

interface priceSection {
  key: PricingSection;
  title: string | null;
  description?: JSX.Element | string;
  link?: string;
  linkText?: string;
  icon?: React.ReactNode;
  ariaLabel?: string;
}

type sectionsList = {
  [key in SearchableVendorTaxonomyKey]?: priceSection[];
};

const sections: sectionsList = {
  [VENUES_TAXONOMY_KEY]: [
    {
      key: PricingSection.VENUE_SERVICE_LEVEL,
      title: null,
      icon: <VenueIcon width={22} height={22} />,
    },
    {
      key: PricingSection.VENUE_FULL_WEDDING,
      title: 'Full wedding (ceremony and reception) pricing',
      icon: <HeartsIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
    {
      key: PricingSection.VENUE_MINIMUM_SPEND,
      title: 'Catering services pricing',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.CAPACITY,
      title: 'Guest capacity',
      icon: <UsersIcon width={22} height={22} />,
    },
  ],
  [PHOTOGRAPHERS_TAXONOMY_KEY]: [
    {
      key: PricingSection.FULL_WEDDING,
      title: 'Full wedding (ceremony and reception) pricing',
      icon: <HeartsIcon width={22} height={22} />,
    },
    {
      key: PricingSection.ELOPEMENT,
      title: 'Elopement pricing',
      icon: <PlaneIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [VIDEOGRAPHERS_TAXONOMY_KEY]: [
    {
      key: PricingSection.FULL_WEDDING,
      title: 'Full wedding (ceremony and reception) pricing',
      icon: <HeartsIcon width={22} height={22} />,
    },
    {
      key: PricingSection.ELOPEMENT,
      title: 'Elopement pricing',
      icon: <PlaneIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [CATERING_TAXONOMY_KEY]: [
    {
      key: PricingSection.CATERER_PRICE,
      title: 'Catering services pricing',
      icon: <CircleDollarIcon width={22} height={22} />,
      // link: '#menus-section', // we do not have a menus section yet
    },
    {
      key: PricingSection.CATERER_MINIMUM_SPEND,
      title: 'Minimum spend',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [BAR_SERVICES_TAXONOMY_KEY]: [
    {
      key: PricingSection.BAR_SERVICES_PRICE,
      title: 'Beverage services pricing',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.BAR_SERVICES_MINIMUM_SPEND,
      title: 'Minimum spend',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [CAKES_DESSERTS_TAXONOMY_KEY]: [
    {
      key: PricingSection.BAKER_PRICE,
      title: 'Price per serving',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [BANDS_DJS_TAXONOMY_KEY]: [
    {
      key: PricingSection.CEREMONY,
      title: 'Ceremony pricing',
      icon: <WeddingRingsIcon width={22} height={22} />,
    },
    {
      key: PricingSection.RECEPTION,
      title: 'Reception pricing',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [HAIR_MAKEUP_TAXONOMY_KEY]: [
    {
      key: PricingSection.BEAUTICIAN_PRICE,
      title: 'Beauty services',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.BEAUTICIAN_MINIMUM_SPEND,
      title: 'Minimum spend',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [FLORISTS_TAXONOMY_KEY]: [
    {
      key: PricingSection.FLORIST_ARRANGEMENT_PRICING,
      title: 'Price per arrangement',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.FLORIST_MINIMUM_SPEND,
      title: 'Minimum spend',
      icon: <CircleDollarIcon width={22} height={22} />,
    },
    {
      key: PricingSection.PACKAGES,
      title: 'Packages',
      link: 'packages-section',
      icon: <GiftIcon width={22} height={22} />,
    },
  ],
  [PLANNERS_TAXONOMY_KEY]: [],
};

export const priceToString = (price: number): string => formatAsCurrency(centsToDollars(price));

/**
 *
 * Checks if the given price is a valid number
 *
 * this function makes use of TypeScript's type predicates (user-defined type guard)
 * type predicates are always attached to a function
 * that takes a single argument and returns a boolean
 *
 * it is executed at run time just like all other type guards
 *
 * @param {number | null} price
 * @returns {boolean} returns true if price is greater than 0
 */
export const isValidPrice = (price: number | null): price is number => price !== null && price > 0;

export const getSectionTitle = (
  section: priceSection,
  venueServiceLevel?: InquiryServicesFacet
): string | null => {
  if (section.key === PricingSection.VENUE_SERVICE_LEVEL) {
    return `${venueServiceLevel?.name} venue`;
  }
  return section.title;
};

const scrollIntoView = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
const scrollToServices = () => {
  scrollIntoView('services');
};

const ViewServicesLink = () => {
  return (
    <LinkV2
      role="button"
      onClick={() => {
        scrollToServices();
      }}
      onKeyUp={(event) => {
        if (event.code === 'Space' || event.code === 'Enter') {
          scrollToServices();
        }
      }}
      noTextTransform
      sizes="small"
    >
      See services included
    </LinkV2>
  );
};

export const getSectionDescription = (
  section: PricingSection,
  data: CouplesStorefrontDetails,
  details?: AnyVendorDetails,
  venueServiceLevel?: InquiryServicesFacet
): JSX.Element | string | null => {
  switch (section) {
    /** Descriptions with a single pricing value */
    case PricingSection.PACKAGES: {
      if (data.packages && data.packages.length > 0) {
        /**
         * If vendor wants to hide pricing
         * return an empty string, the section will still display
         * Do not return null, the section will not display
         */
        if (data.priceRangeVisible === false) return '';

        const priceCents: number = data.packages.reduce((min, vendorPackage) => {
          const { offPeakStartPriceCents, peakStartPriceCents } = vendorPackage;

          if (isValidPrice(offPeakStartPriceCents) && offPeakStartPriceCents < min) {
            return offPeakStartPriceCents;
          }

          if (isValidPrice(peakStartPriceCents) && peakStartPriceCents < min) {
            return peakStartPriceCents;
          }

          return min;
        }, data.packages[0].offPeakStartPriceCents || data.packages[0].peakStartPriceCents || 0);

        /** if price is not valid, return empty string. returning null would hide the entire section */
        return isValidPrice(priceCents) ? `Prices start at ${priceToString(priceCents)}` : '';
      }
      return null;
    }

    case PricingSection.FULL_WEDDING: {
      const { fullStartPriceCents } = data;
      return isValidPrice(fullStartPriceCents)
        ? `Prices start at ${priceToString(fullStartPriceCents)}`
        : null;
    }

    case PricingSection.FLORIST_MINIMUM_SPEND:
    case PricingSection.CATERER_MINIMUM_SPEND:
    case PricingSection.BAR_SERVICES_MINIMUM_SPEND:
    case PricingSection.BEAUTICIAN_MINIMUM_SPEND: {
      const { startPriceCents } = details as FloristDetails;
      return isValidPrice(startPriceCents) ? `${priceToString(startPriceCents)} total` : null;
    }

    case PricingSection.BEAUTICIAN_PRICE: {
      const { startPriceCentsPerPerson } = details as BeauticianDetails;
      return isValidPrice(startPriceCentsPerPerson)
        ? `Prices start at ${priceToString(startPriceCentsPerPerson)} per person`
        : null;
    }

    case PricingSection.ELOPEMENT: {
      const { elopementStartPriceCents } = details as PhotographerDetails | VideographerDetails;
      return isValidPrice(elopementStartPriceCents)
        ? `Prices start at ${priceToString(elopementStartPriceCents)}`
        : null;
    }

    case PricingSection.RECEPTION: {
      const { receptionStartPriceCents } = details as MusicianDetails;
      return isValidPrice(receptionStartPriceCents)
        ? `Prices start at ${priceToString(receptionStartPriceCents)}`
        : null;
    }

    case PricingSection.CEREMONY: {
      const { ceremonyStartPriceCents } = details as MusicianDetails;
      return isValidPrice(ceremonyStartPriceCents)
        ? `Prices start at ${priceToString(ceremonyStartPriceCents)}`
        : null;
    }

    case PricingSection.CAPACITY: {
      const { maxCapacity } = details as VenueDetails;
      return maxCapacity && maxCapacity > 0 ? `Up to ${maxCapacity} seated guests` : null;
    }

    /** Descriptions with a multiple pricing value */

    case PricingSection.VENUE_FULL_WEDDING: {
      const { offPeakStartPrice, peakStartPrice } = data;
      if (!isValidPrice(offPeakStartPrice) || !isValidPrice(peakStartPrice)) {
        return null;
      }

      const peakPrice = formatAsCurrency(peakStartPrice);
      const offPeakPrice = formatAsCurrency(offPeakStartPrice);

      // if both prices are the same, use alternate copy
      if (offPeakStartPrice === peakStartPrice) return `Starting at ${offPeakPrice}`;

      return `Starting at ${offPeakPrice} for off-peak dates, and ${peakPrice} for peak dates`;
    }

    case PricingSection.VENUE_MINIMUM_SPEND: {
      if (!details) return null;

      const { foodAndBeverageStartPriceCentsPerPerson, foodBeverageStartPriceCents } =
        details as VenueDetails;
      if (
        isValidPrice(foodAndBeverageStartPriceCentsPerPerson) &&
        isValidPrice(foodBeverageStartPriceCents)
      ) {
        return `${priceToString(
          foodAndBeverageStartPriceCentsPerPerson
        )} per person for off-peak dates, with a minimum total spend of ${priceToString(
          foodBeverageStartPriceCents
        )}`;
      }
      return null;
    }

    case PricingSection.BAKER_PRICE: {
      const { startPriceCentsPerPerson, minimumSpendCents } = details as BakerDetails;
      const prices: string[] = [];
      if (isValidPrice(startPriceCentsPerPerson)) {
        prices.push(`Starts at ${priceToString(startPriceCentsPerPerson)} per person.`);
      }

      if (isValidPrice(minimumSpendCents)) {
        prices.push(`The minimum spend is ${priceToString(minimumSpendCents)} total.`);
      }

      return prices.length > 0 ? prices.join(' ') : null;
    }

    case PricingSection.FLORIST_ARRANGEMENT_PRICING: {
      const { lowCenterpieceStartPriceCents, bridalBouquetStartPriceCents } =
        details as FloristDetails;
      const prices: string[] = [];
      if (isValidPrice(bridalBouquetStartPriceCents)) {
        prices.push(`Bouquets start at ${priceToString(bridalBouquetStartPriceCents)}`);
      }

      if (isValidPrice(lowCenterpieceStartPriceCents)) {
        const centerpieceText = bridalBouquetStartPriceCents
          ? 'centerpieces'
          : 'Centerpieces start';
        prices.push(`${centerpieceText} at ${priceToString(lowCenterpieceStartPriceCents)}`);
      }

      return prices.length > 0 ? prices.join(', and ') : null;
    }

    case PricingSection.CATERER_PRICE: {
      const { alcoholPerPersonStartPriceCents, startPriceCentsPerPerson } =
        details as CatererDetails;

      let description = null;
      let food = null;
      let alcohol = null;
      if (isValidPrice(startPriceCentsPerPerson)) {
        food = priceToString(startPriceCentsPerPerson);
      }
      if (isValidPrice(alcoholPerPersonStartPriceCents)) {
        alcohol = priceToString(alcoholPerPersonStartPriceCents);
      }
      if (food && alcohol) {
        description = `Food starts at ${food} per person and bar services at ${alcohol} per person.`;
      } else if (food && !alcohol) {
        description = `Food starts at ${food} per person.`;
      } else if (alcohol && !food) {
        description = `Bar services start at ${alcohol} per person.`;
      }

      return description;

      return null;
    }

    case PricingSection.BAR_SERVICES_PRICE: {
      const { alcoholPerPersonStartPriceCents, startPriceCentsPerPerson } =
        details as CatererDetails;
      let description = null;
      let beverage = null;
      let alcohol = null;
      if (isValidPrice(startPriceCentsPerPerson)) {
        beverage = priceToString(startPriceCentsPerPerson);
      }
      if (isValidPrice(alcoholPerPersonStartPriceCents)) {
        alcohol = priceToString(alcoholPerPersonStartPriceCents);
      }
      if (beverage && alcohol) {
        description = `Beverages start at ${beverage} per person and bar services at ${alcohol} per person.`;
      } else if (beverage && !alcohol) {
        description = `Beverage services start at ${beverage} per person.`;
      } else if (alcohol && !beverage) {
        description = `Bar services start at ${alcohol} per person.`;
      }

      return description;
    }

    case PricingSection.VENUE_SERVICE_LEVEL: {
      if (venueServiceLevel) {
        if (venueServiceLevel.key === VENUE_SERVICE_LEVEL_ALL_INCLUSIVE) {
          return (
            <>
              <P.BodyBase className="inlineText">
                The venue takes care of it all - food and beverage, rentals, the works!{' '}
              </P.BodyBase>
              <ViewServicesLink />
            </>
          );
        }
        if (venueServiceLevel.key === VENUE_SERVICE_LEVEL_LIMITED_SERVICES) {
          return (
            <>
              <P.BodyBase className="inlineText">
                The venue will provide the space, plus a few extras.{' '}
              </P.BodyBase>
              <ViewServicesLink />
            </>
          );
        }
        if (venueServiceLevel.key === VENUE_SERVICE_LEVEL_RAW_SPACE) {
          return 'The venue will provide just the space. You’ll bring in your own caterer and vendors.';
        }
      }
      return null;
    }

    default:
      return null;
  }
};

export const getVendorSections = (
  data: CouplesStorefrontDetails,
  details: AnyVendorDetails,
  venueServiceLevel?: InquiryServicesFacet
): priceSection[] => {
  const { priceRangeVisible } = data;
  const vendorSections = sections[data.taxonomyKey];
  if (!vendorSections) return [];

  /**
   * Special cases: vendors that elected not to show pricing
   *
   * (1) packages: we still want to display packages section without pricing
   * (2) capacity: always display capacity section
   */
  const nonPricingSections = [PricingSection.PACKAGES, PricingSection.CAPACITY];

  return _flatMap(vendorSections, (section) => {
    /** Checks for special cases */
    if (!priceRangeVisible && !nonPricingSections.includes(section.key)) return [];

    if (section.description) return [section];
    const description = getSectionDescription(section.key, data, details, venueServiceLevel);
    if (description === null) return []; // when description is null, remove this section

    const title = getSectionTitle(section, venueServiceLevel);

    let { linkText } = section;
    if (section.key === PricingSection.PACKAGES && data.packages && data.packages.length > 0) {
      linkText = `View ${pluralize('package', 'packages', data.packages.length, true)}`;
    }

    return [
      {
        ...section,
        title,
        description,
        linkText,
      },
    ];
  });
};

interface HasPricingProps {
  data: CouplesStorefrontDetails;
  details: AnyVendorDetails;
}

export const hasPricing = ({ data, details }: HasPricingProps) => {
  if (!data || !isClaimed(data) || !(data.taxonomyKey in sections)) return false;

  if (data && data.taxonomyKey === PLANNERS_TAXONOMY_KEY) {
    return true;
  }

  const priceSections = getVendorSections(data, details);
  return !!priceSections?.length;
};

interface PricingProps {
  venueServiceLevel?: InquiryServicesFacet;
}

const Pricing = ({ venueServiceLevel }: PricingProps): JSX.Element | null => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const vendorDetails = getVendorDetails(storefrontDetails) as AnyVendorDetails;
  if (!hasPricing({ data: storefrontDetails, details: vendorDetails })) {
    return null;
  }

  /* wedding-planners get a different treatment here */
  if (storefrontDetails && storefrontDetails.taxonomyKey === PLANNERS_TAXONOMY_KEY) {
    return <PricingForPlanners />;
  }

  const priceSections = getVendorSections(storefrontDetails, vendorDetails, venueServiceLevel);

  return (
    <div className={styles.pricing}>
      {priceSections.map((section) => (
        <div key={section.key} className={styles.section}>
          <div className={styles.title}>
            {section.icon && (
              <span className={styles.icon} role="img" aria-label={section.ariaLabel || ''}>
                {section.icon}
              </span>
            )}
            <H.TitleMedium2 className="inlineText" presentation="h5">
              {section.title}
            </H.TitleMedium2>
          </div>
          {typeof section.description === 'string' && section.description.length > 0 ? (
            <P.BodyBase className="inlineText">{section.description}</P.BodyBase>
          ) : (
            section.description
          )}
          {section.link && section.linkText && (
            <div className={styles.link}>
              <LinkV2
                arrow
                noTextTransform
                onClick={() => scrollIntoView(section.link as string)}
                role="button"
              >
                {section.linkText}
              </LinkV2>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Pricing;
