import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

import styled from '@emotion/styled';

export const ListingMessageContainer = styled.div`
  padding: ${SPACING.S16};
  border-radius: ${SPACING.S16};
  border: 1px solid ${COLORS3.BLACK_015};
  display: flex;
  gap: ${SPACING.S8};
  flex-direction: column;
`;

export const MessageMainContent = styled.div`
  display: flex;
  gap: ${SPACING.S8};
`;
