import { ServerApiServiceType } from '~/libs/server/util/apiService';
import { ClientApiServiceType } from '~/util/apiService';

export function isServerApiService(
  apiService: ServerApiServiceType | ClientApiServiceType
): apiService is ServerApiServiceType {
  return apiService.type === 'server';
}

export function isClientApiService(
  apiService: ServerApiServiceType | ClientApiServiceType
): apiService is ClientApiServiceType {
  return apiService.type === 'client';
}
