import { useMemo, useRef } from 'react';

import { MarketplaceInquiryFlowEntryPoint } from '@zola-helpers/client/dist/es/tracking/flow/flowEvents';
import ButtonV3, { ButtonV3Props } from '@zola/zola-ui/src/components/ButtonV3/ButtonV3';

import { useUserContext } from '~/contexts/UserContext';
import { useInquiryModal } from '~/pages/couples/inquiry-flow/showInquiryModal';
import { InquiryFormValues } from '~/pages/couples/inquiry-flow/types';
import { useStorefrontDetails } from '~/pages/couples/storefronts/contexts/StorefrontDetailsContext';
import { useAppDispatch } from '~/reducers';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { isPreview } from '~/util/storefrontUtils';
import { TrackingProductLocation } from '~/util/trackingHelper';

export interface InquiryButtonProps {
  location?: TrackingProductLocation;
  position: number;
  section: string;
  firstMoveUuid?: string;
  partialValues?: Partial<InquiryFormValues>;
  buttonText?: string;
  onClick?: () => void;
  entryPoint?: MarketplaceInquiryFlowEntryPoint;
}

export const getInquiryCta = (buttonText = 'Get a quote') => buttonText;

type ComponentProps = InquiryButtonProps & Omit<ButtonV3Props, 'onClick'>;

/**
 * Button to start an inquiry with a storefront for a page displaying a _single_
 * storefront.  THis button is assuming that the storefront details are loaded
 * into redux and accessible with the useStorefrontDetails hook.
 *
 * This button is NOT APPROPRIATE for a page with multiple storefronts, like the
 * search results page, because none of the storefronts are loaded into redux.
 *
 * Instead, use the modal components directly, or the InquiryButton in
 * StorefrontQuickView.
 *
 * @param param0
 * @returns
 */
const InquiryButton = ({
  location = TrackingProductLocation.STOREFRONT_DETAIL_PAGE,
  position,
  section,
  firstMoveUuid,
  partialValues,
  buttonText,
  onClick,
  entryPoint,
  ...buttonProps
}: ComponentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { trackAndShowInquiry } = useInquiryModal();
  const userContext = useUserContext();
  const { storefrontDetails, lastInquiry } = useStorefrontDetails<CouplesStorefrontDetails>();

  const viewMessagesText = 'View Messages';

  const buttonRef = useRef();

  const inquiryUrl = useMemo(() => {
    if (lastInquiry) {
      return `/wedding-vendors/inquiries/${lastInquiry?.uuid}`;
    }
    return null;
  }, [lastInquiry]);

  const ctaText = useMemo(() => {
    if (inquiryUrl) {
      return viewMessagesText;
    }
    // Function so we can easily experiment by adding a user Context
    return getInquiryCta(buttonText);
  }, [buttonText, inquiryUrl]);

  const startInquiry = () => {
    trackAndShowInquiry({
      ctaText,
      dispatch,
      firstMoveUuid,
      location,
      position,
      section,
      storefront: storefrontDetails,
      partialValues,
      entryPoint,
    });
  };

  const goToMessagesOrStartInquiry = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    if (onClick) onClick();

    if (inquiryUrl) {
      window.location.assign(inquiryUrl);

      return;
    }
    startInquiry();
  };

  return (
    <ButtonV3
      {...buttonProps}
      onClick={goToMessagesOrStartInquiry}
      style={{ visibility: userContext ? 'visible' : 'hidden' }}
      innerRef={buttonRef}
    >
      {ctaText}
    </ButtonV3>
  );
};

const InquiryButtonWrapper = (props: ComponentProps) => {
  if (isPreview()) {
    return null;
  }

  return <InquiryButton {...props} />;
};

export default InquiryButtonWrapper;
