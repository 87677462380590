import Mixer from '@zola/zola-ui/src/assets/images/icons/mixer.svg';
import { Link } from '@zola/zola-ui/src/components/Link';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import { MappedVenueMenuView } from '~/types/responseTypes';
import { formatAsCurrency, formatWithCommas } from '~/util/textUtils';

import CircleImage from '../../images/CircleImage';
import './menuCardContents.less';

type MenuCardContentsProps = {
  menu: MappedVenueMenuView;
  onClick?: () => void;
};

const MenuCardContents = (props: MenuCardContentsProps) => {
  const { onClick, menu } = props;

  const { name, description, startPrice, endPrice, link, options, otherOptions } = menu;
  const formattedStartPrice = formatAsCurrency(startPrice);
  const formattedEndPrice = formatWithCommas(endPrice);
  const priceRange =
    formattedStartPrice && formattedEndPrice
      ? `${formattedStartPrice}-${formattedEndPrice}`
      : `Starts at ${formattedStartPrice}`;
  const optionItems = (options || []).map((option) => <li key={option.id}>{option.name}</li>);

  return (
    <div
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !!onClick) {
          onClick();
        }
      }}
      tabIndex={0}
      role="button"
      className="menu-card__body"
    >
      <div className="menu-card__header mb-tertiary">
        <CircleImage className="menu-card__icon" src={Mixer} />
        {priceRange && <div className="menu-card__price-range">{priceRange} per guest</div>}
      </div>
      <div className="menu-card__body">
        <H.TitleAccent3 className="menu-card__name mb-tertiary" presentation="h4">
          {name}
        </H.TitleAccent3>
        <P.BodySmall className="menu-card__description mb-secondary">{description}</P.BodySmall>
        <ul className="menu-card__options">
          {optionItems}
          {otherOptions && <li>{otherOptions}</li>}
        </ul>
        {link && (
          <div className="menu_card__external-link mt-secondary">
            <Link className="menu-card__link" href={link} target="_blank" rel="noopener noreferrer">
              View menu
              <span
                className="zola-ui-icon zola-ui-icon-link-ext favorite-or-share__icon-link"
                aria-hidden="true"
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuCardContents;
