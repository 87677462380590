import { useMemo, useState, useEffect } from 'react';

import CachedImage from '~/components/common/images/CachedImage';
import { useLightboxContext } from '~/pages/couples/storefronts/contexts/LightboxContext';
import { useStorefrontDetails } from '~/pages/couples/storefronts/contexts/StorefrontDetailsContext';
import type { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import {
  Container,
  Credit,
  MobileText,
  NotMobileText,
  Photo,
  StyledButton,
  StyledPhotoIcon,
} from './CoverGalleryV2.styles';
import { srcSizes, insufficientPhotosSrcSizes, sizes, insufficientSizes } from './helpers';
import placeholder from './placeholder-photo.svg';

export const getLightboxPhotoUuids = ({
  coverGallery = [],
  photoGallery = [],
}: CouplesStorefrontDetails) => {
  const coverGalleryUuids = coverGallery.map((photo) => photo.uuid);
  const photoGalleryUuids = photoGallery.map((photo) => photo.uuid);
  return [...coverGalleryUuids, ...photoGalleryUuids].filter(Boolean);
};

type CoverGalleryProps = {
  className?: string;
};

export const CoverGallery = ({ className }: CoverGalleryProps) => {
  const { storefrontDetails, isUnclaimed } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { openLightbox } = useLightboxContext();

  const { coverGallery = [], name, social, photoGallery = [] } = storefrontDetails;
  const photos = useMemo(
    () => [coverGallery[0], photoGallery[0], photoGallery[1], coverGallery[1]],
    [coverGallery, photoGallery]
  );

  const [coverPhotos, setCoverPhotos] = useState(photos);
  const [twoPhotos, setTwoPhotos] = useState(coverPhotos.filter(Boolean).length === 2);
  const fullGalleryLength = coverGallery.length + photoGallery.length;

  useEffect(() => {
    // only use the vendor controlled cover photos if the vendor has actually set them, otherwise default to 2 cover photos / 2 gallery photos
    if (coverGallery.length > 2) {
      const vendorControlledCoverPhotos = [
        coverGallery[0],
        coverGallery[1],
        coverGallery[2],
        coverGallery[3],
      ];
      setCoverPhotos(vendorControlledCoverPhotos);
    } else {
      setCoverPhotos(photos);
    }
  }, [coverGallery, photos]);

  useEffect(() => {
    setTwoPhotos(coverGallery.length === 2 && coverPhotos.filter(Boolean).length === 2);
  }, [coverGallery, coverPhotos]);

  const ICON_SIZE = 20;

  const unclaimedCredit = useMemo(() => {
    let hostname: string | null = null;

    if (social?.web) {
      try {
        const url = new URL(social.web);
        hostname = url.hostname.toLowerCase();
      } catch {
        hostname = null;
      }
    }

    return `Photo from ${hostname || name}`;
  }, [name, social]);

  return (
    <Container className={className} insufficientPhotos={twoPhotos}>
      {coverPhotos.map((photo, i) => {
        const srcSize = twoPhotos ? insufficientPhotosSrcSizes[i] : srcSizes[i];
        const size = twoPhotos ? insufficientSizes[i] : sizes[i];
        if (photo?.uuid) {
          return (
            <Photo
              key={`cover-gallery-${i}`}
              onClick={() => openLightbox(photo.uuid, 'Vendor photos')}
              insufficientPhotos={twoPhotos}
            >
              <CachedImage
                uuid={photo.uuid}
                srcSizes={srcSize}
                sizes={`(min-width: 1024px) ${size || 100}vw, 100vw`}
                fit="crop"
                quality={75}
              />
              {isUnclaimed && <Credit>{unclaimedCredit}</Credit>}
            </Photo>
          );
        }
        return (
          <Photo key={`placeholder-${i}`} insufficientPhotos={twoPhotos} isPlaceholder>
            <img src={placeholder} alt="placeholder" />
          </Photo>
        );
      })}
      {!twoPhotos && coverPhotos.filter(Boolean).length === 4 && (
        <StyledButton
          variant="secondary"
          onClick={() => openLightbox(coverPhotos[0].uuid, 'Vendor photos')}
        >
          <StyledPhotoIcon height={ICON_SIZE} width={ICON_SIZE} />
          <NotMobileText>View all ({fullGalleryLength}+)</NotMobileText>
          <MobileText>{fullGalleryLength}</MobileText>
        </StyledButton>
      )}
    </Container>
  );
};
