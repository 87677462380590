import { CheckmarkIcon } from '@zola/zola-ui/src/components/SvgIcons';

import styles from './mobileServiceModal.module.less';

interface PackageLineItemProps {
  option: {
    key: string | null;
    name: string | null;
  };
}

const PackageLineItem = ({ option }: PackageLineItemProps) => (
  <div className={styles.item}>
    <CheckmarkIcon alt="Yes" fill="#15845d" height={16} width={16} />

    <div className={styles.label}>{option.name}</div>
  </div>
);

export default PackageLineItem;
