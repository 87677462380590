import { Fragment } from 'react';

import type { CouplesStorefrontDetailsMusician } from '~/types/storefrontDetails';

import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import VideoGallery from '../components/VideoGallery';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const MusicianDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsMusician>();
  const { packages, preferredVendors, gallery } = storefrontDetails;
  const musicGenresOffered = filterForParentKey('music-genre', storefrontDetails.options);
  const musicianServicesOffered = filterForParentKey(
    'musician-services',
    storefrontDetails.options
  );
  const eventTypesOffered = filterForParentKey('event-types', storefrontDetails.options);
  const hasServices = [musicGenresOffered, musicianServicesOffered, eventTypesOffered].some(
    (services) => services.length > 0
  );

  const hasServiceOrPackageOrGallery = hasServices || !!packages?.length || !!gallery?.length;

  return (
    <Fragment>
      <VideoGallery heading="Performance samples" />
      {hasServiceOrPackageOrGallery && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices ? (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(
                musicianServicesOffered.length > 0,
                'Services',
                musicianServicesOffered,
                true
              )}
              {serviceBlock(
                musicGenresOffered.length > 0 || eventTypesOffered.length > 0,
                'Musical genres',
                musicGenresOffered,
                true
              )}
              {serviceBlock(eventTypesOffered.length > 0, 'Event types', eventTypesOffered, true)}
            </VendorStorefrontSection>
          ) : (
            <Fragment />
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection
        title={`Preferred vendors of ${storefrontDetails.name}`}
        vendors={preferredVendors}
      />
      <OtherVendorsSection
        title={`More wedding services from ${storefrontDetails.name}`}
        vendors={otherServices}
      />
      <RealWeddingsSection />
      <FaqsSection />
      <AvailabilityCalendar />
    </Fragment>
  );
};

export default MusicianDetails;
