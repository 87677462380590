import cx from 'classnames';

import { StorefrontMarketView } from '~/types/responseTypes';

import ServicesModalWrapper from './ServicesModalWrapper';

import styles from './ServicesModal.module.less';

export interface HomeMarketsModalProps {
  markets: {
    homeMarkets: StorefrontMarketView[];
    travelMarkets: StorefrontMarketView[];
  };
  vendorName: string | null;
  vendorLocation: string;
}

const HomeMarketsModal = (props: HomeMarketsModalProps): JSX.Element | null => {
  const { markets, vendorLocation, vendorName } = props;
  const { homeMarkets, travelMarkets } = markets;

  const displayMarketsList = (marketsList: StorefrontMarketView[]) => (
    <ul>
      {marketsList.map(({ market }) => (
        <li className={cx(styles.label, 'mt-quaternary')} key={market.id}>
          {market.label}
        </li>
      ))}
    </ul>
  );
  return (
    <ServicesModalWrapper
      title="Standard markets served"
      vendorLocation={vendorLocation}
      contentList={homeMarkets}
      vendorName={vendorName}
    >
      {travelMarkets.length > 0 && (
        <div className={cx(styles.servicesModalMarketsListContainer, styles.servicesList)}>
          <div className={cx(styles.servicesModalListTitle, styles.mobileMargin)}>
            Markets served for additional fees
          </div>
          {displayMarketsList(travelMarkets)}
        </div>
      )}
    </ServicesModalWrapper>
  );
};

export default HomeMarketsModal;
