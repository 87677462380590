import { MutableRefObject, useCallback, useEffect, useState, useRef } from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import { useModal } from '~/contexts/ModalContext';

import DescriptionModal from './DescriptionModal';
import { Cell } from './TableHelperComponents';

import styles from './desktopServiceMatrix.module.less';

interface DescriptionCellsProps {
  descriptions: (string | null)[];
  packageNames: string[];
}

const DescriptionCells = (props: DescriptionCellsProps) => {
  const { packageNames, descriptions } = props;
  const [showMoreButton, setShowMoreButton] = useState<boolean[]>([]);
  const descriptionsRef: MutableRefObject<HTMLParagraphElement[]> = useRef([]);
  const { showCustomModal } = useModal();

  /**
   * Count the amount of lines in a description
   * using ref and diving by line height, then check
   * if the description is 6 lines or more and uses that
   * boolean to show the more button or not.
   */
  useEffect(() => {
    const refs = descriptionsRef.current;
    const MAX_TEXT_ROWS = 6;
    const descriptionTextRows: boolean[] = [];
    refs.forEach((desc) => {
      if (desc.clientHeight === 0) {
        descriptionTextRows.push(false);
      } else {
        const textRow = Math.round(desc.clientHeight / 18);
        descriptionTextRows.push(textRow >= MAX_TEXT_ROWS);
      }

      setShowMoreButton(descriptionTextRows);
    });
  }, [descriptionsRef]);

  const openDescriptionModal = useCallback(
    (description, idx) => {
      showCustomModal(DescriptionModal, {
        description,
        planningLevel: packageNames[idx],
      });
    },
    [packageNames, showCustomModal]
  );

  return (
    <tr>
      <th
        aria-label="planning service description"
        scope="row"
        className={styles.descriptor}
        key="description"
      >
        Description
      </th>
      {descriptions.map((description, idx) => (
        <Cell className={styles.descriptionCtn} key={idx}>
          <p
            ref={(element) => {
              descriptionsRef.current[idx] = element as HTMLParagraphElement;
            }}
            className={styles.description}
          >
            {description}
          </p>
          {showMoreButton[idx] && (
            <LinkV2
              className={styles.moreLink}
              type="button"
              onClick={() => openDescriptionModal(description, idx)}
            >
              More
            </LinkV2>
          )}
        </Cell>
      ))}
    </tr>
  );
};

export default DescriptionCells;
