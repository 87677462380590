import { Fragment } from 'react';

import { Tabs } from '@zola/zola-ui/src/components/Tabs';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import { CatererStoryView, VenueSpaceView } from '~/types/responseTypes';

import styles from './showcasesSection.module.less';

interface ShowcasesSectionProps {
  component: any;
  className?: string;
  heading: string;
  showcases: CatererStoryView[] | VenueSpaceView[];
}

const ShowcasesSection = ({
  component: Component,
  className,
  heading,
  showcases,
}: ShowcasesSectionProps): JSX.Element => {
  const classes = cx(
    styles.showcasesSection,
    'marketplace__showcases-section storefront__section',
    className
  );

  if (!showcases || !showcases.length) {
    return <Fragment />;
  }

  const showcaseNavItems = showcases.map((showcase, idx) => ({
    id: idx,
    text: showcase.name,
    panelContent: <Component showcase={showcases[idx]} />,
  }));
  return (
    <div className={classes}>
      <div className="container">
        <hr className={styles.sectionHr} />
        <H.Title2 className={styles.heading} presentation="h4" strong>
          {heading}
        </H.Title2>
        <Tabs
          tabItems={showcaseNavItems}
          initialTabId={0}
          arrowVariant={showcaseNavItems?.length > 3}
          noVerticalScroll
        />
      </div>
    </div>
  );
};

export default ShowcasesSection;
