import { createContext, useCallback, useContext, useMemo } from 'react';

import { AccountIncentiveStatusView } from '@zola/svc-marketplace-ts-types';
import { markPreAuthUserAsHavingSeenIncentive } from '@zola/zola-ui/src/hooks/useAccountIncentivizationCheck/useAccountIncentivizationCheck';

import { IUserContext } from '~/types/userContext';
import ApiService from '~/util/apiService';

type IncentiveStatusLookup = {
  [type in AccountIncentiveStatusView.OfferTypeEnum]?: AccountIncentiveStatusView;
};

type AccountsIncentivizationContextType = {
  activateIncentive: (offerType: AccountIncentiveStatusView.OfferTypeEnum) => Promise<void>;
  isBookedInquiryIncentiveAvailable: () => boolean;
};

const AccountsIncentivizationContext = createContext<
  AccountsIncentivizationContextType | undefined
>(undefined);

export const useAccountIncentivizationContext = () => {
  const context = useContext(AccountsIncentivizationContext);
  if (context === undefined) {
    throw new Error(
      'AccountsIncentivizationContext must be used within a AccountsIncentivizationProvider'
    );
  }
  return context;
};

/**
 * For a post-auth user, record with the backend that an account incentive was
 * seen and is now available to the account.
 *
 * @param offerType
 */
const activateAccountIncentive = async (
  offerType: AccountIncentiveStatusView.OfferTypeEnum
): Promise<AccountIncentiveStatusView> => {
  return ApiService.post<AccountIncentiveStatusView>(
    '/web-marketplace-api/v1/account-incentive/activate-offer',
    {
      offerType,
    }
  );
};

/**
 * Provides data and functions for accessing and activating account incentives.
 *
 * An account incentive is a discount or promotion that is available to a post-auth
 * users and requires that they preform some action to receive the discount. For
 * example: booking an inquiry might get them 65% off save the dates.  They have to
 * first see the incentive (activating) then they have to complete the incentive.
 *
 * Completing the incentive happens on the backend.
 *
 * @param param0
 * @returns
 */
export const AccountIncentivizationProvider: React.FC<{
  userContext: IUserContext | null;
  incentiveStatus: AccountIncentiveStatusView[];
}> = ({ children, userContext, incentiveStatus }) => {
  /**
   * Callback to activate an incentive.  For the pre-auth user, we don't yet
   * have an account, and we don't want to populate a bunch of orphan records
   * in the backend, so we record in local storage that the incentive was seen.
   */
  const activateIncentive = useCallback(
    (offerType: AccountIncentiveStatusView.OfferTypeEnum): Promise<void> => {
      if (!userContext || userContext.is_guest) {
        // Record local storage
        markPreAuthUserAsHavingSeenIncentive(offerType);
        return Promise.resolve();
      } else {
        return activateAccountIncentive(offerType).then(() => undefined);
      }
    },
    [userContext]
  );

  /**
   * Transforms the list of available incentives into a lookup table
   */
  const incentiveStatusLookup: IncentiveStatusLookup = useMemo(() => {
    return incentiveStatus.reduce((acc, status) => {
      return {
        ...acc,
        [status.offerType]: status,
      };
    }, {} as IncentiveStatusLookup);
  }, [incentiveStatus]);

  const isIncentiveAvailable = useCallback(
    (offerType: AccountIncentiveStatusView.OfferTypeEnum): boolean => {
      const status = incentiveStatusLookup[offerType];
      // We could check status.availableForCompletion here but a decision was made
      // to keep the incentive sticking around
      return Boolean(status && (status.availableForCompletion || status.activatedAt));
    },
    [incentiveStatusLookup]
  );

  const isBookedInquiryIncentiveAvailable = useCallback(() => {
    if (isIncentiveAvailable('JUNE2024_BOOKEDINQUIRY_65OFFSTD')) {
      return true;
    }
    return false;
  }, [isIncentiveAvailable]);

  return (
    <AccountsIncentivizationContext.Provider
      value={{
        activateIncentive,
        isBookedInquiryIncentiveAvailable,
      }}
    >
      {children}
    </AccountsIncentivizationContext.Provider>
  );
};
