import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { getImageUrl, ImageFit } from '~/util/imageUtils';
import { getBioPhotoImageId } from '~/util/storefrontUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

const useStorefrontAvatar = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const avatar = getImageUrl(getBioPhotoImageId(storefrontDetails, false), {
    width: 360,
    height: 360,
    fit: ImageFit.CROP,
  });

  return avatar;
};

export default useStorefrontAvatar;
