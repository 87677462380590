import { useMemo } from 'react';

import {
  OptionFacetView,
  StorefrontPriceView,
  WeddingPlannerPackageView,
} from '@zola/svc-marketplace-ts-types';
import { PlusIcon } from '@zola/zola-ui/src/components/SvgIcons';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import Checkmark from '~/components/common/ui/Checkmark/Checkmark';
import { centsToDollarsWithCommas } from '~/util/priceConversion';

import {
  countOccurrences,
  getMatrix,
  getSortedMap,
  onlyUnique,
  sortByNumberOfOptions,
} from '../utils/servicePackageHelpers';
import DescriptionCells from './DescriptionCells';
import { Cell, LineItemRow, PackageNameHeader } from './TableHelperComponents';

import styles from './desktopServiceMatrix.module.less';

export interface DesktopServiceMatrixProps {
  packages: WeddingPlannerPackageView[];
  priceRangeVisible: boolean;
}

const makePriceText = (
  price: StorefrontPriceView | null,
  hasRate: boolean | null
): string | null => {
  if (!price || !price.minCents || price.minCents < 100) return null;
  const priceInDollars = centsToDollarsWithCommas(price.minCents);
  const message = `Starts at $${priceInDollars}`;
  return hasRate ? `${message}*` : `${message}`;
};

const makeTimelineText = (timeFacet: OptionFacetView): string => {
  if (timeFacet.key === 'length-of-time-day-only') return `${timeFacet.name}`;
  return `${timeFacet.name} out`;
};

/**
 * @name DesktopServiceMatrix
 * @description generates a dynamic table for 1-7 service packages and their options
 * @param {PlannerServicePackageView[]} packages array of vendor's service packages
 * @returns a large table with the InquiryButton included
 */
export const DesktopServiceMatrix = ({
  packages,
  priceRangeVisible,
}: DesktopServiceMatrixProps) => {
  const { matrix, packageNames, prices, showNote, timelines, descriptions } = useMemo(() => {
    // ensure service level packages with the most to offer appear first
    const sortedServiceLevels = packages.sort(sortByNumberOfOptions);
    return {
      matrix: getMatrix(sortedServiceLevels),
      packageNames: packages.map(({ serviceLevelFacet }) =>
        serviceLevelFacet ? serviceLevelFacet.name : ''
      ),
      prices: packages.map(({ storefrontPriceView: pricing, hasPercentageRate }) =>
        makePriceText(pricing, hasPercentageRate)
      ),
      showNote: packages.filter(({ hasPercentageRate }) => hasPercentageRate).length > 0,
      timelines: packages.map(({ lengthOfTimeFacet }) =>
        lengthOfTimeFacet ? makeTimelineText(lengthOfTimeFacet) : null
      ),
      descriptions: packages.map(({ description }) => description),
    };
  }, [packages]);

  const sortedLineItems: React.ReactNode[] = useMemo(() => {
    const serviceIds = matrix.flat().filter(onlyUnique);
    const idMap = new Map<number, number>();
    serviceIds.forEach((id) => idMap.set(id || 0, matrix.reduce(countOccurrences(id), 0)));
    const sortedByOccurrence = getSortedMap(idMap);

    /* rows of various service items, sorted by occurrence across packages */
    const keys = [...sortedByOccurrence.keys()];
    return keys.map((key) => (
      <LineItemRow id={key as unknown as number} packages={packages} key={key} />
    ));
  }, [matrix, packages]);

  return (
    <div className={styles.matrixContainer}>
      <div className={styles.heading}>
        <H.Title2 presentation="h4" strong>
          Services by planning level
        </H.Title2>
        <div className={styles.legendContainer}>
          <div className={styles.legend}>
            <Checkmark />
            <span>
              Service <strong>included in starting price</strong>
            </span>
          </div>
          <div className={cx(styles.legend, styles.extraCostLegend)}>
            <PlusIcon className={styles.icon} />
            <span>
              Service available <strong>for an extra cost</strong>
            </span>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th aria-hidden scope="col" className={styles.spacerCell} key="spacer" />
            {packageNames.map((name, idx) => (
              <PackageNameHeader name={name || ''} key={idx.toString()} />
            ))}
          </tr>
          {/* if no descriptions exist for any planning level don't show that row */}
          {!descriptions.every((description) => !description) && (
            <DescriptionCells packageNames={packageNames} descriptions={descriptions} />
          )}
          {timelines.filter(Boolean).length > 0 ? (
            <tr>
              <th
                aria-label="planning service timeframe"
                scope="row"
                className={styles.descriptor}
                key="timeline-descriptor"
              >
                Planning begins
              </th>
              {timelines.map((timeline, idx) => (
                <Cell key={idx}>{timeline}</Cell>
              ))}
            </tr>
          ) : null}
        </thead>
        <tbody className={styles.tbody}>{sortedLineItems}</tbody>
        {priceRangeVisible && (
          <tfoot>
            <tr>
              <th
                aria-label="service pricing"
                scope="row"
                className={styles.descriptor}
                key="pricing-descriptor"
              >
                Pricing
              </th>
              {prices.map((price, idx) => (
                <Cell key={idx}>{price}</Cell>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
      {priceRangeVisible && (
        <div className={styles.footer}>
          {showNote && <aside role="note">*Plus % of total wedding costs</aside>}
        </div>
      )}
    </div>
  );
};

export default DesktopServiceMatrix;
