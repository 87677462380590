import { useCallback } from 'react';

import { useModal } from '~/contexts/ModalContext';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import UnclaimedClaimListingForm from './UnclaimedClaimListingForm';

import styles from './unclaimedClaimListingModal.module.less';

interface UnclaimedClaimListingModalProps {
  storefrontDetails: CouplesStorefrontDetails;
}

export const useUnclaimedClaimListingModal = () => {
  const { showV2PredefinedModal } = useModal();

  const showUnclaimedClaimListingModal = useCallback(
    (storefrontDetails: CouplesStorefrontDetails) => {
      showV2PredefinedModal<UnclaimedClaimListingModalProps>(
        'UNCLAIMED_CLAIM_LISTING',
        {
          dialogHeadingId: 'unclaimed-claim-listing',
          triggerRef: null,
          lockBgScrolling: true,
          disableFocusTrap: true,
        },
        { storefrontDetails }
      );
    },
    [showV2PredefinedModal]
  );

  return { showUnclaimedClaimListingModal };
};

const UnclaimedClaimListingModal = (props: UnclaimedClaimListingModalProps) => {
  const { storefrontDetails } = props;
  return (
    <div className={styles.unclaimedClaimListingModal}>
      <UnclaimedClaimListingForm storefrontDetails={storefrontDetails} />
    </div>
  );
};

export default UnclaimedClaimListingModal;
