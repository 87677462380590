export type JumpMenuItem = { label: string; id: string };

export enum JumpMenuSectionIds {
  Photos = 'top-anchor',
  About = 'about-anchor',
  ServicesPricing = 'services-pricing-anchor',
  Reviews = 'reviews-anchor',
  RealWeddings = 'real-weddings-anchor',
  FAQs = 'faqs-anchor',
}
export const jumpAnchors = [
  { label: 'Photos', id: JumpMenuSectionIds.Photos },
  { label: 'About', id: JumpMenuSectionIds.About },
  { label: 'Services & pricing', id: JumpMenuSectionIds.ServicesPricing },
  { label: 'Reviews', id: JumpMenuSectionIds.Reviews },
  { label: 'Real weddings', id: JumpMenuSectionIds.RealWeddings },
  { label: 'FAQs', id: JumpMenuSectionIds.FAQs },
];
