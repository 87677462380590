import { InfoCircleIcon } from '@zola/zola-ui/src/components/SvgIconsV3/InfoCircle';

import cx from 'classnames';

import styles from './infoSection.module.less';

interface InfoSectionProps {
  className?: string;
  children: React.ReactNode;
}

const InfoSection = ({ className, children }: InfoSectionProps) => (
  <div className={cx(styles.infoSection, className)}>
    <div className={styles.icon}>
      <InfoCircleIcon width={22} height={22} />
    </div>
    <div className={styles.info}>{children}</div>
  </div>
);

export default InfoSection;
