import { Fragment, useMemo, useState } from 'react';

import ButtonV3 from '@zola/zola-ui/src/components/ButtonV3/ButtonV3';
import { ButtonColorway, ButtonVariant } from '@zola/zola-ui/src/components/ButtonV3/types';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import {
  PartialTaxonomyNode,
  PartialVendorSearch,
} from '~/pages/couples/explore/components/SearchResults/types/types';
import { LocationOrAllMarketsType } from '~/types/responseTypes';
import { isSearchLocation } from '~/util/searchUtils';

import SavedSearchesButton from '../SavedSearches/SavedSearchesButton';
import LocationDropdown from './LocationDropdown';
import { SearchButton, SearchButtonProps } from './SearchButton';
import VendorDropdown from './VendorDropdown';

import styles from './stackedSearchbar.module.less';

export interface StackedSearchBarProps {
  selectedLocation: LocationOrAllMarketsType;
  updateSelectedLocation: (location: LocationOrAllMarketsType) => void;
  selectedTaxonomyNode: PartialTaxonomyNode | null;
  setSelectedTaxonomyNode: (t: PartialTaxonomyNode | null) => void;
  navigateToSrp: (nextSearch: PartialVendorSearch) => void;
  includeSavedSearch?: boolean;
  variant?: ButtonVariant;
  colorway?: ButtonColorway;
  isClpRedesign?: boolean;
}

/**
 * A search bar composed of two dropdowns, one for locations and one for vendor types, and a search button
 */
export const StackedSearchBar = ({
  selectedLocation,
  updateSelectedLocation,
  selectedTaxonomyNode,
  setSelectedTaxonomyNode,
  navigateToSrp,
  includeSavedSearch = false,
  isClpRedesign,
}: StackedSearchBarProps): JSX.Element => {
  const [locationDropdownInput, setLocationDropdownInput] = useState(
    (() => {
      if (isSearchLocation(selectedLocation)) {
        return selectedLocation.label;
      }
      return '';
    })()
  );
  const [vendorDropdownInput, setVendorDropdownInput] = useState(selectedTaxonomyNode?.label || '');

  const searchButtonProps: SearchButtonProps = useMemo(() => {
    return {
      innerButtonClassName: styles.button,
      onClick: () => {
        navigateToSrp({
          selectedLocation,
          selectedTaxonomy: selectedTaxonomyNode,
        });
      },
    };
  }, [navigateToSrp, selectedLocation, selectedTaxonomyNode]);

  return (
    <div
      data-testid="stacked-search-bar"
      className={cx('stacked-search-bar', styles.stackedSearchbar)}
    >
      <H.Title3 presentation="h5" className={styles.hed}>
        Search vendors on Zola
      </H.Title3>
      <div className={cx(styles.inputs)}>
        <div>
          <VendorDropdown
            selectedTaxonomyNode={selectedTaxonomyNode}
            setSelectedTaxonomyNode={setSelectedTaxonomyNode}
            inputString={vendorDropdownInput}
            setInputString={setVendorDropdownInput}
            className={styles.input}
            navigateToSrp={navigateToSrp}
            selectedLocation={selectedLocation}
          />
        </div>
        <div>
          <LocationDropdown
            selectedLocation={selectedLocation}
            updateSelectedLocation={updateSelectedLocation}
            inputString={locationDropdownInput}
            setInputString={setLocationDropdownInput}
            selectedTaxonomyNode={selectedTaxonomyNode}
            className={styles.input}
            navigateToSrp={navigateToSrp}
          />
        </div>
      </div>
      <div className={cx(styles.buttonContainer)}>
        {includeSavedSearch && <SavedSearchesButton className={styles.savedSearchesButton} />}
        <Fragment>
          {/* Only one of these two search buttons will show, which one is determined
              by CSS.  If we were using isMobile() / isDesktop() from our zola-helpers
              responsive utilities, the attributes would change between the server
              side render and the client side render.  To avoid page hydration errors
              we use CSS to show/hide a button based on the screen size, but both
              buttons are in the DOM.
          */}
          <SearchButton
            className={cx(styles.searchButton, styles.desktopSearchButton)}
            {...searchButtonProps}
            size={isClpRedesign ? 'medium' : 'large'}
            variant="primary"
            fullWidth
          />
        </Fragment>
        <ButtonV3
          component="a"
          href="/inspiration/onboard?entry=CLP"
          variant="secondary"
          fullWidth
          textTransform={false}
          size="large"
          className={cx(!isClpRedesign && styles.quizBtn)}
        >
          Take our quiz
        </ButtonV3>
      </div>
    </div>
  );
};

export default StackedSearchBar;
