import { Fragment } from 'react';

import H from '@zola/zola-ui/src/typography/Headings';

import { VideoGalleryDisplay } from '~/pages/vendors/Storefront/editPages/VideoGallery';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './videoGallery.module.less';

interface VideoGalleryProps {
  heading?: string;
}

const COLUMNS = 4;

const VideoGallery = ({ heading }: VideoGalleryProps) => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { taxonomyKey, gallery } = storefrontDetails;

  if (gallery.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="storefront__section storefront__video-gallery-section">
      <div className="container">
        <hr />
        <H.Title2 className={styles.heading} presentation="h4" strong>
          {heading || 'Wedding video samples'}
        </H.Title2>
        <div>
          <VideoGalleryDisplay
            gallery={gallery}
            columnsPerRow={COLUMNS}
            canEdit={false}
            canPlay
            taxonomyKey={taxonomyKey}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
