import { Fragment } from 'react';

import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';

import cx from 'classnames';

import { addDays, formatDateFrom, isBeforeDate } from '~/util/dateUtils';

import styles from './firstMoveExpiration.module.less';

export const getDaysLeftToResponse = (firstMove: StorefrontFirstMoveView): string | null => {
  const { expiresAt } = firstMove;
  if (expiresAt) {
    if (isBeforeDate(expiresAt, addDays(new Date(), 1))) {
      return '1 day left to respond';
    }

    return `${formatDateFrom(expiresAt, new Date(), true)} left to respond`;
  }
  return null;
};

type FirstMoveExpirationProps = React.HTMLAttributes<HTMLSpanElement> & {
  firstMove: StorefrontFirstMoveView;
};

export const FirstMoveExpiration = ({
  firstMove,
  className,
  ...rest
}: FirstMoveExpirationProps) => {
  const expirationText = getDaysLeftToResponse(firstMove);

  if (expirationText) {
    return (
      <span className={cx(styles.firstMoveExpiration, className)} {...rest}>
        {expirationText}
      </span>
    );
  }
  return <Fragment />;
};
