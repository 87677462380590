import { FormEvent } from 'react';

import BannerV2 from '@zola/zola-ui/src/components/BannerV2/BannerV2';
import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import { ErrorBoundary } from '@zola/zola-ui/src/components/ErrorBoundary';
import { PreauthFooterV3 } from '@zola/zola-ui/src/components/PreAuthV3';

import cx from 'classnames';

import ScrollToTop from '~/components/common/ScrollToTop';
import Logger from '~/util/logger';

import VendorGladApp from '../../VendorGladApp/VendorGladApp';
import LinkBlock from './components/LinkBlock';

import './vendorEditLayout.less';

type VendorsEditLayoutProps = {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  sectionTitle: string;
  mobileSectionTitle?: string;
  saveFunc?: (event: FormEvent<Element>) => void;
  backTo?: string;
  backCopy?: string;
  backOnClick?: (() => void) | null;
  disableSave?: boolean;
  containerClasses?: string;
  link?: {
    url?: string;
    copy?: string;
    clickFn?: () => void;
  };
  awaitBack?: () => void;
  bannerProps?: {
    type?: 'warning' | 'minorwarn' | 'marketing' | 'informational' | 'error';
    children?: React.ReactElement;
  };
};

const VendorsEditLayout = (props: VendorsEditLayoutProps) => {
  const {
    id = '',
    children = null,
    className = null,
    sectionTitle,
    mobileSectionTitle,
    saveFunc,
    backTo = '/inspire/vendors/listing',
    backCopy = 'Back',
    backOnClick,
    disableSave = false,
    containerClasses = 'container',
    link,
    awaitBack,
    bannerProps,
  } = props;

  const classes = cx('marketplace__edit-storefront-layout', className);

  return (
    <ScrollToTop>
      <ErrorBoundary Logger={Logger}>
        <div id={id} className={classes}>
          <div className="edit-storefront__nav">
            <div className="nav__edge-element">
              <LinkBlock
                backTo={backTo}
                backOnClick={backOnClick || undefined}
                awaitBack={awaitBack}
              >
                <span className="hidden-xs hidden-sm">{backCopy}</span>
              </LinkBlock>
            </div>
            <div className="nav__page-title">
              <h1 className="nav__section-title">
                <span className="hidden-xs">{sectionTitle}</span>
                <span className="visible-xs">{mobileSectionTitle || sectionTitle}</span>
              </h1>
            </div>
            <div className="nav__edge-element">
              {link && link.url && (
                <ButtonV2
                  className="hidden-xs"
                  component="a"
                  href={link.url}
                  onClick={link.clickFn}
                >
                  {link.copy}
                </ButtonV2>
              )}
              {saveFunc && typeof saveFunc === 'function' && (
                <ButtonV2 onClick={saveFunc} disabled={disableSave} className="hidden-xs">
                  Save
                </ButtonV2>
              )}
            </div>
          </div>
          {bannerProps && <BannerV2 {...bannerProps} className="banner-position-override" />}
          <div className={containerClasses}>
            <div className="edit-storefront__container">{children}</div>
          </div>
        </div>
        <div className="marketplace__vendors-footer">
          <PreauthFooterV3 isGuest />
        </div>
        <VendorGladApp />
      </ErrorBoundary>
    </ScrollToTop>
  );
};

export default VendorsEditLayout;
