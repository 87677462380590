import { ViewStorefront } from '@zola/svc-marketplace-ts-types';

import ApiService from '~/util/apiService';

export const addRecentlyViewedStorefront = (request: Omit<ViewStorefront, 'accountId'>) => {
  return ApiService.post<void, Omit<ViewStorefront, 'accountId'>>(
    '/web-marketplace-api/v1/recently-viewed/storefronts',
    request
  );
};
