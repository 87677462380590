import { Fragment } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorWordSingular } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { pluralize } from '@zola-helpers/client/dist/es/transformers';
import { VendorMarketView } from '@zola/svc-marketplace-ts-types';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import StarRatings from '@zola/zola-ui/src/components/StarRatings/StarRatings';
import CheckmarkIcon from '@zola/zola-ui/src/components/SvgIcons/Checkmark';
import GeoPinIcon from '@zola/zola-ui/src/components/SvgIcons/GeoPin';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import cx from 'classnames';

import TravelAirplaneIcon from '~/assets/images/ui/travelAirplane.svg';
import { useModal } from '~/contexts/ModalContext';
import { LocationOrAllMarketsType, StorefrontMarketsView } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { formatLocation } from '~/util/formatters';
import { formatRating } from '~/util/reviewUtils';
import getCanonicalUrl, { getPublicReviewUrl } from '~/util/storefrontUrl';
import { isClaimed, isPreview } from '~/util/storefrontUtils';
import { titleCase } from '~/util/textUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import HomeMarketsModal, { HomeMarketsModalProps } from './HomeMarketsModal';
import UnclaimedDisclaimerSection from './UnclaimedDisclaimerSection';

import styles from './overviewLocation.module.less';

export interface OverviewLocationProps {
  nycDisplayHeaderText: string | null;
  displayServiceMarket: boolean;
  displayTravelMarket: boolean;
  selectedMarketStateCode: string | null;
  selectedMarket: VendorMarketView | null;
  isVendorLocationSameAsSearch: boolean;
  recordedLocation: LocationOrAllMarketsType | Record<string, never>;
  markets: StorefrontMarketsView;
  isRecordedLocationPresent: boolean;
}

const MarketServedCopy = ({
  nycDisplayHeaderText,
  displayServiceMarket,
  displayTravelMarket,
  displayLocation,
  showIcon,
}: Pick<
  OverviewLocationProps,
  'nycDisplayHeaderText' | 'displayServiceMarket' | 'displayTravelMarket'
> & {
  displayLocation: string;
  showIcon: boolean;
}) => {
  const serviceMarketCopy = (
    <Fragment>
      <span className="hidden-xs">|</span>{' '}
      {showIcon && (
        <>
          <CheckmarkIcon color={COLORS3.BLACK_075} />{' '}
        </>
      )}
      <P.BodyBase className="inlineText" color="BLACK_075">
        Serves {nycDisplayHeaderText || displayLocation}
      </P.BodyBase>
    </Fragment>
  );

  const travelMarketCopy = (
    <Fragment>
      <span className="hidden-xs">|</span>{' '}
      {showIcon && (
        <>
          <img src={TravelAirplaneIcon} alt="Airplane" />{' '}
        </>
      )}
      <P.BodyBase className="inlineText" color="BLACK_075">
        Serves {nycDisplayHeaderText || displayLocation} for additional fees
      </P.BodyBase>
    </Fragment>
  );

  if (displayServiceMarket) return serviceMarketCopy;
  if (displayTravelMarket) return travelMarketCopy;

  return null;
};

const OverviewLocation = (props: OverviewLocationProps) => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const {
    nycDisplayHeaderText,
    displayServiceMarket,
    displayTravelMarket,
    selectedMarketStateCode,
    selectedMarket,
    recordedLocation,
    markets,
    isRecordedLocationPresent,
    isVendorLocationSameAsSearch,
  } = props;
  const {
    uuid,
    taxonomyKey,
    location,
    name,
    address,
    averageReviewsRate,
    recommendations: reviews,
    slug,
  } = storefrontDetails;
  let displayLocation = recordedLocation.label;
  if (!displayLocation) {
    displayLocation = formatLocation({
      city: selectedMarket?.label,
      stateProvince: selectedMarketStateCode,
    });
  }
  const { homeMarkets, travelMarkets } = markets;
  const hasReviews = reviews.length > 0;
  const reviewText = pluralize('review', 'reviews', reviews.length, true);
  const { showCustomModal } = useModal();

  const handleShowMarketsModal = () =>
    showCustomModal(HomeMarketsModal as (props: HomeMarketsModalProps) => JSX.Element, {
      markets,
      vendorLocation: location as string,
      vendorName: name,
    });

  const scrollToReviews = (tabText: string) => {
    const section = document.querySelector('#reviews-anchor');
    if (section) {
      const tabs = section.querySelectorAll('li[role="tab"]');
      section.scrollIntoView();
      tabs.forEach((tab) => {
        if (tab.innerHTML.includes(tabText)) {
          (tab as HTMLElement).click();
        }
      });
    }
  };

  const renderReviews = () => {
    if (hasReviews) {
      return (
        <div className={styles.reviewsLink}>
          <LinkV2
            role="button"
            type="button"
            onClick={() => scrollToReviews('Zola')}
            data-testid="reviews-link"
            noTextTransform
            noUnderline
          >
            <StarRatings
              activeStars={formatRating(averageReviewsRate)}
              reviewCount={reviewText}
              totalStars={5}
              fillColor={COLORS3.YELLOW_100}
            />
          </LinkV2>
        </div>
      );
    }
    if (!isPreview()) {
      return (
        <div className={styles.reviewsLink}>
          No reviews yet.{' '}
          <LinkV2
            href={getPublicReviewUrl(uuid, getCanonicalUrl(taxonomyKey, slug))}
            rel="noindex nofollow"
            noTextTransform
          >
            Write a review
          </LinkV2>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={cx('overview__location', styles.overviewLocation)}>
      {location && (
        <div
          className={cx(
            'overview__block',
            taxonomyKey !== VENUES_TAXONOMY_KEY ? 'overview__flex' : ''
          )}
        >
          {selectedMarket &&
            (taxonomyKey === VENUES_TAXONOMY_KEY && storefrontDetails.address.address1 ? (
              <P.BodyBase className="inlineText" color="BLACK_075">
                <span className="overview__city">
                  {storefrontDetails.address.address1}, {formatLocation(storefrontDetails.address)}
                </span>{' '}
                based{' '}
                <span className="overview__vendor-type">
                  {titleCase(getVendorWordSingular(taxonomyKey))}
                </span>
              </P.BodyBase>
            ) : (
              <div>
                <GeoPinIcon
                  className="location-pin-icon"
                  color={COLORS3.BLACK_075}
                  height={16}
                  width={16}
                />
                <P.BodyBase className="inlineText" color="BLACK_075">
                  <span className="overview__vendor-type">
                    {titleCase(getVendorWordSingular(taxonomyKey))}
                  </span>{' '}
                  based in <span className="overview__city">{formatLocation(address)}</span>
                </P.BodyBase>
              </div>
            ))}
          {isClaimed(storefrontDetails) &&
            selectedMarket &&
            taxonomyKey !== VENUES_TAXONOMY_KEY &&
            isRecordedLocationPresent &&
            !isVendorLocationSameAsSearch && (
              <div className="vendor-market">
                <MarketServedCopy
                  nycDisplayHeaderText={nycDisplayHeaderText}
                  displayServiceMarket={displayServiceMarket}
                  displayTravelMarket={displayTravelMarket}
                  displayLocation={displayLocation}
                  showIcon
                />
              </div>
            )}
          {isClaimed(storefrontDetails) && (homeMarkets.length > 1 || travelMarkets.length > 1) && (
            <div className="overview__block">
              <div className="overview__block hidden-xs">
                <LinkV2
                  className="overview__markets"
                  role="button"
                  onClick={handleShowMarketsModal}
                  arrowPosition="postfix"
                  arrow
                  data-tip
                  data-for="markets-tooltip"
                  data-effect="solid"
                  noTextTransform
                >
                  +{' '}
                  {pluralize('market', 'markets', homeMarkets.length + travelMarkets.length, true)}
                </LinkV2>
              </div>
            </div>
          )}
        </div>
      )}
      {!isClaimed(storefrontDetails) && <UnclaimedDisclaimerSection />}
      {renderReviews()}
    </div>
  );
};

export default OverviewLocation;
