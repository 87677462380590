import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import SmallVendorCard from '~/components/common/cards/SmallVendorCard/SmallVendorCard';
import { vendorCardViewToVendorCardVendor } from '~/components/common/cards/util/helper';
import { CouplesStorefrontDetailsVenue } from '~/types/storefrontDetails';

import SeeAllDrawer from '../../../../../components/common/SeeAllDrawer';
import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';

import styles from './requiredVendorsSection.module.less';

export interface RequiredVendorsSectionProps {
  className?: string;
}

const RequiredVendorsSection = ({ className }: RequiredVendorsSectionProps) => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsVenue>();
  const { name, preferredVendors } = storefrontDetails;
  const vendors = preferredVendors.filter((vendor) => vendor.required);
  const classes = cx('marketplace__required-vendors-section', 'storefront__section', className);

  if (!vendors || !vendors.length) {
    return null;
  }

  const vendorCards = vendors
    .filter((vendor) => Boolean(vendor.taxonomyNode?.key))
    .map((vendor) => {
      const storefront = {
        name: vendor.vendorName,
        type: vendor.taxonomyNode?.key as VendorTaxonomyKey,
        vendorCard: vendorCardViewToVendorCardVendor(vendor),
      };
      return (
        <SmallVendorCard
          className={styles.vendorCard}
          storefront={storefront}
          key={vendor.storefrontUuid}
        />
      );
    });

  return (
    <div className={cx(styles.requiredVendorsSection, 'storefront__section')}>
      <div className="container">
        <hr />
        <div className={classes}>
          <SeeAllDrawer
            data={vendorCards}
            desktopColumnCount={3}
            heading={<H.Title2 presentation="h4" strong>{`Vendors required by ${name}`}</H.Title2>}
          />
        </div>
      </div>
    </div>
  );
};

export default RequiredVendorsSection;
