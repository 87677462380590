import { Fragment, useMemo } from 'react';

import { BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';

import { useUnclaimedLearnMoreModal } from '~/components/common/modals/UnclaimedLearnMoreModal/UnclaimedLearnMoreModal';
import { getDaysLeftToResponse } from '~/pages/couples/inquiries/components/FirstMoveExpiration/FirstMoveExpiration';

import { useStorefrontDetailsUnsafe } from '../../contexts/StorefrontDetailsContext';
import {
  DeclineFirstMoveButton,
  ExploreMoreButton,
  LightboxInquiryButton,
} from './DesktopLightboxControls.styles';

const UnclaimedListingButton = ({ closeLightbox }: { closeLightbox: () => void }) => {
  const { showUnclaimedLearnMoreModal } = useUnclaimedLearnMoreModal();
  const { storefrontDetails } = useStorefrontDetailsUnsafe() || {};

  if (!storefrontDetails) {
    return null;
  }

  return (
    <ExploreMoreButton
      variant="primary"
      colorway="cloudAlt"
      onClick={() => {
        closeLightbox();
        showUnclaimedLearnMoreModal(storefrontDetails);
      }}
    >
      Explore more
    </ExploreMoreButton>
  );
};

export const DesktopLightboxDek = () => {
  const { firstMove } = useStorefrontDetailsUnsafe() || {};

  const dek = useMemo(() => {
    return firstMove ? getDaysLeftToResponse(firstMove) : null;
  }, [firstMove]);

  if (dek) {
    return <BodySmall color="WHITE_100">{dek}</BodySmall>;
  }
  return null;
};

export const DesktopLightboxHeaderControls = ({ onClose }: { onClose: () => void }) => {
  const { storefrontDetails, firstMove, isUnclaimed, dismissFirstMove } =
    useStorefrontDetailsUnsafe() || {};

  return (
    <Fragment>
      {firstMove && (
        <DeclineFirstMoveButton
          variant="secondary"
          colorway="cloudAlt"
          onClick={() => {
            dismissFirstMove?.();
            onClose();
          }}
        >
          No thanks
        </DeclineFirstMoveButton>
      )}
      {storefrontDetails && !isUnclaimed && (
        <LightboxInquiryButton
          section="LIGHTBOX"
          position={1}
          data-testid="lightbox-inquiry-button"
          firstMoveUuid={firstMove?.uuid}
          variant="primary"
          colorway="cloudAlt"
          onClick={onClose}
        />
      )}

      {storefrontDetails && isUnclaimed && <UnclaimedListingButton closeLightbox={onClose} />}
    </Fragment>
  );
};
