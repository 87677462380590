import { useCallback, useEffect, useState } from 'react';

import { getSearchedLocation } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import { StorefrontMarketView, VendorMarketView } from '@zola/svc-marketplace-ts-types';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import _has from 'lodash/has';

import { getSearchLocationForSlug } from '~/libs/universal/vendorMarket';
import { LocationOrAllMarketsType } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';
import {
  removeSearchedLocationSlug,
  setSearchedLocation,
  getSearchedLocationSlug,
  isSearchLocation,
} from '~/util/searchUtils';

const useBreadcrumbLocation = (data: CouplesStorefrontDetails) => {
  const { markets, address } = data;
  const { homeMarkets, travelMarkets } = markets;
  const [recordedLocation, setRecordedLocation] = useState<
    LocationOrAllMarketsType | Record<string, never>
  >({});

  useEffectOnce(() => {
    const searchedLocationSlug = getSearchedLocationSlug();
    if (searchedLocationSlug) {
      getSearchLocationForSlug(searchedLocationSlug, ApiService)
        .then((response) => {
          if (response) {
            removeSearchedLocationSlug();
            setSearchedLocation(response);
            setRecordedLocation(response);
          }
        })
        .catch(() => null);
    } else {
      setRecordedLocation(getSearchedLocation() || {});
    }
  });

  const [selectedMarket, setSelectedMarket] = useState<VendorMarketView | null>(null);
  const [selectedMarketStateCode, setSelectedMarketStateCode] = useState<string | null>();
  const [nycDisplayHeaderText, setNYCDisplayHeaderText] = useState<string | null>(null);
  const [isVendorLocationSameAsSearch, setIsVendorLocationSameAsSearch] = useState(false);
  const [displayServiceMarket, setDisplayServiceMarket] = useState(false);
  const [displayTravelMarket, setDisplayTravelMarket] = useState(false);

  const findMarket = useCallback(
    (vendorMarkets: StorefrontMarketView[], findNYCMarket: boolean) => {
      const findNonNYCMarkets = ({ market }: StorefrontMarketView) =>
        market?.id === recordedLocation?.storefrontSearchPayload?.vendorMarketId;
      const findNYCMarkets = ({ market }: StorefrontMarketView) =>
        market?.label === 'New York City';

      return findNYCMarket
        ? vendorMarkets.find(findNYCMarkets)
        : vendorMarkets.find(findNonNYCMarkets);
    },
    [recordedLocation]
  );

  useEffect(() => {
    const vendorMarket: Partial<VendorMarketView> = {
      id: recordedLocation?.storefrontSearchPayload?.vendorMarketId,
    };

    if (isSearchLocation(recordedLocation)) {
      let isSame = false;
      if (recordedLocation.type === 'CITY') {
        isSame = address.possibleCitySlug === recordedLocation.slug;
      } else if (recordedLocation.type === 'MARKET') {
        const recordedMarketId = recordedLocation.storefrontSearchPayload?.vendorMarketId;
        isSame =
          homeMarkets.findIndex((homeMarket) => homeMarket.market.id === recordedMarketId) > -1;
      } else if (recordedLocation.type === 'STATE') {
        const recordedStateProvince = recordedLocation.storefrontSearchPayload?.stateProvince;
        isSame = address.stateProvince === recordedStateProvince;
      }
      setIsVendorLocationSameAsSearch(isSame);
    }

    /**
     * set a market based on the storefront's address
     *
     * this function is declared inside the useEffect hook
     * to avoid being re-declared on each render cycle
     * */
    const setMarketByAddress = () => {
      ApiService.post(
        '/web-marketplace-api/v1/vendor-market/verify-market-presence',
        {
          street: address.address1,
          city: address.city,
          state: address.stateProvince,
          zipcode: address.postalCode,
        },
        { cacheKey: [address.address1, address.city, address.stateProvince, address.postalCode] }
      )
        .then((response) => {
          if (response.length) {
            setSelectedMarket(response[0]);
          } else {
            // if that still doesn't give us a market then return a market
            // from the vendor's list of markets
            setSelectedMarket(homeMarkets[0].market);
          }
        })
        .catch(StandardLogFn);
    };

    const NEW_YORK_CITY_LABELS: Record<string, string> = {
      'bronx-ny': 'Bronx',
      'brooklyn-ny': 'Brooklyn',
      'queens-ny': 'Queens',
      'manhattan-ny': 'Manhattan',
    };

    // if the user came to this storefront from a SRP other than all locations...
    if (homeMarkets.length > 0 || travelMarkets.length > 0) {
      if (vendorMarket?.id && !isVendorLocationSameAsSearch) {
        /**
         * if recorded city is one of the five boroughs run a check here to match against NYC
         * that may be listed in standard or travel markets of given vendor and then list the recorded
         * city as a market the vendor serves or travels to in the Header section
         */
        if (recordedLocation?.slug && _has(NEW_YORK_CITY_LABELS, recordedLocation?.slug)) {
          const nycAsStandardMarket = findMarket(homeMarkets, true);
          const nycAsTravelMarket = findMarket(travelMarkets, true);

          if (nycAsStandardMarket) {
            setDisplayServiceMarket(true);
            setSelectedMarketStateCode(recordedLocation.storefrontSearchPayload?.stateProvince);
            setNYCDisplayHeaderText(NEW_YORK_CITY_LABELS[recordedLocation.slug]);
            return setSelectedMarket(nycAsStandardMarket.market);
          }

          if (nycAsTravelMarket) {
            setDisplayTravelMarket(true);
            setSelectedMarketStateCode(recordedLocation.storefrontSearchPayload?.stateProvince);
            setNYCDisplayHeaderText(NEW_YORK_CITY_LABELS[recordedLocation.slug]);
            return setSelectedMarket(nycAsTravelMarket.market);
          }
        }

        const matchedLocation = findMarket(homeMarkets, false);
        const matchedTravelLocation = findMarket(travelMarkets, false);

        // if the vendor market = the search market, return that.
        if (matchedLocation) {
          setDisplayServiceMarket(true);
          setSelectedMarketStateCode(recordedLocation.storefrontSearchPayload?.stateProvince);
          return setSelectedMarket(matchedLocation.market);
        }

        if (matchedTravelLocation) {
          setDisplayTravelMarket(true);
          setSelectedMarketStateCode(recordedLocation.storefrontSearchPayload?.stateProvince);
          return setSelectedMarket(matchedTravelLocation.market);
        }
      } else if (homeMarkets.length === 1) {
        // return the market from the vendor's list of markets if there's only one
        return setSelectedMarket(homeMarkets[0].market);
      }
    }

    // otherwise find their market based on their address
    return setMarketByAddress();
  }, [
    recordedLocation,
    homeMarkets,
    address,
    travelMarkets,
    isVendorLocationSameAsSearch,
    findMarket,
  ]);

  return {
    selectedMarket,
    selectedMarketStateCode,
    nycDisplayHeaderText,
    isVendorLocationSameAsSearch,
    isRecordedLocationPresent: isSearchLocation(recordedLocation),
    displayServiceMarket,
    displayTravelMarket,
    recordedLocation,
  };
};

export default useBreadcrumbLocation;
