import cx from 'classnames';

import { renderDefaultFavoriteButton } from '~/components/common/favorites/buttonShapes/renderDefaultFavoriteButton';
import { StorefrontFavoriteButton } from '~/components/common/favorites/StorefrontFavoriteButton';
import { FavoriteButtonVariant, RenderFavoriteButton } from '~/components/common/favorites/types';
import { useShowPostFavoriteOptIn } from '~/components/common/modals/InvitesFromVendorsOptIn/showPostFavoriteOptIn';
import useFavorites from '~/hooks/useFavorites';
import { TrackingProductLocation } from '~/util/trackingHelper';

import styles from './favoriteCTA.module.less';

type FavoriteCTAWrapperProps = {
  storefrontUuid: string;
  storefrontCoverUuid?: string;
};
type FavoriteCTAProps = FavoriteCTAWrapperProps & {
  variant?: FavoriteButtonVariant;
  buttonClassOverrides?: string;
};

export const FavoriteCTAWrapper = ({
  children,
  storefrontUuid,
  storefrontCoverUuid,
}: { children: RenderFavoriteButton } & FavoriteCTAWrapperProps) => {
  const { storefrontsLoaded, isGuest } = useFavorites(); // fetches favorites if not loaded

  const showPostFavoriteOptIn = useShowPostFavoriteOptIn();

  if (!isGuest && !storefrontsLoaded) {
    // guests can favorite photos, they just get logged in / signed up when they
    // do, otherwise, if they aren't a guest, we wait for the favorites to load before
    // showing any hearts.  For guests,  they'll always see the unFavorite button
    // which is hidden by default on desktop.
    return null;
  }

  return (
    <StorefrontFavoriteButton
      uuid={storefrontUuid}
      location={TrackingProductLocation.STOREFRONT_DETAIL_PAGE}
      vendorPhotoUuid={storefrontCoverUuid}
      afterFavoriteCallback={showPostFavoriteOptIn}
    >
      {children}
    </StorefrontFavoriteButton>
  );
};

export const FavoriteCTA = ({
  storefrontUuid,
  storefrontCoverUuid,
  buttonClassOverrides,
  variant,
}: FavoriteCTAProps) => {
  return (
    <FavoriteCTAWrapper storefrontUuid={storefrontUuid} storefrontCoverUuid={storefrontCoverUuid}>
      {renderDefaultFavoriteButton({
        buttonClassOverrides: cx(
          { [styles.favoriteCompact]: variant !== 'full' },
          buttonClassOverrides
        ),
        imageClassOverrides: styles.heartImg,
        variant: variant,
        type: 'VENDOR',
      })}
    </FavoriteCTAWrapper>
  );
};
