import cx, { Argument as ClassName } from 'classnames';
import { useDrag, useDrop } from 'react-dnd';

import './withDraggablePhoto.less';

interface WithDraggablePhotoProps {
  index: number;
  className?: ClassName;
  swapItems: (from: number, to: number) => void;
}

/**
 * Highorder component for wrapping a photo in a drag/drop.  For instance: re-ordering
 * cover photos or video galleries
 *
 * Provide a component that can be dragged and dropped, along with the item's index and
 * swap items function.
 *
 * @example
 * const DraggableCoverPhoto = WithDraggablePhoto(<THE WRAPPED COMPONENT>);
 *
 * <DndProvider backend={HTML5Backend}>
 *   <DraggableCoverPhoto
 *     index={0}
 *     swapItems={(from, to) => {...}}
 *     ... Additional props passed to the wrapped component
 *   />
 *   <DraggableCoverPhoto
 *     index={1}
 *     swapItems={(from, to) => {...}}
 *     ... Additional props passed to the wrapped component
 *   />
 * </DndProvider>
 *
 * @param {*} WrappedComponent
 */
const WithDraggablePhoto =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props: Props & WithDraggablePhotoProps) => {
    // eslint-disable-next-line react/prop-types
    const { index, swapItems, className } = props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'draggable-photo', index },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: 'draggable-photo',
      drop: (dropItem: { type: string; index: number }) => {
        if (dropItem.index !== index) {
          swapItems(index, dropItem.index);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    });

    const wrappedProps: Props & { className?: ClassName } = { ...props };
    delete wrappedProps.className;

    return (
      <div
        className={cx(className, 'draggable-photo-wrapper draggable-photo-drop-target', {
          'drop-active': isOver && canDrop,
        })}
        ref={drop}
      >
        <div
          ref={drag}
          className={cx('draggable-photo-drag-handle', { 'drag-active': isDragging })}
        >
          <WrappedComponent {...wrappedProps} />
        </div>
      </div>
    );
  };

export default WithDraggablePhoto;
