import { DrawerV2 } from '@zola/zola-ui/src/components/Drawer';

import DesktopServiceMatrix, { DesktopServiceMatrixProps } from './DesktopServiceMatrix';

import styles from './desktopServices.module.less';

export const DesktopServices = (props: DesktopServiceMatrixProps) => (
  <div className={styles.desktopServices}>
    <DrawerV2
      buttonText={{
        open: 'Show Package Info',
        close: 'See Less',
        appearance: 'button',
      }}
      expandedByDefault={false}
      maxHeight={300}
    >
      <DesktopServiceMatrix {...props} />
    </DrawerV2>
  </div>
);

export default DesktopServices;
