import { Fragment } from 'react';

import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import { TrashIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Trash';

import cx from 'classnames';

import styles from './editButtonBlock.module.less';

type EditButtonBlockProps = {
  cancelFunc: () => void;
  deleteFunc?: ((id: number) => void) | null;
  deleteCopy?: string;
  saving: boolean;
  pristine: boolean | null;
  isValid?: boolean | undefined;
  className?: string;
  saveCopyOverride?: string;
  saveFunc?: () => void;
  name?: string | string[];
};

const EditButtonBlock = (props: EditButtonBlockProps) => {
  const {
    className,
    cancelFunc,
    deleteFunc,
    deleteCopy,
    saving,
    isValid = true,
    pristine,
    saveCopyOverride,
    saveFunc,
    name,
  } = props;

  const classes = cx(styles.buttonBlock, className);

  const saveButtonCopy = saveCopyOverride || 'Save';

  return (
    <div className={classes} style={{ zIndex: 1 }}>
      <div>
        {deleteFunc && (
          <Fragment>
            <ButtonV2
              variant="tertiary"
              onClick={deleteFunc}
              disabled={saving}
              className={styles.deleteButton}
            >
              <TrashIcon width={24} height={24} />
              {deleteCopy || 'Delete'}
            </ButtonV2>
          </Fragment>
        )}
      </div>
      <div className={styles.saveAndCancel}>
        <ButtonV2 variant="secondary" onClick={cancelFunc} disabled={saving}>
          Cancel
        </ButtonV2>
        <ButtonV2
          disabled={!isValid || saving || pristine}
          data-testid={`${name}-save-button`}
          type="submit"
          onClick={saveFunc && typeof saveFunc === 'function' && saveFunc}
        >
          {saveButtonCopy}
        </ButtonV2>
      </div>
    </div>
  );
};

export default EditButtonBlock;
