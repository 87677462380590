import React, { Fragment } from 'react';

import {
  VendorTaxonomyKey,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { StorefrontVideoGalleryView } from '@zola/svc-marketplace-ts-types';
import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import cx from 'classnames';

import { editVideo, deleteVideo } from '~/actions/vendors/vendorStorefrontVideoActions';
import { useModal } from '~/contexts/ModalContext';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getVideoGallery } from '~/reducers/entities/videoGalleryReducer';
import { getImageUrl } from '~/util/imageUtils';
import { getVendorIcon } from '~/util/vendorUtils';

import { getVideoTypeOptions } from './VideoEditForm';

import './galleryCard.less';

const cardImage = getVendorIcon(VIDEOGRAPHERS_TAXONOMY_KEY);

type AddVideoCardContentProps = {
  index: number;
};

/* index is used to target where in the list the video will added */
const AddVideoCardContent = ({ index }: AddVideoCardContentProps) => {
  const dispatch = useAppDispatch();
  const doCreateNewVideo: React.MouseEventHandler = (event) => {
    event.preventDefault();
    if (window) {
      window.scrollTo(0, 0);
    }
    dispatch(editVideo(index));
  };

  return (
    <ButtonV2 onClick={doCreateNewVideo}>
      <span className="zola-ui-icon-plus" />
      Add Video
    </ButtonV2>
  );
};

type CardContentProps = {
  canEdit?: boolean;
  canPlay?: boolean;
  index: number;
  video: StorefrontVideoGalleryView;
};

const CardContent = ({ video, index, canEdit, canPlay }: CardContentProps) => {
  const dispatch = useAppDispatch();
  const { showV2PredefinedModal } = useModal();
  const doEditVideo: React.MouseEventHandler = (event) => {
    event.preventDefault();
    if (window) {
      window.scrollTo(0, 0);
    }
    dispatch(editVideo(index));
  };

  const doDeleteVideo: React.MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(deleteVideo(index));
  };

  const doPlayVideo: React.MouseEventHandler = (event) => {
    event.preventDefault();
    showV2PredefinedModal<{
      className: string;
      videoId: string | null;
      title: string | null;
      videoHost: string | null;
    }>(
      'VIDEO_MODAL',
      { dialogHeadingId: 'video-modal', triggerRef: null },
      {
        className: 'video-gallery-player',
        videoId: video.hostId,
        title: video.title,
        videoHost: video.host,
      }
    );
  };

  if (video) {
    const src = getImageUrl(video.thumbnail.imageId as string);
    return (
      <Fragment>
        <img className="video-thumbnail" src={src} alt="video thumbnail" />
        {canPlay && (
          <div className="video-gallery__video-gallery-card-controls-container">
            <button type="button" onClick={doPlayVideo}>
              <span className="zola-ui-icon-play-2" />
            </button>
          </div>
        )}
        {canEdit && (
          <div className="video-gallery__video-gallery-card-button-container">
            <button type="button" onClick={doEditVideo}>
              <span className="zola-ui-icon-pencil" />
            </button>

            <button type="button" onClick={doDeleteVideo}>
              <span className="zola-ui-icon-trash" />
            </button>
          </div>
        )}
      </Fragment>
    );
  }
  return <img src={cardImage} className="placeholder-image" alt="video" />;
};

type GalleryCardProps = {
  index: number;
  video: StorefrontVideoGalleryView;
  className?: string;
  canEdit?: boolean;
  canPlay?: boolean;
  taxonomyKey: VendorTaxonomyKey;
};

const GalleryCard = (props: GalleryCardProps) => {
  const { index, video, className = '', canEdit = false, canPlay = true, taxonomyKey } = props;

  // Current video gallery
  const videoGallery = useAppSelector(getVideoGallery) || [];
  const count = videoGallery.length;
  const showTwoUploadCards = count === 0 && index < 2;
  const showOneUploadCard = count > 0 && index === count;
  const uploadEnabled = canEdit && !video && (showTwoUploadCards || showOneUploadCard);
  const uploadRequired = uploadEnabled && count < 2;

  const typeLookup: Record<string, string> = getVideoTypeOptions(taxonomyKey).reduce(
    (lookup, option) => {
      return {
        ...lookup,
        [option.value]: option.title,
      };
    },
    {}
  );
  const videoType = video ? typeLookup[video.type] || video.type : null;

  const classes = cx('video-gallery__video-gallery-card', className, {
    'video-gallery__video-gallery-card-upload-enabled': uploadEnabled,
    'video-gallery__video-gallery-card-upload-required': uploadRequired,
    'video-gallery__video-gallery-card-placeholder': !uploadEnabled && !video,
  });
  return (
    <div className={classes}>
      <div className="video-thumbnail-wrapper">
        <div className="video-thumbnail-content">
          {uploadEnabled ? (
            /* index will affect where new video ends up, so bind to array length */
            <AddVideoCardContent index={videoGallery.length} />
          ) : (
            <CardContent index={index} video={video} canEdit={canEdit} canPlay={canPlay} />
          )}
        </div>
      </div>
      {/* This is here for all cards so we have the right spacing */}
      <div className="video-meta">
        <P.BodySmall className="video-title" title={video ? video.title : undefined}>
          {video ? video.title : 'Fake Title'}
        </P.BodySmall>
        <div className="video-type">{videoType || 'Fake Type'}</div>
      </div>
    </div>
  );
};

export default GalleryCard;
