import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { JumpMenuSectionIds } from '../helpers/jumpMenu';

export { JumpMenuSectionIds };

type JumpMenuSectionProps = {
  sectionName: JumpMenuSectionIds;
  className?: string;
  id?: string;
};

const JumpMenuSection: React.FC<JumpMenuSectionProps> = ({
  sectionName,
  className,
  id,
  children,
}) => {
  const { addJumpMenuItem } = useStorefrontDetails();

  return (
    <div className={className} id={id}>
      {children}
    </div>
  );
};

export default JumpMenuSection;
