import { RefObject, useMemo, useRef } from 'react';

import { FormApi } from 'final-form';
import type { History } from 'history';
import reduce from 'lodash/reduce';

import Logger from '~/util/logger';

const listingUrl = '/inspire/vendors/listing';

export const cancel = (history: History, returnToInquiryUrl?: string | null) => {
  history.push(returnToInquiryUrl || listingUrl);
};

const handleErrors = <FormValues>(form: FormApi<FormValues>, ref?: RefObject<HTMLElement>) => {
  const { errors } = form.getState();
  const syncErrors = errors ? Object.keys(errors) : [];
  // Finds the first valid id
  const showError = syncErrors.reduce<HTMLElement | null>((accumulator, error) => {
    return accumulator || document.getElementById(error) || document.getElementsByName(error)[0];
  }, null);
  if (showError) {
    // y Grabs the error and offsets the page to account for the height of the Nav and field label
    const y = showError.getBoundingClientRect().top + window.scrollY - 100;
    (ref?.current || window).scrollTo({ top: y, behavior: 'smooth' });
  }
};

/**
 * Scrolls to the first error in the form as long as the id for the field matches the field key
 */
export const scrollToErrorDecorator = <FormValues>(
  form: FormApi<FormValues>,
  ref?: RefObject<HTMLElement>
) => {
  const unsubscribe = form.subscribe(() => undefined, { errors: true });
  const originalSubmit = form.submit;
  // eslint-disable-next-line no-param-reassign
  form.submit = async () => {
    try {
      if (!form.getState().valid) {
        handleErrors(form, ref);
      }
      await originalSubmit();
    } catch (error) {
      Logger.error(error);
    }
    return form.getState().values;
  };
  return unsubscribe;
};

const scrollToErrorDecoratorWithRef =
  (ref: RefObject<HTMLElement>) =>
  <FormValues>(form: FormApi<FormValues>) =>
    scrollToErrorDecorator(form, ref);

export const useScrollToErrorDecorator = () => {
  const scrollRef = useRef(null);
  const memoizedScrollToErrorDecorator = useMemo(
    () => scrollToErrorDecoratorWithRef(scrollRef),
    []
  );
  return { scrollRef, scrollToErrorDecorator: memoizedScrollToErrorDecorator };
};

/**
 * Create a record of the selected options for use in lists of selected items in React Final Form Components.
 * An optional predicate can be passed to choose which options are selected.
 *
 * @example createIsSelectedRecord([{key: "value"}]) // {"value": true}
 */
export const createIsSelectedRecord = <T extends { key: string }>(
  options: T[],
  isSelected?: (option: T) => boolean
): Record<string, boolean> =>
  reduce<T, Record<string, boolean>>(
    options,
    (acc, el) => {
      acc[el.key] = isSelected ? isSelected(el) : true;
      return acc;
    },
    {}
  );

export const getSelectedKeys = (values: Record<string, boolean>): string[] =>
  reduce<Record<string, boolean>, string[]>(
    values,
    (acc, selected, k) => {
      if (selected) acc.push(k);
      return acc;
    },
    []
  );

export const formatCents = (value: number) => {
  return value ? value / 100 : undefined;
};

export const parseCents = (value: string) => {
  return value ? parseInt(value, 10) * 100 : null;
};
