import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import style from './vendorStorefrontSection.module.less';

interface VendorStorefrontSectionProps {
  hed: string;
  dek?: string;
  children: React.ReactNode;
  className?: string;
  id?: string;
}
const VendorStorefrontSection = (props: VendorStorefrontSectionProps): JSX.Element => {
  const { hed, dek, children, className, id } = props;

  return (
    <section className={cx(className, style.section, 'storefront__section')} id={id}>
      <div className="container">
        <hr />
        <div className={style.header}>
          <div>
            <H.Title2 presentation="h4" strong>
              {hed}
            </H.Title2>
          </div>
          <div className={style.dek}>{dek}</div>
        </div>
        <div className={style.content}>{children}</div>
      </div>
    </section>
  );
};
export default VendorStorefrontSection;
