import { Fragment, useRef, useState, useEffect } from 'react';

import {
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  BANDS_DJS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { StorefrontPackageView } from '@zola/svc-marketplace-ts-types';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import cx from 'classnames';

import { centsToDollarsWithCommas, centsToDollars } from '~/util/priceConversion';
import { formatRange } from '~/util/rangeUtils';
import { formatAsCurrency } from '~/util/textUtils';

import styles from './packageCardContent.module.less';

const asCurrency = (value: string) => `$${value}`;

export interface PackageCardContentProps {
  onClick: () => void;
  packageData: Omit<StorefrontPackageView, 'id' | 'displayOrder'>;
  vendorType: SearchableVendorTaxonomyKey;
  isPreview?: boolean;
  showMore?: boolean;
  setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
}

const PackageCardContent = ({
  packageData,
  onClick,
  vendorType,
  isPreview,
  showMore,
  setSeeMore,
}: PackageCardContentProps): JSX.Element => {
  const descriptionCopy =
    'Enter in a description of your package in the field to the left. You’ll see it appear here as you type.';
  const nameCopy = 'Name of Package';
  const {
    name = nameCopy,
    description = descriptionCopy,
    offPeakStartPriceCents,
    offPeakEndPriceCents,
    peakStartPriceCents,
    peakEndPriceCents,
    guestCountMin,
    guestCountMax,
    packageItems,
    hours,
  } = packageData;

  const [truncation, setTruncation] = useState<boolean | undefined>(false);

  const descriptionHeight = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardHeight, setCardHeight] = useState(false);

  useForceUpdateOnWindowResize();

  useEffect(() => {
    const descriptionLineHeight = 20;

    if (descriptionHeight.current && !truncation && !cardHeight) {
      const descriptionRows = descriptionHeight.current?.offsetHeight / descriptionLineHeight;
      setCardHeight((cardRef?.current?.offsetHeight || 0) > 320);
      setTruncation(descriptionRows > 4);
      setSeeMore(true);
    }
  }, [truncation, cardHeight, setSeeMore, packageData]);

  const guestCapacity = formatRange(guestCountMin, guestCountMax);

  const peakPriceRange = formatRange(
    peakStartPriceCents,
    peakEndPriceCents,
    centsToDollarsWithCommas,
    asCurrency
  );

  const offPeakPriceRange = formatRange(
    offPeakStartPriceCents,
    offPeakEndPriceCents,
    centsToDollarsWithCommas,
    asCurrency
  );

  const price = centsToDollars(peakStartPriceCents);

  const packageMap =
    packageItems &&
    packageItems.map((packageItem, index: number) => <li key={index}>{packageItem.itemText}</li>);

  const whatsIncludedBlock = () => {
    return packageItems && packageItems.length > 0 ? (
      <Fragment>
        <div className={styles.included}>What’s included</div>
        <div className={styles.includedItems}>
          <ul>{packageMap}</ul>
        </div>
      </Fragment>
    ) : (
      <Fragment />
    );
  };

  const showTruncation = !isPreview && truncation && true;

  return (
    <div
      className={cx(styles.packageCardContent, isPreview && styles.preview)}
      onClick={() => !isPreview && onClick()}
      onKeyPress={() => !isPreview && onClick()}
    >
      <div ref={cardRef} className={styles.body}>
        <H.TitleAccent3
          className={cx(
            styles.name,
            'mb-tertiary',
            name === nameCopy && styles.placeholderColor,
            !isPreview && 'pt-tertiary'
          )}
          presentation="h4"
        >
          {name}
        </H.TitleAccent3>

        {[VENUES_TAXONOMY_KEY].includes(vendorType) && guestCapacity ? (
          <P.BodyBase
            className={cx(styles.semiBold, styles.marginTop, 'mb-tertiary')}
            color="BLACK_050"
          >
            {guestCapacity} guests
          </P.BodyBase>
        ) : (
          <Fragment />
        )}

        {hours &&
        [PHOTOGRAPHERS_TAXONOMY_KEY, VIDEOGRAPHERS_TAXONOMY_KEY].includes(vendorType) &&
        hours > 0 ? (
          <P.BodyBase
            className={cx(styles.semiBold, styles.marginTop, 'mb-tertiary')}
            color="BLACK_075"
          >
            {hours} hours
          </P.BodyBase>
        ) : (
          <Fragment />
        )}

        {[VENUES_TAXONOMY_KEY, PHOTOGRAPHERS_TAXONOMY_KEY, VIDEOGRAPHERS_TAXONOMY_KEY].includes(
          vendorType
        ) && peakPriceRange ? (
          <P.BodySmall className={styles.marginTop} color="BLACK_075">
            Peak pricing {peakPriceRange}
          </P.BodySmall>
        ) : (
          <Fragment />
        )}

        {[VENUES_TAXONOMY_KEY, PHOTOGRAPHERS_TAXONOMY_KEY, VIDEOGRAPHERS_TAXONOMY_KEY].includes(
          vendorType
        ) && offPeakPriceRange ? (
          <P.BodySmall className={styles.marginTop} color="BLACK_075">
            Off-peak pricing {offPeakPriceRange}
          </P.BodySmall>
        ) : (
          <Fragment />
        )}

        {[
          FLORISTS_TAXONOMY_KEY,
          CATERING_TAXONOMY_KEY,
          CAKES_DESSERTS_TAXONOMY_KEY,
          BANDS_DJS_TAXONOMY_KEY,
          HAIR_MAKEUP_TAXONOMY_KEY,
          BAR_SERVICES_TAXONOMY_KEY,
        ].includes(vendorType) && price ? (
          <P.BodySmall className={cx(styles.marginTop, 'mt-tertiary')} color="BLACK_075">
            <span className={styles.price}>Price</span>
            {formatAsCurrency(price)}
          </P.BodySmall>
        ) : (
          <Fragment />
        )}

        <div className={styles.description}>
          <div className={styles.semiBold}>Description</div>
          <div
            ref={descriptionHeight}
            className={cx(
              styles.descriptionText,
              'mt-tertiary',
              showTruncation && styles.truncate,
              isPreview && showMore && styles.truncate,
              description === descriptionCopy ? styles.placeholderColor : ''
            )}
          >
            <P.BodySmall>{description}</P.BodySmall>
          </div>
        </div>
        {whatsIncludedBlock()}
      </div>
    </div>
  );
};

export default PackageCardContent;
