import { useState, useEffect, useCallback } from 'react';

import { getSearchLocationForBriefLocation } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import cx from 'classnames';

import { useSearchedLocation } from '~/contexts/SearchContext';
import {
  PartialVendorSearch,
  PartialTaxonomyNode,
} from '~/pages/couples/explore/components/SearchResults/types/types';
import createCanonicalUrl from '~/pages/couples/explore/util/canonicalUrl';
import { LocationOrAllMarketsType } from '~/types/responseTypes';
import { ALL_MARKETS } from '~/util/searchUtils';

import { NotNullMembersBriefLocation } from '../../util/types';
import { InlineSearchBar } from '../SearchBar/InlineSearchBar';
import StackedSearchBar from '../SearchBar/StackedSearchBar';

import styles from './overlaySearchBar.module.less';

const DEFAULT_TAXONOMY_NODE: PartialTaxonomyNode = {
  key: VENUES_TAXONOMY_KEY,
  label: 'Venues',
};

type OverlaySearchBarProps = {
  weddingLocation?: NotNullMembersBriefLocation;
  defaultTaxonomyNode?: PartialTaxonomyNode;
  includeSavedSearch?: boolean;
  className?: string;
  verticalStacked?: boolean;
  isClpRedesign?: boolean;
  isClpTest?: boolean;
};

/**
 * This little fucker gives back the react props for an StackedSearchBar
 * or an InlineSearchBar that requires a click to navigate.  For example,
 * on the category landing page or landing page, you have to pick a
 * category and location, the explicitly click search.  Thats different
 * than the SRP where changing a dropdown is an immediate page update/search
 */
export const useSearchBarRequiringClickProps = (props: {
  weddingLocation?: NotNullMembersBriefLocation;
  defaultTaxonomyNode?: PartialTaxonomyNode;
}) => {
  const { weddingLocation, defaultTaxonomyNode = DEFAULT_TAXONOMY_NODE } = props;

  const { searchedLocation, setSearchedLocation } = useSearchedLocation();

  const [selectedLocation, setSelectedLocation] = useState<LocationOrAllMarketsType | undefined>(
    searchedLocation || undefined
  );

  const [selectedTaxonomyNode, setSelectedTaxonomyNode] = useState<PartialTaxonomyNode | null>(
    defaultTaxonomyNode
  );

  const updateSelectedLocation = useCallback(
    (location: LocationOrAllMarketsType) => {
      setSelectedLocation(location);
      setSearchedLocation(location);
    },
    [setSearchedLocation]
  );

  useEffect(() => {
    if (defaultTaxonomyNode) {
      setSelectedTaxonomyNode(defaultTaxonomyNode);
    }
  }, [defaultTaxonomyNode]);

  useEffect(() => {
    if (weddingLocation && !selectedLocation) {
      setSelectedLocation(getSearchLocationForBriefLocation(weddingLocation));
    }
  }, [weddingLocation, selectedLocation]);

  return {
    selectedTaxonomyNode,
    setSelectedTaxonomyNode,
    selectedLocation: selectedLocation || ALL_MARKETS,
    updateSelectedLocation,
    navigateToSrp: (req: PartialVendorSearch) => {
      window.location.assign(createCanonicalUrl(req));
    },
  };
};

export const OverlaySearchBar: React.FC<OverlaySearchBarProps> = (props) => {
  const {
    weddingLocation,
    defaultTaxonomyNode = DEFAULT_TAXONOMY_NODE,
    verticalStacked = false,
    includeSavedSearch = false,
    className,
    isClpRedesign,
    isClpTest,
  } = props;

  const searchBarInteractionProps = useSearchBarRequiringClickProps({
    weddingLocation,
    defaultTaxonomyNode,
  });

  const searchBarProps = {
    ...searchBarInteractionProps,
    includeSavedSearch,
    isClpRedesign,
    isClpTest,
  };

  const SearchBarComponent = verticalStacked ? StackedSearchBar : InlineSearchBar;

  return (
    <div
      className={cx(
        styles.overlaySearchBar,
        {
          [styles.verticallyStacked]: verticalStacked,
        },
        className
      )}
    >
      <SearchBarComponent {...searchBarProps} />
    </div>
  );
};
