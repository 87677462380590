import { useMemo, Fragment } from 'react';

import {
  BANDS_DJS_TAXONOMY_KEY,
  VendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  StorefrontVideoGalleryThumbnailView,
  StorefrontVideoGalleryView,
} from '@zola/svc-marketplace-ts-types';
import { FormInput } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import { RenderField } from '@zola/zola-ui/src/components/Form/RenderField';
import { RenderRadioField } from '@zola/zola-ui/src/components/Form/RenderRadioField';

import { Form, Field } from 'react-final-form';

import { saveVideoGalleryEntry, cancelEdit } from '~/actions/vendors/vendorStorefrontVideoActions';
import * as Validations from '~/components/common/form/commonValidations';
import { FormDropdown } from '~/components/common/form/FinalForm/Inputs';
import { useAppSelector, useAppDispatch } from '~/reducers';
import { getVendorTaxonomyKey } from '~/selectors/vendorStorefrontSelectors';
import { StorefrontLinkTypeEnum } from '~/types/responseTypes';
import { getImageUrl } from '~/util/imageUtils';
import { getVideoIdFromUrl } from '~/util/linkUtils';
import Logger from '~/util/logger';

import { scrollToErrorDecorator } from '../../editPages/helpers/formHelpers';
import EditButtonBlock from '../EditButtonBlock';
import SingleImageUpload from '../SingleImageUpload';

import './videoEditForm.less';

const MUSICIAN_VIDEO_TYPE_OPTIONS = [
  { title: 'Ceremony', value: 'CEREMONY' },
  { title: 'Reception', value: 'RECEPTION' },
  { title: 'After Party', value: 'AFTER_PARTY' },
  { title: 'Live Show', value: 'LIVE_SHOW' },
];

const VIDEOGRAPHER_VIDEO_TYPE_OPTIONS = [
  { title: 'Trailer', value: 'TRAILER' },
  { title: 'Highlight Reel', value: 'HIGHLIGHT_REEL' },
  { title: 'Short Feature', value: 'SHORT_FEATURE' },
  { title: 'Long Feature', value: 'LONG_FEATURE' },
];

export const getVideoTypeOptions = (vendorTaxonomyKey: VendorTaxonomyKey) => {
  const options =
    vendorTaxonomyKey === BANDS_DJS_TAXONOMY_KEY
      ? MUSICIAN_VIDEO_TYPE_OPTIONS
      : VIDEOGRAPHER_VIDEO_TYPE_OPTIONS;

  return options.map((option) => ({ ...option, label: option.title }));
};

type VideoHosts = 'VIMEO' | 'YOUTUBE';
type ThumbnailImage = StorefrontVideoGalleryThumbnailView & { imageUrl: string; newPhoto: boolean };

interface FormValues {
  videoHost: VideoHosts;
  hostId: string;
  videoType: string;
  videoTitle: string;
  thumbnail: ThumbnailImage;
}

const videoHosts: Record<VideoHosts, { name: string; url: string }> = {
  VIMEO: {
    name: 'Vimeo',
    url: 'https://www.vimeo.com/',
  },
  YOUTUBE: { name: 'YouTube', url: 'https://youtu.be/' },
} as const;

type VideoEditFormProps = {
  video?: StorefrontVideoGalleryView | null;
};

interface VideoEditFormOwnProps {
  index: number | null;
  storefrontUuid: string;
  video: StorefrontVideoGalleryView | null;
}

const VideoEditForm = ({
  video = null,
  index,
  storefrontUuid,
}: VideoEditFormProps & VideoEditFormOwnProps) => {
  const dispatch = useAppDispatch();
  const videoTypeOptions = getVideoTypeOptions(useAppSelector(getVendorTaxonomyKey));

  const addVideoToInMemoryGallery = async ({
    videoHost,
    hostId,
    videoTitle,
    videoType,
    thumbnail,
  }: FormValues) => {
    const videoId = getVideoIdFromUrl(StorefrontLinkTypeEnum[videoHost], hostId);
    const entry = {
      host: videoHost,
      hostId: videoId,
      title: videoTitle,
      type: videoType,
      thumbnail,
      storefrontUuid,
      index,
    };

    await dispatch(saveVideoGalleryEntry(entry)).catch(Logger.error);
  };

  const initialValues: Partial<FormValues> = useMemo(() => {
    return {
      hostId: video?.hostId,
      videoHost: video?.host as VideoHosts,
      videoTitle: video?.title,
      videoType: video?.type,
      thumbnail: video?.thumbnail && {
        ...video.thumbnail,
        imageUrl: getImageUrl(video.thumbnail.imageId) as string,
        newPhoto: false,
      },
    };
  }, [video]);

  return (
    <Fragment>
      <Form<FormValues>
        initialValues={initialValues}
        decorators={[scrollToErrorDecorator]}
        onSubmit={addVideoToInMemoryGallery}
        render={({ pristine, handleSubmit, values, valid, submitting, form }) => {
          return (
            <form id="marketplace-vendors-storefront-edit-video" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-xs-12 video-thumbnail">
                  <div className="video-thumbnail-wrapper">
                    <Field
                      name="thumbnail"
                      validate={Validations.REQUIRED}
                      id="thumbnail"
                      render={() => (
                        <SingleImageUpload
                          className="video-thumbnail-content"
                          ctaCopy="Upload"
                          image={values.thumbnail}
                          setImage={(image: ThumbnailImage) => {
                            form.change('thumbnail', image);
                          }}
                          sectionTitle="video-gallery"
                          showEditButton
                          instructionCopy={
                            <span>
                              Upload a thumbnail for your video link
                              <span className="text-danger danger-asterisk">*</span>
                              <br />
                              16:9 aspect ratios work best
                            </span>
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 video-form">
                  <div className="video-host">
                    <div className="video-host-label">
                      <span className="hidden-sm">Video </span>Format
                      <span className="text-danger danger-asterisk">*</span>
                    </div>
                    <Field
                      label="Vimeo"
                      name="videoHost"
                      id="vimeo"
                      type="radio"
                      value="VIMEO"
                      required
                      inline
                      disabled={submitting}
                    >
                      {(props) => <RenderRadioField {...props} type="radio" />}
                    </Field>
                    <Field
                      label="YouTube"
                      name="videoHost"
                      id="youtube"
                      type="radio"
                      value="YOUTUBE"
                      required
                      inline
                      disabled={submitting}
                    >
                      {(props) => <RenderRadioField {...props} type="radio" />}
                    </Field>
                  </div>

                  <div
                    className="host-id"
                    style={{ visibility: values.videoHost ? 'initial' : 'hidden' }}
                  >
                    <div className="host-id-label">
                      {values.videoHost && videoHosts[values.videoHost].url}
                      <span className="text-danger danger-asterisk">*</span>
                    </div>
                    <Field
                      name="hostId"
                      type="text"
                      id="host-id"
                      required
                      disabled={submitting}
                      validate={Validations.REQUIRED}
                    >
                      {(fieldRenderProps) => <RenderField type="text" {...fieldRenderProps} />}
                    </Field>
                  </div>

                  <div className="video-type">
                    <FormDropdown
                      name="videoType"
                      id="video-type"
                      selectText="Select Type"
                      options={videoTypeOptions}
                      required
                      disabled={submitting}
                      label="Type of Video"
                      validate={Validations.REQUIRED}
                    />
                  </div>

                  <div className="video-title">
                    <FormInput
                      name="videoTitle"
                      type="text"
                      id="video-title"
                      required
                      disabled={submitting}
                      label="Title of Video"
                      validate={Validations.REQUIRED}
                    />
                  </div>
                </div>
              </div>

              <EditButtonBlock
                saving={submitting}
                cancelFunc={() => dispatch(cancelEdit())}
                isValid={valid}
                pristine={pristine}
                saveCopyOverride={video ? 'Save Changes' : 'Add Video'}
              />
            </form>
          );
        }}
      />
    </Fragment>
  );
};

export default VideoEditForm;
