import Example1x from '~/assets/images/vendorStorefront/video-gallery/Videographer_Edit_Listing_Example@1x.png';
import Example2x from '~/assets/images/vendorStorefront/video-gallery/Videographer_Edit_Listing_Example@2x.png';

import ListingExample from '../ListingExample';

const Example = () => {
  return (
    <ListingExample
      alt="Example video gallery"
      example1x={Example1x}
      example2x={Example2x}
      size="lg"
    />
  );
};

export default Example;
