import { RefObject, useEffect, useMemo, useRef } from 'react';

export const onStickyScroll = (onShow: () => void, onHide: () => void, height?: number): void => {
  const windowHeight = window.innerHeight;

  const requiredScroll = height ?? windowHeight / 2;
  if (window.pageYOffset >= requiredScroll) {
    onShow();
  } else {
    onHide();
  }
};

export type StickyScrollProps = {
  /**
   * how far the page must be scrolled be before the visibility class is applied.
   * Default is half the page.  If this is 200px, then the scroll offset has to
   * be more than 200px. To show the sticky on the first scroll, set a height of 0 */
  height?: number;
  /** class to apply when the scroll conditions are met. default is 'show' */
  showClassName?: string;
  /** class to apply when the scroll conditions are no longer met. default is 'hide' */
  hideClassName?: string;
  stickyBtn?: RefObject<HTMLDivElement>;
};

/**
 * Toggles visibility classes for a sticky button element on the page
 * as the page scrolls
 *
 */
const handleStickyScroll = ({
  height,
  showClassName = 'show',
  hideClassName = 'hide',
  stickyBtn,
}: StickyScrollProps = {}): void => {
  onStickyScroll(
    () => {
      if (!stickyBtn?.current) return;
      const classes = stickyBtn.current.classList;

      classes.add(showClassName);
      classes.remove(hideClassName);
    },
    () => {
      if (!stickyBtn?.current) return;
      const classes = stickyBtn.current.classList;

      classes.add(hideClassName);
      classes.remove(showClassName);
    },
    height
  );
};

type UseStickyScrollProps = StickyScrollProps & {
  showStickyButton: boolean;
};
export const useStickyScroll = (props: UseStickyScrollProps) => {
  const stickyBtn = useRef<HTMLDivElement>(null);

  const { showStickyButton, ...rest } = props;

  const eventListener = useMemo(() => {
    return () => handleStickyScroll({ ...rest, stickyBtn });
  }, [rest]);

  useEffect(() => {
    if (showStickyButton) {
      window.addEventListener('scroll', eventListener);
    }
    return () => {
      window.removeEventListener('scroll', eventListener);
    };
  });

  return stickyBtn;
};

export default handleStickyScroll;
