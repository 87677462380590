import { Fragment } from 'react';

import { BANDS_DJS_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { pluralize } from '@zola-helpers/client/dist/es/transformers';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import T from '@zola/zola-ui/src/typography/Text';

import ReactToolTip from 'react-tooltip';

import { getServicesOfferedMetaForVendorTaxonomyKey } from '~/meta/services';
import { MappedOptionFacetView } from '~/types/responseTypes';
import { CouplesStorefrontDetailsMusician } from '~/types/storefrontDetails';
import { formatRange } from '~/util/rangeUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

export const MusicianOverview = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsMusician>();
  const { musicianDetails, options } = storefrontDetails;
  const { actSizeMin, actSizeMax } = musicianDetails;
  const musicians = [
    'musicianType',
    'musicDjs',
    'musicSolo',
    'musicBand',
    'musicCategory',
    'musicGenres',
  ];

  const findMusicianByFormKey = (formKey: string) => {
    return (servicesMetaData.find((meta) => meta.formKey === formKey) || {}).parentKey;
  };

  const musicianExists = (id: string) => {
    return options.filter((option) => option.parentKey === id); // eslint-disable-line react/destructuring-assignment
  };

  const servicesMetaData = getServicesOfferedMetaForVendorTaxonomyKey(BANDS_DJS_TAXONOMY_KEY);
  const musicianParentKey = musicians.map((parentKey) => findMusicianByFormKey(parentKey));
  const musicianTypes =
    musicianParentKey.map((musician) => musician && musicianExists(musician)) || [];

  const isGenre = (musician: MappedOptionFacetView) => musician.parentKey === 'music-genre';
  const isCeremonyOnly = (musician: MappedOptionFacetView) =>
    musician.parentKey === 'musician-category';

  const genreAndCeremonyOnlyBlock = (musician: MappedOptionFacetView[]) => (
    <Fragment>
      {/* Genre */}
      {musician.length <= 3 ? (
        <div className="overview__block mt-tertiary">
          <P.BodySmall className="mr-quaternary inlineText">
            <T.Strong>{isGenre(musician[0]) ? `Genres: ` : `Type: `}</T.Strong>
            {musician.map((genre: MappedOptionFacetView, idx: number) => (
              <span key={genre.id}>
                {genre.name}
                {idx !== musician.length - 1 && ', '}
              </span>
            ))}
          </P.BodySmall>
        </div>
      ) : (
        <div className="overview__block">
          <P.BodySmall className="mr-quaternary inlineText">
            <T.Strong>{isGenre(musician[0]) ? `Genres: ` : `Type: `}</T.Strong>
            <span>{musician[0].name}</span>
          </P.BodySmall>
          <Fragment>
            <ReactToolTip id={musician[0].parentKey} aria-haspopup="true">
              <ul>
                {musician.map(
                  (genre: MappedOptionFacetView, idx: number) =>
                    idx > 0 && <li key={genre.id}>{genre.name}</li>
                )}
              </ul>
            </ReactToolTip>
            <div
              className="overview__block overview__markets ml-tertiary"
              data-tip
              data-for={musician[0].parentKey}
              data-effect="solid"
            >
              +{musician.length - 1}{' '}
              {isGenre(musician[0])
                ? `${pluralize('genre', 'genres', musician.length - 1, false)}`
                : `more`}
            </div>
          </Fragment>
        </div>
      )}
    </Fragment>
  );

  const overviewBlock = (musician: MappedOptionFacetView[], index: number) => (
    <Fragment key={index}>
      {isGenre(musician[0]) || (isCeremonyOnly(musician[0]) && musician.length > 1) ? (
        genreAndCeremonyOnlyBlock(musician)
      ) : (
        <Fragment>
          {/* Type and Category */}
          <div className="overview__block mt-tertiary">
            <P.BodySmall className="mr-quaternary inlineText">
              <T.Strong>
                {musician[0].parentKey === 'musician-type' ? 'Category: ' : 'Type: '}
              </T.Strong>
              <span>{musician[0].name}</span>
            </P.BodySmall>
          </div>
          {/* Size of Act */}
          <div className="overview__block mt-tertiary">
            {musician[0].parentKey === 'music-djs' && (
              <P.BodySmall className="mr-quaternary inlineText">
                <T.Strong>Size of act: </T.Strong>
                <span>{formatRange(actSizeMin, actSizeMax)}</span>
              </P.BodySmall>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );

  return (
    <div>
      {musicianTypes.map((musician, index) => {
        return musician && musician.length > 0 ? (
          overviewBlock(musician, index)
        ) : (
          <Fragment key={index} />
        );
      })}
    </div>
  );
};
