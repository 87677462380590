import { CheckmarkIcon } from '@zola/zola-ui/src/components/SvgIcons';

interface CheckmarkProps {
  fill?: string;
  height?: number;
  width?: number;
}

const Checkmark = ({ fill = '#15845d', height = 24, width = 24 }: CheckmarkProps) => (
  <CheckmarkIcon alt="Yes" fill={fill} width={width} height={height} />
);

export default Checkmark;
