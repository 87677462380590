export const srcSizes = [
  [
    { width: 450, height: 225 },
    { width: 600, height: 300 },
    { width: 1024, height: 384 },
    { width: 1536, height: 768 },
    { width: 2048, height: 768 },
  ],
  [
    { width: 1, height: 1 },
    { width: 387.3, height: 92.61 },
    { width: 150, height: 138.92 },
    { width: 203, height: 188 },
    { width: 203 * 2, height: 188 * 2 },
  ],
  [
    { width: 1, height: 1 },
    { width: 100, height: 92.61 },
    { width: 150, height: 138.92 },
    { width: 203, height: 188 },
    { width: 203 * 2, height: 188 * 2 },
  ],
  [
    { width: 1, height: 1 },
    { width: 211.25, height: 260 },
    { width: 243.75, height: 300 },
    { width: 312, height: 384 },
    { width: 312 * 2, height: 384 * 2 },
  ],
];

export const insufficientPhotosSrcSizes = [
  [
    { width: 450, height: 225 },
    { width: 600, height: 300 },
    { width: 900, height: 450 },
    { width: 1000, height: 500 },
    { width: 1200, height: 600 },
    { width: 1800, height: 900 },
    { width: 2000, height: 1000 },
  ],
  undefined,
  undefined,
  [
    { width: 1, height: 1 },
    { width: 2, height: 2 },
    { width: 3, height: 3 },
    { width: 300, height: 300 },
    { width: 450, height: 450 },
    { width: 500, height: 500 },
    { width: 600, height: 600 },
    { width: 900, height: 900 },
    { width: 1000, height: 1000 },
  ],
];

export const sizes = [55, 20, 20, 25];

export const insufficientSizes = [66, undefined, undefined, 34];
