import { Fragment } from 'react';

import { CouplesStorefrontDetailsFlorist } from '~/types/storefrontDetails';

import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const FloristDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsFlorist>();
  const { options, name, preferredVendors, packages } = storefrontDetails;
  const serviceLevelsOffered = filterForParentKey('florist-service-level', options);
  const preWeddingServicesOffered = filterForParentKey('florist-services-pre-wedding', options);
  const postWeddingServicesOffered = filterForParentKey('florist-services-post-wedding', options);
  const dayOfServicesOffered = filterForParentKey('florist-services-during-wedding', options);
  const arrangementTypesOffered = filterForParentKey('florist-arrangement-types', options);
  const arrangementsOffered = filterForParentKey('florist-floral-arrangement', options);
  const hasServices = [
    serviceLevelsOffered,
    preWeddingServicesOffered,
    postWeddingServicesOffered,
    dayOfServicesOffered,
    arrangementTypesOffered,
    arrangementsOffered,
  ].some((services) => services.length > 0);

  const hasServiceOrPackage = hasServices || !!packages?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(
                serviceLevelsOffered.length > 0,
                'Service levels',
                serviceLevelsOffered,
                true
              )}
              {serviceBlock(
                preWeddingServicesOffered.length > 0,
                'Pre-wedding services',
                preWeddingServicesOffered,
                true
              )}
              {serviceBlock(
                postWeddingServicesOffered.length > 0,
                'Post-wedding services',
                postWeddingServicesOffered,
                true
              )}
              {serviceBlock(
                dayOfServicesOffered.length > 0,
                'Day-of services',
                dayOfServicesOffered,
                true
              )}
              {serviceBlock(
                arrangementTypesOffered.length > 0,
                'Arrangement styles',
                arrangementTypesOffered,
                true
              )}
              {serviceBlock(
                arrangementsOffered.length > 0,
                'Floral arrangements offered',
                arrangementsOffered,
                true
              )}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
      <AvailabilityCalendar />
    </Fragment>
  );
};

export default FloristDetails;
