import { useEffect, useState } from 'react';

import { CoverGallery } from '~/components/common/ui/CoverGalleryV2/CoverGalleryV2';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { getSearchLocationForAddress, isSearchLocation } from '~/util/searchUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import useBreadcrumbLocation from '../hooks/useBreadcrumbLocation';
import Breadcrumb from './Breadcrumb';

import styles from '../storefrontDetailsContent.module.less';

export const StorefrontHeader = ({ hideCLPLPBreadcrumbs }: { hideCLPLPBreadcrumbs?: boolean }) => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { name: storefrontName, address, taxonomyKey } = storefrontDetails;
  const { recordedLocation } = useBreadcrumbLocation(storefrontDetails);
  const storefrontLocation = getSearchLocationForAddress(address);
  const [selectedLocation, setSelectedLocation] = useState(storefrontLocation);

  useEffect(() => {
    const isFromSearch = document.referrer?.includes('/wedding-vendors/search');
    if (isFromSearch && isSearchLocation(recordedLocation)) {
      setSelectedLocation(recordedLocation);
    }
  }, [recordedLocation]);

  return (
    <div className={styles.header}>
      <Breadcrumb
        vendorTitle={storefrontName}
        vendorTypeKey={taxonomyKey}
        selectedLocation={selectedLocation}
        storefrontLocation={storefrontLocation}
        hideCLPLPBreadcrumbs={hideCLPLPBreadcrumbs}
      />
      <CoverGallery />
    </div>
  );
};
