import { Fragment } from 'react';

import { CouplesStorefrontDetailsVideographer } from '~/types/storefrontDetails';

import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import VideoGallery from '../components/VideoGallery';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const VideographerDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsVideographer>();
  const { preferredVendors, options, name, packages, gallery } = storefrontDetails;
  const servicesOffered = filterForParentKey('videographer-package-components', options);
  const deliverablesOffered = filterForParentKey('videographer-deliverables', options);
  const hasServices = [servicesOffered, deliverablesOffered].some(
    (services) => services.length > 0
  );

  const hasServiceOrPackage = hasServices || !!packages?.length || !!gallery?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          <VideoGallery />
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(servicesOffered.length > 0, 'Services', servicesOffered, true)}
              {serviceBlock(
                deliverablesOffered.length > 0,
                'Deliverables',
                deliverablesOffered,
                true
              )}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
      <AvailabilityCalendar />
    </Fragment>
  );
};

export default VideographerDetails;
