import { useState, useCallback, useEffect } from 'react';

type UseMouseEnterProps<T extends HTMLElement = HTMLElement> = {
  trigger?: React.MutableRefObject<T | null>;
};

/** @deprecated - For a11y purposes, its preferable to always include the content in the DOM and use hover to toggle visibility. */
export const useMouseEnter = <T extends HTMLElement = HTMLElement>({
  trigger,
}: UseMouseEnterProps<T>) => {
  const [isEntered, setEntered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setEntered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setEntered(false);
  }, []);

  useEffect(() => {
    const element = trigger?.current;

    if (element) {
      element.addEventListener('mouseenter', onMouseEnter);
      element.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      if (element) {
        element.removeEventListener('mouseenter', onMouseEnter);
        element.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [onMouseEnter, onMouseLeave, trigger]);

  return {
    isEntered,
  };
};
