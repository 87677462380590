import { Fragment } from 'react';

import { CouplesStorefrontDetailsPhotographer } from '~/types/storefrontDetails';

import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const PhotographerDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsPhotographer>();
  const { name, options, preferredVendors, packages } = storefrontDetails;
  const servicesOffered = filterForParentKey('package-components-services-offered', options);
  const photoFormatsOffered = filterForParentKey('photo-format', options);
  const deliverablesOffered = filterForParentKey('package-deliverables-offered', options);
  const hasServices = [servicesOffered, photoFormatsOffered, deliverablesOffered].some(
    (services) => services.length > 0
  );

  const hasServiceOrPackage = hasServices || !!packages?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(servicesOffered.length > 0, 'Services', servicesOffered, true)}
              {serviceBlock(
                photoFormatsOffered.length > 0,
                'Photo formats',
                photoFormatsOffered,
                true
              )}
              {serviceBlock(
                deliverablesOffered.length > 0,
                'Deliverables',
                deliverablesOffered,
                true
              )}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
      <AvailabilityCalendar />
    </Fragment>
  );
};

export default PhotographerDetails;
