import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';

import ApiService from '~/util/apiService';

export const getFirstMoveForStorefront = (storefrontUuid: string) => {
  return ApiService.get<StorefrontFirstMoveView | null>(
    `/web-marketplace-api/v1/first-moves/storefront/${storefrontUuid}`
  ).then((firstMove) => {
    // The API will return an empty object if there is no first move for the storefront
    if (firstMove?.uuid) {
      return firstMove;
    }
    return null;
  });
};

export const dismissFirstMove = (uuid: string) => {
  return ApiService.post<void>(`/web-marketplace-api/v1/first-moves/${uuid}/decline`);
};
