import { useMemo } from 'react';

import { VenueSpaceView } from '~/types/responseTypes';
import { formatRange } from '~/util/rangeUtils';
import { formatWithCommas } from '~/util/textUtils';

type SpaceDetailsResult = {
  seatedCapacity: string;
  standingCapacity: string;
  squareFeet: string | null;
  spaceOptions: string | null;
  spacePricing: string | null;
};

const formatCapacity = (min: number | null, max: number | null, postfix: string) => {
  if ((max !== null && min === null) || (max !== null && min === max)) {
    return `Up to ${max} ${postfix}`;
  }
  return formatRange(min, max, null, (value) => `${value} ${postfix}`);
};

export const useSpaceDetails = ({ space }: { space: VenueSpaceView }): SpaceDetailsResult => {
  const seatedCapacity = formatCapacity(space.minSeatedCapacity, space.maxSeatedCapacity, 'Seated');
  const standingCapacity = formatCapacity(
    space.minStandingCapacity,
    space.maxStandingCapacity,
    'Standing'
  );

  const squareFeet = (space.squareFeet || 0) > 0 ? formatWithCommas(space.squareFeet) : null;

  const spaceOptions =
    (space.entityFacetViews?.length || 0) > 0
      ? (space.entityFacetViews || []).map((option) => option.name).join(', ')
      : null;

  const spacePricing = useMemo(() => {
    if (space.cost || space.includedInVenueCost) {
      return space.includedInVenueCost ? 'Included in total venue pricing' : space.cost;
    }
    return null;
  }, [space.cost, space.includedInVenueCost]);

  return {
    seatedCapacity,
    standingCapacity,
    squareFeet,
    spaceOptions,
    spacePricing,
  };
};
