import { Fragment, useMemo, useState } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import { COLORS3, FONT, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import CachedImage from '~/components/common/images/CachedImage';
import { useAccountIncentivizationContext } from '~/contexts/AccountIncentivizationContext';

import { useSearchBarRequiringClickProps } from '../OverlaySearchBar/OverlaySearchBar';
import LocationDropdown from '../SearchBar/LocationDropdown';
import VendorDropdown from '../SearchBar/VendorDropdown';
import Invitation from './clara-and-bradley-invitation@2x.png';

/**
 * Wrapper around the whole banner for sizing (container queries) and z-index
 */
const PromoContainer = styled.section`
  container-type: inline-size;

  // these keep the auto-suggestion dropdown on top of the next section down
  // https://www.joshwcomeau.com/css/stacking-contexts/ has some explanation
  // about why a z-index on the auto-complete (20) doesn't apply to the section
  // below it: they are different stacking contexts.
  position: relative;
  z-index: 1;
`;

/**
 * The content of the promo banner with the gray background and padding applied
 * by breakpoints to match the rest of the page.
 */
const PromoWrapper = styled.div`
  background-color: ${COLORS3.BLACK_010};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  gap: 48px;

  // Danger: media-query V1 to match the rest of the LP
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding: 0 24px;
    gap: 8px;
  }
`;

const HIDE_IMAGE_BREAKPOINT = 300;

/**
 * Wrapper for the search controls and text.
 */
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @container (width >= 1400px) and (width < 1600px) {
    width: 700px;
  }

  @container (width >= 1600px) {
    width: 800px;
  }

  @container (width <= ${HIDE_IMAGE_BREAKPOINT}px) {
    padding: 16px 0;
  }

  @container (width < 1200px) {
    gap: 24px;
  }
`;

/**
 * The card image -- scaled to different sizes at different widths to try and
 * find a happy layout for every breakpoint
 */
export const HeroImage = styled(CachedImage)`
  height: 300px;
  width: 395px;

  display: block;

  @container (width <= ${HIDE_IMAGE_BREAKPOINT}px) {
    display: none;
  }
  @container (width <= 350px) {
    height: 82px;
    width: 120px;
  }

  @container (width < 768px) and (width > 350px) {
    height: 120px;
    width: 158px;
  }

  @container (width >= 768px)  and (width <= 991px) {
    height: 200px;
    width: 260px;
  }

  @container (width >= 992px) and (width < 1024px) {
    height: 240px;
    width: 316px;
  }

  @container (width >= 1024px) and (width < 1200px) {
    height: 240px;
    width: 316px;
  }
`;

/**
 * Text title on the page.  Some of these styles were copied from the masthead
 * title, but we've got some overrides here on the font size.
 */
const StyledTitleWrapper = styled.div`
  max-width: 600px;

  font-family: ${FONT.FONT_FAMILY_CIRCULAR};
  font-size: 42px;
  line-height: 42px;
  letter-spacing: -0.04em;
  font-weight: ${FONT.FONT_WEIGHT_REGULAR};

  span,
  strong {
    font-family: ${FONT.FONT_FAMILY_NEW_SPIRIT};
    font-weight: ${FONT.FONT_WEIGHT_MEDIUM};

    @container (width < 768px) {
      font-family: ${FONT.FONT_FAMILY_CIRCULAR};
      font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
    }
  }

  @container (width <= 350px) {
    margin: 8px 0;
  }

  @container (width < 768px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
  }

  @container (width >= 768px)  and (width < 1024px) {
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0;
  }
`;

/**
 * Wrapper around the vendor and location pickers.  This has height overrides
 * on the various components and a border to match the figma.
 */
const SearchBarWrapper = styled.div`
  // Danger: media-query V1 to match the rest of the LP
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }

  display: flex;

  height: 56px;
  border-radius: 28px;
  align-items: center;
  background-color: ${COLORS3.WHITE_100};
  border: 1px solid ${COLORS3.BLACK_030};

  .marketplace-autosuggest-input {
    border-radius: 28px;
    height: 54px;
  }
`;

/** Line between inputs in the search bar */
const Divider = styled.div`
  height: 24px;
  border-left: 1px solid ${COLORS3.BLACK_015};
`;

/**
 * The search button.  Hopefully you didn't need this comment.
 * But... the margin and height are here so we get a little gap around the button
 * which is positioned within a white background.
 */
const SearchButton = styled(ButtonV3)<{ searchButtonPosition: 'inside' | 'outside' }>`
  margin-right: 2px;
  &&& {
    ${({ searchButtonPosition }) => (searchButtonPosition !== 'outside' ? `height: 48px` : '')}
  }
`;

const StyledVendorDropdown = styled(VendorDropdown)`
  // The dropdown input (seen when not clicked) can be pretty small.  By default, the
  // dropdown stays constrained to the same size as the input.  This breaks the dropdown
  // outside the input bounds so our longer text can display
  .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    min-width: 250px;

    // Make sure icons in the items don't shrink if the text is really long    svg {
    flex-shrink: 0;
  }
`;

const StyledLocationDropdown = styled(LocationDropdown)`
  // The dropdown input (seen when not clicked) can be pretty small.  By default, the
  // dropdown stays constrained to the same size as the input.  This breaks the dropdown
  // outside the input bounds so our longer text can display
  .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    min-width: 320px;

    // Make sure icons in the items don't shrink if the text is really long
    svg {
      flex-shrink: 0;
    }
  }
`;

const SearchBarButtonOutsideWrapper = styled.div<{ wrapSearchButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 800px;

  ${SearchBarWrapper} {
    flex-grow: 1;
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_XL} {
    ${({ wrapSearchButton }) =>
      wrapSearchButton
        ? `
            flex-direction: column;
            align-items: flex-start;
          `
        : ''}
  }
`;

/**
 * Search controls for the page (vendor / location / search button).
 *
 * Really similar to the InlineSearchBar or StackedSearchBar... but different
 * enough that we couldn't re-use them.  In _particular_ those components have
 * a lot of coloring and padding, and they don't put the search button visually
 * inside the component.
 *
 */
export const SearchBar = ({
  searchButtonPosition,
  wrapSearchButton,
}: {
  searchButtonPosition: 'inside' | 'outside';
  wrapSearchButton?: boolean;
}) => {
  const [locationDropdownInput, setLocationDropdownInput] = useState('');
  const [vendorDropdownInput, setVendorDropdownInput] = useState('');

  const {
    selectedLocation,
    updateSelectedLocation,
    selectedTaxonomyNode,
    setSelectedTaxonomyNode,
    navigateToSrp,
  } = useSearchBarRequiringClickProps({});

  const WrapperComponent =
    searchButtonPosition === 'inside' ? Fragment : SearchBarButtonOutsideWrapper;

  const SearchBtn = useMemo(() => {
    return (
      <SearchButton
        searchButtonPosition={searchButtonPosition}
        onClick={() => navigateToSrp({ selectedLocation, selectedTaxonomy: selectedTaxonomyNode })}
        size="large"
      >
        Search
      </SearchButton>
    );
  }, [navigateToSrp, selectedLocation, selectedTaxonomyNode, searchButtonPosition]);

  return (
    <WrapperComponent wrapSearchButton={wrapSearchButton}>
      <SearchBarWrapper>
        <StyledVendorDropdown
          selectedTaxonomyNode={selectedTaxonomyNode}
          setSelectedTaxonomyNode={setSelectedTaxonomyNode}
          inputString={vendorDropdownInput}
          setInputString={setVendorDropdownInput}
          noInputBorder
          navigateToSrp={navigateToSrp}
          selectedLocation={selectedLocation}
        />
        <Divider />
        <StyledLocationDropdown
          selectedLocation={selectedLocation}
          updateSelectedLocation={updateSelectedLocation}
          inputString={locationDropdownInput}
          setInputString={setLocationDropdownInput}
          selectedTaxonomyNode={selectedTaxonomyNode}
          noInputBorder
          navigateToSrp={navigateToSrp}
        />
        {searchButtonPosition !== 'outside' && SearchBtn}
      </SearchBarWrapper>
      {searchButtonPosition === 'outside' && SearchBtn}
    </WrapperComponent>
  );
};

const StyledLink = styled(LinkV2, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'fontSize',
})<{ fontSize?: 12 | 14 | 16 }>`
  ${({ fontSize = 12 }) => `font-size: ${fontSize}px;`}
  ${({ fontSize = 12 }) => `line-height: ${fontSize === 12 ? 16 : fontSize === 16 ? 24 : 20}px;`}  

  &&& {
    font-weight: ${FONT.FONT_WEIGHT_REGULAR};
  }
`;
export const BookedVendorsPromoTermsLink = ({ fontSize }: { fontSize?: 12 | 14 | 16 }) => (
  <StyledLink
    bold={false}
    href="https://www.zola.com/discount-terms/1214449"
    noUnderline
    noTextTransform
    fontSize={fontSize}
  >
    Terms
  </StyledLink>
);

const Title = ({ showSearchBar }: { showSearchBar: boolean }) => (
  <TitleContainer>
    {showSearchBar && <SearchBar searchButtonPosition="inside" />}
    <StyledTitleWrapper>
      Book a vendor on Zola and get <span>65% off save the dates</span>.{' '}
      <BookedVendorsPromoTermsLink fontSize={14} />
    </StyledTitleWrapper>
  </TitleContainer>
);

export const BookedVendorPromoActivation: React.FC = ({ children }) => {
  const { activateIncentive } = useAccountIncentivizationContext();

  useEffectOnce(() => {
    activateIncentive('JUNE2024_BOOKEDINQUIRY_65OFFSTD').catch(() => null);
  });
  return <>{children}</>;
};

export const BookedVendorPromoModule = ({ showSearchBar }: { showSearchBar: boolean }) => {
  // TODO: IsVisible callback when this div is seen
  return (
    <BookedVendorPromoActivation>
      <Title showSearchBar={showSearchBar} />
      <HeroImage
        src={Invitation}
        alt=""
        srcSizes={[
          { width: 790, height: 600 },
          { width: 395, height: 300 },
        ]}
        quality={80}
      />
    </BookedVendorPromoActivation>
  );
};

export const BookedVendorLandingPagePromoModule = ({
  showSearchBar,
  className,
}: {
  showSearchBar: boolean;
  className?: string;
}) => {
  return (
    <PromoContainer className={className}>
      <PromoWrapper>
        <BookedVendorPromoModule showSearchBar={showSearchBar} />
      </PromoWrapper>
    </PromoContainer>
  );
};
