import cx from 'classnames';

import { CouplesStorefrontDetailsVenue } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';
import './WeddingScannerSection.less';

type WeddingScannerSectionProps = {
  className?: string;
};

const WeddingScannerSection = ({ className }: WeddingScannerSectionProps) => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsVenue>();
  const { name, weddingScanner } = storefrontDetails;
  const classes = cx('marketplace__wedding-scanner-section', 'storefront__section', className);

  if (!weddingScanner) {
    return null;
  }

  const { matterport, sketchfab } = weddingScanner;
  const matterportUrl = matterport ? `https://my.matterport.com/show/?m=${matterport}` : null;
  const matterportTitle = `Matterport 3D walk-through for ${name}`;
  const sketchfabUrl = sketchfab ? `https://sketchfab.com/models/${sketchfab}/embed` : null;
  const sketchfabTitle = `Sketchfab 3D model for ${name}`;

  return matterportUrl || sketchfabUrl ? (
    <div className={classes}>
      <hr className="container section-hr" />
      <div className="container wedding-scanner-section__flex-container">
        {matterportUrl && (
          <div className="wedding-scanner-section__item-container">
            <iframe
              src={matterportUrl}
              title={matterportTitle}
              allowFullScreen
              allow="fullscreen; vr"
            />
          </div>
        )}
        {sketchfabUrl && (
          <div className="wedding-scanner-section__item-container">
            <iframe
              src={sketchfabUrl}
              title={sketchfabTitle}
              allowFullScreen
              allow="fullscreen; vr"
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default WeddingScannerSection;
