import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

import styled from '@emotion/styled';

import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';

const DESKTOP_BUTTON_WIDTH = `200px`;

export const LightboxInquiryButton = styled(InquiryButton)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    width: ${DESKTOP_BUTTON_WIDTH};
  }
`;
export const DeclineFirstMoveButton = styled(ButtonV3)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    width: ${DESKTOP_BUTTON_WIDTH};
  }
`;

export const ExploreMoreButton = styled(ButtonV3)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    width: ${DESKTOP_BUTTON_WIDTH};
  }
`;
