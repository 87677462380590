import { Component } from 'react';

import JoiningHeartsAnimation from '@zola/zola-ui/src/components/JoiningHeartsAnimation';

import cx from 'classnames';

import styles from './loader.module.less';

interface LoaderProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: boolean;
  joiningHearts?: boolean;
}

interface LoaderState {
  timeout: number | undefined;
  visible: boolean;
}

class Loader extends Component<LoaderProps, LoaderState> {
  static defaultProps = {
    delay: true,
    joiningHearts: false,
  };

  constructor(props: LoaderProps) {
    super(props);

    this.showLoader = this.showLoader.bind(this);

    this.state = {
      timeout: undefined,
      visible: !props.delay,
    };
  }

  componentDidMount() {
    const { delay } = this.props;
    if (delay) {
      this.setDelayTimeout();
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state;
    this.setState({ timeout: undefined });
    window.clearTimeout(timeout);
  }

  setDelayTimeout() {
    this.setState({
      timeout: window.setTimeout(this.showLoader, 500),
    });
  }

  showLoader() {
    this.setState({
      visible: true,
    });
  }

  render() {
    const { visible } = this.state;
    const { className, style, joiningHearts } = this.props;

    if (joiningHearts) {
      return (
        <div
          className={styles.joiningHearts}
          style={{ visibility: visible ? 'visible' : 'hidden', ...style }}
        >
          <JoiningHeartsAnimation />
        </div>
      );
    }

    return (
      <div
        className={cx('zola-loader', styles.loader, className)}
        style={{ visibility: visible ? 'visible' : 'hidden', ...style }}
      >
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
      </div>
    );
  }
}

export default Loader;
