import { useEffect } from 'react';

import { createSelector } from 'reselect';

import { requestLastInquiryToStorefront } from '~/actions/couplesInquiriesActions';
import { useUserContext } from '~/contexts/UserContext';
import { useAppDispatch, useAppSelector } from '~/reducers';
import type { RootState } from '~/reducers';

export const getLastInquiryToStorefront = (storefrontUuid?: string) =>
  createSelector(
    (state: RootState) => state.couplesInquiries.lastInquiryToStorefront,
    (lastInquiries) => {
      return storefrontUuid ? lastInquiries[storefrontUuid] : null;
    }
  );

// Globally guard against requesting the last inquiry to a storefront twice on one page
// We need this global guard so that when we have 2 inquiry buttons on the same page
// both of which want to know if there is an existing inquiry, we only have one API call
const useLastInquiryToStorefrontDuplicateRequestGuard: { [storefrontUuid: string]: boolean } = {};

const useLastInquiryToStorefront = (storefrontUuid?: string) => {
  const userContext = useUserContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      storefrontUuid &&
      userContext &&
      !userContext.is_guest &&
      Boolean(useLastInquiryToStorefrontDuplicateRequestGuard[storefrontUuid]) === false
    ) {
      useLastInquiryToStorefrontDuplicateRequestGuard[storefrontUuid] = true;
      dispatch(requestLastInquiryToStorefront(storefrontUuid)).catch(() => null);
    }
  }, [dispatch, storefrontUuid, userContext]);

  return useAppSelector(getLastInquiryToStorefront(storefrontUuid));
};

export default useLastInquiryToStorefront;
