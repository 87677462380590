import { useCallback, useRef, useMemo, Fragment } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { WeddingCardView } from '@zola/svc-marketplace-ts-types';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import useContainerDimensions from '@zola/zola-ui/src/hooks/useContainerDimensions';
import H from '@zola/zola-ui/src/typography/Headings';

import LazyLoad from 'react-lazyload';

import { CardShelf } from '~/components/common/cards/CardShelf/CardShelf';
import { CardPhoto } from '~/components/common/cards/VendorCard/VendorCardPhoto';

import { useLightboxContext } from '../contexts/LightboxContext';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import JumpMenuSection, { JumpMenuSectionIds } from './JumpMenuSection';

import styles from './realWeddingsSection.module.less';

const RealWeddingCardV2 = ({ wedding }: { wedding: WeddingCardView }) => {
  const { openLightbox } = useLightboxContext();

  const imageIds: string[] = useMemo(
    () => wedding.photos.filter((p) => Boolean(p.imageId)).map((photo) => photo.imageId as string),
    [wedding.photos]
  );

  return (
    <div className={styles.realWeddingCard}>
      <CardPhoto
        alternateImage={<Fragment />}
        imageIds={imageIds.slice(0, 5)}
        scaleOnHover={false}
        showCarousel
        lazyload={false}
        readOnly={false}
        scrollContainer={undefined}
        aspectRatio="4:3"
        photoOnClick={(uuid: string) => openLightbox(uuid, 'Real wedding photos')}
        className={styles.photo}
      />

      <LinkV2
        href={`/inspiration/wedding/${wedding.slug}`}
        target="_blank"
        rel="noopener"
        noTextTransform
        noUnderline
      >
        <H.Title3 presentation="h6" strong>
          {wedding.title}
        </H.Title3>
      </LinkV2>
    </div>
  );
};

const RealWeddingsSectionV2 = () => {
  const { realWeddings, storefrontDetails } = useStorefrontDetails();
  const { openLightbox } = useLightboxContext();

  const { name: storefrontName, taxonomyKey } = storefrontDetails;

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(ref);

  const title = useMemo(() => {
    if (taxonomyKey === VENUES_TAXONOMY_KEY) {
      return `Real couples who got married at ${storefrontName}`;
    }
    return `Real wedding inspiration featuring ${storefrontName}`;
  }, [taxonomyKey, storefrontName]);

  const weddingsWithPhotos = useMemo(() => {
    return realWeddings.filter((wedding) => wedding.photos.length > 0);
  }, [realWeddings]);

  const firstPhotoId = useMemo(() => {
    return weddingsWithPhotos[0]?.photos[0]?.imageId;
  }, [weddingsWithPhotos]);

  const viewAll = useCallback(() => {
    openLightbox(firstPhotoId, 'Real wedding photos');
  }, [firstPhotoId, openLightbox]);

  if (weddingsWithPhotos.length === 0) return <></>;

  return (
    <div ref={ref}>
      <JumpMenuSection
        className="marketplace__real-weddings-section storefront__section"
        sectionName={JumpMenuSectionIds.RealWeddings}
      >
        <div className="container">
          <hr />
          <div className={styles.hed}>
            <H.Title2 presentation="h4" strong>
              {title}
            </H.Title2>
            <LinkV2 noTextTransform onClick={viewAll}>
              Show all photos
            </LinkV2>
          </div>
          <div className="row mt-primary" />
          <LazyLoad height={320} offset={300} once>
            <CardShelf hoverScaleAdjust={false} width={width}>
              {realWeddings.map((wedding) => (
                <RealWeddingCardV2 key={wedding.uuid} wedding={wedding} />
              ))}
            </CardShelf>
          </LazyLoad>
        </div>
      </JumpMenuSection>
    </div>
  );
};

const RealWeddingSection = () => {
  return <RealWeddingsSectionV2 />;
};
export default RealWeddingSection;
