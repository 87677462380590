import { WeddingPlannerPackageView } from '@zola/svc-marketplace-ts-types';

export const getDisplayPackageName = (packageName: string) => {
  return packageName.replace('/', ' / ');
};

/* functions used to sort and arrange service matrix */

/* build a matrix (array of arrays) from a set of service views */

// matrix = services.map(({ options }) => options.map(({ id }) => id))
export const getMatrix = (services: WeddingPlannerPackageView[]) => {
  const packageOptionsId = services.map(({ packageOptions }) =>
    (packageOptions || []).map(({ id }) => id)
  );
  const extraFeesId = services.map(({ extraFeePackageOptions }) =>
    (extraFeePackageOptions || []).map(({ id }) => id)
  );
  return [...packageOptionsId, ...extraFeesId];
};
export const sortByNumberOfOptions = (
  viewA: WeddingPlannerPackageView,
  viewB: WeddingPlannerPackageView
) => {
  return viewA.packageOptions.length > viewB.packageOptions.length ? -1 : 1;
};

/* simply sort by value */
const sortByValue = (a: number[], b: number[]) => b[1] - a[1];

/* used to reduce an array and count how many time a value appears within child arrays */
// matrix.reduce(countOccurrences(someId), 0);
export const countOccurrences = (id: number) => {
  return (acc: number, curr: number[]) => {
    let result = acc;
    if (curr.indexOf(id) > -1) {
      result += 1;
    }
    return result;
  };
};

type NumericTuple = [number, number];

export const getSortedMap = (map: Map<number, number>): Map<number, number> => {
  const entries = map.entries() as unknown as NumericTuple[];
  return new Map([...entries].sort(sortByValue));
};

/* uniq */
// ids = matrix.flat().filter(onlyUnique);
export const onlyUnique = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;
