import { useCallback, useState } from 'react';

import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { StorefrontPackageView } from '@zola/svc-marketplace-ts-types';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';

import cx from 'classnames';

import CardBase from '~/components/common/cards/CardBase/CardBase';
import { useModal } from '~/contexts/ModalContext';

import PackageCardContent from './PackageCardContent';

import styles from './packageCard.module.less';

interface PackageCardProps {
  packageData: Omit<StorefrontPackageView & { services?: string[] }, 'id' | 'displayOrder'>;
  vendorType: SearchableVendorTaxonomyKey;
  isPreview?: boolean;
  showMore?: boolean;
}

const PackageCard = ({
  vendorType,
  isPreview = false,
  showMore,
  packageData,
  ...rest
}: PackageCardProps): JSX.Element => {
  const [seeMore, setSeeMore] = useState(false);
  const { showCustomModal } = useModal();

  const openPackageModal = useCallback(() => {
    showCustomModal(PackageCardContent, {
      ...rest,
      vendorType,
      setSeeMore,
      onClick: openPackageModal,
      packageData,
    });
  }, [packageData, rest, showCustomModal, vendorType]);

  return (
    <CardBase
      className={cx('p-secondary', styles.packageCard, isPreview && styles.preview)}
      isLink={false}
    >
      <PackageCardContent
        {...rest}
        isPreview={isPreview}
        setSeeMore={setSeeMore}
        vendorType={vendorType}
        showMore={showMore}
        packageData={packageData}
        onClick={openPackageModal}
      />
      {seeMore && !isPreview && (
        <>
          <div className={styles.fade} />
          <div className={styles.moreButtonContainer}>
            <LinkV2
              className={styles.moreButton}
              arrowPosition="postfix"
              arrow
              noTextTransform
              onClick={openPackageModal}
              role="button"
              type="button"
              variant="primary"
            >
              See more
            </LinkV2>
          </div>
        </>
      )}
    </CardBase>
  );
};

export default PackageCard;
