import { MappedOptionFacetView } from '~/types/responseTypes';

import ServiceSectionV2 from './ServiceSectionV2';

import styles from './ServiceBlockV2.module.less';

interface ServiceBlockProps {
  options: MappedOptionFacetView[];
  label: string;
  imgSrc?: string;
  className?: string;
}
const ServiceBlockV2 = ({ options, imgSrc, label, className }: ServiceBlockProps): JSX.Element => {
  return (
    <ServiceSectionV2 imgSrc={imgSrc} label={label} className={className}>
      <ul className={styles.list}>
        {options.map((option) => (
          <li className={styles.item} key={option.id}>
            {option.name}
          </li>
        ))}
      </ul>
    </ServiceSectionV2>
  );
};

export default ServiceBlockV2;
