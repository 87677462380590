import { useCallback } from 'react';

import P from '@zola/zola-ui/src/typography/Paragraphs';

import { useModal } from '~/contexts/ModalContext';
import UnclaimedSimilarSection from '~/pages/couples/storefronts/components/UnclaimedSimilarSection';
import { StorefrontDetailsProvider } from '~/pages/couples/storefronts/contexts/StorefrontDetailsProvider';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { renderDefaultFavoriteButton } from '../../favorites/buttonShapes/renderDefaultFavoriteButton';
import { StorefrontFavoriteButton } from '../../favorites/StorefrontFavoriteButton';
import { Heading } from '../../zolaUI/Typography';

import styles from './unclaimedLearnMoreModal.module.less';

interface UnclaimedLearnMoreModalProps {
  storefrontDetails: CouplesStorefrontDetails;
}

export const useUnclaimedLearnMoreModal = () => {
  const { showV2PredefinedModal } = useModal();

  const showUnclaimedLearnMoreModal = useCallback(
    (storefrontDetails: CouplesStorefrontDetails) => {
      showV2PredefinedModal<UnclaimedLearnMoreModalProps>(
        'UNCLAIMED_LEARN_MORE',
        { dialogHeadingId: 'unclaimed-learn-more', triggerRef: null, lockBgScrolling: true },
        { storefrontDetails }
      );
    },
    [showV2PredefinedModal]
  );

  return { showUnclaimedLearnMoreModal };
};

const UnclaimedLearnMoreModal = (props: UnclaimedLearnMoreModalProps) => {
  const { storefrontDetails } = props;
  const { name, uuid } = storefrontDetails;
  return (
    <StorefrontDetailsProvider storefrontDetails={storefrontDetails} isVendorPreview={false}>
      <div className={styles.unclaimedLearnMoreModal}>
        <div className={styles.heading}>
          <Heading.H1 presentation="h3">{name} is not on Zola yet</Heading.H1>
          <P.BodyBase>Favorite them to be the first to know!</P.BodyBase>
        </div>
        <StorefrontFavoriteButton uuid={uuid}>
          {renderDefaultFavoriteButton({
            className: styles.favoriteButton,
            variant: 'full',
            type: 'VENDOR',
          })}
        </StorefrontFavoriteButton>
        <hr />
        <UnclaimedSimilarSection presentation="modal" />
      </div>
    </StorefrontDetailsProvider>
  );
};

export default UnclaimedLearnMoreModal;
