import { Fragment } from 'react';

import { HAIR_MAKEUP_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { pluralize } from '@zola-helpers/client/dist/es/transformers';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import T from '@zola/zola-ui/src/typography/Text';

import ReactToolTip from 'react-tooltip';

import { getServicesOfferedMetaForVendorTaxonomyKey } from '~/meta/services';
import { CouplesStorefrontDetailsBeautician } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

const BeauticianOverview = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsBeautician>();
  const { beauticianDetails, options } = storefrontDetails;
  const { stylistCount } = beauticianDetails;

  const servicesMetaData = getServicesOfferedMetaForVendorTaxonomyKey(HAIR_MAKEUP_TAXONOMY_KEY);
  const stylesFacetId = (servicesMetaData.find((meta) => meta.formKey === 'hairBeautyStyles') || {})
    .parentId;
  const beautyStyles = options.filter((option) => option.parentId === stylesFacetId);

  return (
    <div>
      {(stylistCount || 0) > 0 && (
        <div className="overview__block mt-tertiary">
          <P.BodySmall className="mr-quaternary inlineText">
            <T.Strong>Number of beauticians: </T.Strong>
            <span>{stylistCount}</span>
          </P.BodySmall>
        </div>
      )}
      {beautyStyles.length > 0 && beautyStyles.length <= 3 && (
        <div className="overview__block mt-tertiary">
          <P.BodySmall className="mr-quaternary inlineText">
            <T.Strong>Styles: </T.Strong>
            {beautyStyles.map((style, idx) => (
              <span key={style.id}>
                {style.name}
                {idx !== beautyStyles.length - 1 && ', '}
              </span>
            ))}
          </P.BodySmall>
        </div>
      )}
      {beautyStyles.length >= 4 && (
        <div className="overview__block  mt-tertiary">
          <P.BodySmall className="mr-quaternary inlineText">
            <T.Strong>Styles: </T.Strong>
            <span>{beautyStyles[0].name}</span>
          </P.BodySmall>
          <Fragment>
            <ReactToolTip id="styles-tooltip" aria-haspopup="true">
              <ul>
                {beautyStyles.map((style, idx) => idx > 0 && <li key={style.id}>{style.name}</li>)}
              </ul>
            </ReactToolTip>
            <div
              className="overview__block overview__markets ml-tertiary"
              data-tip
              data-for="styles-tooltip"
              data-effect="solid"
            >
              +{pluralize('style', 'styles', beautyStyles.length - 1, true)}
            </div>
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default BeauticianOverview;
