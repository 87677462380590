import { useState } from 'react';

import { StorefrontListingMessageView } from '@zola/svc-marketplace-ts-types';
import { CalendarEventIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CalendarEvent';
import { TagIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Tag';
import TagV2, { TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';
import COLORS3, { COLORS_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors3';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import T from '@zola/zola-ui/src/typography/Text';

import featureFlags from '~/util/featureFlags';

import { ListingMessageContainer, MessageMainContent } from './ListingMessage.styles';

interface ListingMessageLabelProps {
  text: string;
}

const ListingMessageLabel = ({ text }: ListingMessageLabelProps): JSX.Element => (
  <TagV2 size={TagV2Size.SMALL} variant={TagV2Variant.BLACK_010}>
    {text}
  </TagV2>
);

interface ListingMessageProps {
  message: StorefrontListingMessageView;
}

export const ListingMessage = ({ message }: ListingMessageProps): JSX.Element | null => {
  const [enableVendorAnnouncements, setEnableVendorAnnouncements] = useState(false);

  useEffectOnce(() => {
    setEnableVendorAnnouncements(featureFlags.get('enableVendorAnnouncements'));
  });

  if (enableVendorAnnouncements === false) return null;

  const { title, body, messageType } = message;

  if (!title || !body || !messageType) return null;

  let labelText, IconComponent;
  switch (messageType) {
    case 'OFFER':
      labelText = 'Offer from vendor';
      IconComponent = <TagIcon width={24} height={24} color={COLORS_SEMANTIC.POSITIVE_100} />;
      break;
    case 'OPEN_HOUSE':
      labelText = 'Open house';
      IconComponent = <CalendarEventIcon width={24} height={24} color={COLORS3.COVE_100} />;
      break;
  }

  return (
    <ListingMessageContainer>
      {labelText && (
        <div>
          <ListingMessageLabel text={labelText} />
        </div>
      )}
      <MessageMainContent>
        {IconComponent}
        <span>
          <P.BodyBase>
            <T.Strong>{title}</T.Strong>
          </P.BodyBase>
          <P.BodyBase>{body}</P.BodyBase>
        </span>
      </MessageMainContent>
    </ListingMessageContainer>
  );
};
