/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import { CouplesStorefrontDetailsWeddingPlanner } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';

const scrollToSection = (link: string) => {
  const anchor = document.querySelector(link);
  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const Pricing = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsWeddingPlanner>();
  const { options, weddingPlannerDetails } = storefrontDetails;

  const serviceLevelInfo = useMemo(() => {
    const { packages } = weddingPlannerDetails;
    const selectedOptions = options.map((option) => option.key);
    const filteredPackages = packages.filter(
      (pkg) => pkg.serviceLevelFacet && selectedOptions.includes(pkg.serviceLevelFacet.key)
    );

    if (!filteredPackages || filteredPackages.length < 1) return '';
    const bestPackage = filteredPackages[0];
    const remaining = filteredPackages.slice(1);
    if (filteredPackages.length > 1) {
      return (
        <>
          <P.BodySmall className="inlineText">
            Service levels: {bestPackage.serviceLevelFacet?.name}{' '}
          </P.BodySmall>
          <LinkV2
            role="button"
            noTextTransform
            onClick={() => scrollToSection('#service-levels')}
          >{`+${remaining.length} service levels`}</LinkV2>
        </>
      );
    }
    return `Service level: ${bestPackage.serviceLevelFacet?.name}`;
  }, [options, weddingPlannerDetails]);

  const teamSizeInfo = useMemo(() => {
    const { numberOfPlannersMin: min, numberOfPlannersMax: max } = weddingPlannerDetails;
    if (!min) return '';
    if (min > 0 && max && max > min) {
      return `Team size: ${min} – ${max}`;
    }
    return `Team size: ${min}`;
  }, [weddingPlannerDetails]);

  return (
    <>
      <div>{serviceLevelInfo}</div>
      <P.BodySmall>{teamSizeInfo}</P.BodySmall>
    </>
  );
};

export default Pricing;
