import { Fragment } from 'react';

import cx from 'classnames';

import { StorefrontLinkTypeEnum } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';

import styles from './videoSection.module.less';

interface VideoSectionProps {
  className?: string;
}

const VideoSection = ({ className }: VideoSectionProps): JSX.Element => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { video } = storefrontDetails;
  const { videoId, type, title } = video || {};

  if (!videoId) {
    return <Fragment />;
  }

  let embedUrl;

  switch (type) {
    case StorefrontLinkTypeEnum.FACEBOOK_VIDEO:
      embedUrl = `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/watch/?v=${videoId}`;
      break;
    case StorefrontLinkTypeEnum.VIMEO:
      embedUrl = `https://player.vimeo.com/video/${videoId}`;
      break;
    case StorefrontLinkTypeEnum.YOUTUBE:
      embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0`;
      break;
    default:
      break;
  }

  if (!embedUrl) {
    return <Fragment />;
  }

  const classNames = cx(styles.videoSection, className);
  return (
    <Fragment>
      <hr className={cx('container', styles.sectionHr)} />
      <div className={classNames}>
        <iframe
          src={embedUrl}
          title={title || ''}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </Fragment>
  );
};

export default VideoSection;
