import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import { CouplesStorefrontDetailsVenue } from '~/types/storefrontDetails';

import MenuCard from '../../../../../components/common/cards/menuCard/MenuCard';
import SeeAllDrawer from '../../../../../components/common/SeeAllDrawer';
import { useStorefrontDetails } from '../../contexts/StorefrontDetailsContext';

interface MenuSectionProps {
  className?: string;
}

const MenusSection = ({ className }: MenuSectionProps) => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetailsVenue>();
  const { venueDetails, name } = storefrontDetails;
  const { menus } = venueDetails;
  const classes = cx('marketplace__menus-section', 'storefront__section', className);

  if (!menus || !menus.length) {
    return null;
  }

  const menuCards = menus.map((menu, i) => <MenuCard menu={menu} key={i} />);

  return (
    <div className={classes}>
      <div className="container">
        <hr />
        <SeeAllDrawer
          data={menuCards}
          desktopColumnCount={3}
          heading={
            <H.Title2 presentation="h4" strong>
              {name} menu options
            </H.Title2>
          }
        />
      </div>
    </div>
  );
};

export default MenusSection;
