import { useEffect, useState } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  AddressValidationView,
  AddressView,
  CreateStorefrontClaimRequest,
  ValidationRequest,
} from '@zola/svc-marketplace-ts-types';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import FinalFormDropdown from '@zola/zola-ui/src/components/Form/dropdownV3/FinalFormDropdown/FinalFormDropdown';
import FinalFormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput/FinalFormInput';
import {
  EMAIL,
  PHONE_US,
  REQUIRED,
  ZIP,
  MAX_LENGTH_20,
  MAX_LENGTH_35,
  MAX_LENGTH_15,
} from '@zola/zola-ui/src/components/Form/util/validations';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';

import { useModal } from '~/contexts/ModalContext';
import { useToast } from '~/hooks/useToast';
import { stateOptionList } from '~/meta/stateOptionList';
import { useAppDispatch } from '~/reducers';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

import { MAX_LENGTH_100, MAX_LENGTH_255 } from '../../form/commonValidations';
import { Heading } from '../../zolaUI/Typography';

import styles from './unclaimedClaimListingForm.module.less';

interface UnclaimedClaimListingFormProps {
  storefrontDetails: CouplesStorefrontDetails;
}

interface UnclaimedClaimListingFormValues {
  requestType: CreateStorefrontClaimRequest.RequestTypeEnum;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  businessName: string;
  streetAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
}

const UnclaimedClaimListingForm = (props: UnclaimedClaimListingFormProps) => {
  const { storefrontDetails } = props;
  const dispatch = useAppDispatch();
  const { hideModal } = useModal();
  const { positiveToast } = useToast();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const requireStreetAddress = storefrontDetails.taxonomyKey === VENUES_TAXONOMY_KEY;

  useEffect(() => {
    if (submitSuccess) {
      hideModal();
      positiveToast({ headline: 'Submitted!', dek: 'A member of our team will be in touch soon.' });
    }
  }, [dispatch, hideModal, positiveToast, submitSuccess]);

  const onSubmit = async (values: UnclaimedClaimListingFormValues) => {
    let formError: string | null = null;
    let validatedAddress: AddressView | undefined;

    await ApiService.post<AddressValidationView, ValidationRequest>(
      '/web-marketplace-api/v2/address/validate',
      {
        address1: values.streetAddress,
        address2: null,
        city: values.city,
        stateProvince: values.stateCode,
        postalCode: values.zipCode,
      }
    )
      .then((response) => {
        if (response.validationResult === 'NOT_VALID') {
          formError = 'Enter a valid U.S. address.';
        }
        if (response.validatedAddressDifferent) {
          ({ validatedAddress } = response);
        }
      })
      .catch(StandardLogFn);

    if (formError) {
      return { [FORM_ERROR]: formError };
    }

    const claimRequest: CreateStorefrontClaimRequest = {
      requestType: values.requestType,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.emailAddress,
      businessName: storefrontDetails.name, // The business name field is disabled
      address: {
        address1: validatedAddress?.address1 || values.streetAddress || null,
        address2: null,
        city: validatedAddress?.city || values.city,
        stateProvince: validatedAddress?.stateProvince || values.stateCode,
        postalCode: validatedAddress?.stateProvince || values.zipCode,
        countryCode: validatedAddress?.countryCode || null,
        latitude: validatedAddress?.latitude || null,
        longitude: validatedAddress?.longitude || null,
        googleMapsPlaceId: validatedAddress?.googleMapsPlaceId || null,
      },
    };

    await ApiService.post<void, CreateStorefrontClaimRequest>(
      `/web-marketplace-api/v1/storefront/${storefrontDetails.uuid}/claim`,
      claimRequest
    ).catch((error) => {
      StandardLogFn(error);
      if (error.category?.reason === 'validation') {
        formError = `You've already requested to claim this listing—we'll be in touch soon! In the meantime, reach out to vendorsales@zola.com with any questions.`;
      } else {
        formError = `Something went wrong. Please reach out to vendorsales@zola.com to continue claiming this listing.`;
      }
    });

    if (formError) {
      return { [FORM_ERROR]: formError };
    }

    setSubmitSuccess(true);

    return undefined;
  };

  return (
    <div className={styles.unclaimedClaimListingForm}>
      <Heading.H1 className={styles.heading} presentation="h3">
        Discover the smarter way to connect with couples
      </Heading.H1>
      <P.BodyBase className={styles.subheading}>
        Tell us the basics, and a member of our team will reach out to help you customize your
        listing.
      </P.BodyBase>
      <Form
        initialValues={{
          requestType: 'MANAGE',
          businessName: storefrontDetails.name,
          streetAddress: storefrontDetails.address.address1,
          city: storefrontDetails.address.city,
          stateCode: storefrontDetails.address.stateProvince,
          zipCode: storefrontDetails.address.postalCode,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, submitError }) => {
          return (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.requestType}>
                <FinalFormDropdown
                  name="requestType"
                  label="Choose what you want to do"
                  options={[
                    { value: 'MANAGE', label: 'Claim this listing to start getting inquiries' },
                    { value: 'REMOVE', label: 'Remove this listing from Zola' },
                  ]}
                  validate={[REQUIRED]}
                  isRequired
                />
              </div>
              <div className={styles.firstName}>
                <FinalFormInput
                  type="text"
                  name="firstName"
                  label="First name"
                  validate={[REQUIRED, MAX_LENGTH_255]}
                  isRequired
                />
              </div>
              <div className={styles.lastName}>
                <FinalFormInput
                  type="text"
                  name="lastName"
                  label="Last name"
                  validate={[REQUIRED, MAX_LENGTH_255]}
                  isRequired
                />
              </div>
              <div className={styles.emailAddress}>
                <FinalFormInput
                  type="email"
                  name="emailAddress"
                  label="Email address"
                  validate={[REQUIRED, EMAIL, MAX_LENGTH_255]}
                  isRequired
                />
              </div>
              <div className={styles.phoneNumber}>
                <FinalFormInput
                  type="tel"
                  name="phoneNumber"
                  label="Phone number"
                  validate={[REQUIRED, PHONE_US, MAX_LENGTH_20]}
                  isRequired
                />
              </div>
              <div className={styles.businessName}>
                <FinalFormInput
                  type="text"
                  name="businessName"
                  label="Business name"
                  validate={[REQUIRED, MAX_LENGTH_255]}
                  isRequired
                  disabled
                />
              </div>
              <div className={styles.streetAddress}>
                <FinalFormInput
                  type="text"
                  name="streetAddress"
                  label="Street address"
                  validate={requireStreetAddress ? [REQUIRED, MAX_LENGTH_100] : [MAX_LENGTH_100]}
                  isRequired={requireStreetAddress}
                />
              </div>
              <div className={styles.city}>
                <FinalFormInput
                  type="text"
                  name="city"
                  label="City"
                  validate={[REQUIRED, MAX_LENGTH_35]}
                  isRequired
                />
              </div>
              <div className={styles.stateCode}>
                <FinalFormDropdown
                  name="stateCode"
                  label="State"
                  options={stateOptionList}
                  validate={[REQUIRED]}
                  isRequired
                />
              </div>
              <div className={styles.zipCode}>
                <FinalFormInput
                  type="text"
                  name="zipCode"
                  label="Zip code"
                  validate={[REQUIRED, ZIP, MAX_LENGTH_15]}
                  isRequired
                />
              </div>
              <div className={styles.submit}>
                <P.BodySmall className={styles.info}>
                  <span className={styles.error}>{submitError}</span>
                  <span className={styles.required}>* Required</span>
                </P.BodySmall>
                <ButtonV3
                  component="button"
                  type="submit"
                  size="large"
                  fullWidth
                  disabled={submitting}
                >
                  Request to claim this listing
                </ButtonV3>
                <P.BodySmall className={styles.terms}>
                  By clicking submit, you agree to our{' '}
                  <LinkV2 href="/privacy" target="_blank" subtle>
                    Privacy Policy
                  </LinkV2>{' '}
                  and{' '}
                  <LinkV2 href="/terms" target="_blank" subtle>
                    Terms of Use
                  </LinkV2>
                  .
                </P.BodySmall>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default UnclaimedClaimListingForm;
