import { Fragment, useMemo } from 'react';

import { AccordionV2 } from '@zola/zola-ui/src/components/AccordionV2';
import Schemas from '@zola/zola-ui/src/components/Schemas/Schemas';
import H from '@zola/zola-ui/src/typography/Headings';

import cx from 'classnames';

import InquiryButton from '~/components/common/ui/inquiries/InquiryButton';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { getFaqSchema } from '~/util/seoUtils';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import JumpMenuSection, { JumpMenuSectionIds } from './JumpMenuSection';
import SectionDrawer from './SectionDrawer';

import styles from './faqsSection.module.less';

const FaqsSection = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { faqs } = storefrontDetails;
  const faqSchemas = useMemo(() => {
    if (faqs) {
      return [getFaqSchema(faqs.map(({ question, answer }) => ({ question, answer })))];
    }

    return [];
  }, [faqs]);

  const noFaqs = !faqs || !faqs.length;

  if (noFaqs) {
    return <Fragment />;
  }

  const heading = (
    <div className={cx(styles.sectionHeading, 'faqs-section__heading')}>
      <H.Title2 presentation="h4" strong>
        Frequently asked questions
      </H.Title2>
      <div className={styles.itemCount}>{faqs.length} Answers</div>
    </div>
  );

  const items = faqs.map((faq) => ({
    id: faq.id.toString(),
    hed: faq.question,
    dek: faq.answer,
  }));

  return (
    <JumpMenuSection
      className={cx(styles.faqsSection, 'storefront__section')}
      sectionName={JumpMenuSectionIds.FAQs}
    >
      <div className="container">
        <hr />
        <SectionDrawer heading={heading} defaultIsOpen>
          <InquiryButton
            className={styles.desktopInquiryButton}
            section="FAQ"
            position={6}
            buttonText="Ask them a question"
            entryPoint="FAQ"
          />
          <Schemas schemas={faqSchemas} />
          <AccordionV2
            className={styles.faqsSectionItems}
            items={items}
            allowMultiple
            arrowOnLeft
          />
        </SectionDrawer>
        <InquiryButton
          className={styles.mobileOnly}
          section="FAQ"
          position={6}
          buttonText="Ask them a question"
          entryPoint="FAQ"
        />
      </div>
    </JumpMenuSection>
  );
};

export default FaqsSection;
